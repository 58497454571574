export const MainChartConfig = [
    {
        id: "id",
        numeric: true,
        label: "Actions",
        allowSort: false,
        hidden: false,
        dataType: "hidden",
        options: false,
    },{
        id: "well_id",
        numeric: false,
        label: "Well ID",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "state_well",
        numeric: false,
        label: "State Well Number",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "agency",
        numeric: false,
        label: "Agency",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "agency_well_id",
        numeric: false,
        label: "Local Agency Well ID",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "well_type",
        numeric: true,
        label: "Water Depth",
        allowSort: true,
        hidden: false,
        dataType: "dropdown", 
        options: ["AG", "Galvanized", "Galvanized Pipe", "Irrigation", "MONITOR", "Observation", "Pipe", "Residential", "Stockwatering", "SUBMERSE AG", "SUBMERSE DOM", "Unknown"],
    },{
        id: "volumetric",
        numeric: true,
        label: "Volumetric Pumping Calculations",
        allowSort: true,
        hidden: false,
        dataType: "dropdown", 
        options: ["Kw/h", "Hours", "AF"],
    },{
        id: "notes",
        numeric: true,
        label: "Notes",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "location",
        numeric: true,
        label: "",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: []
    },{
        id: "latitude",
        numeric: true,
        label: "",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: []
    },{
        id: "longitude",
        numeric: true,
        label: "",
        allowSort: false,
        hidden: false,
        dataType: "ignore",
        options: []
    },{
        id: "results",
        dataType: "ignore",
    },{
        id: "time_period_testpump",
        dataType: "ignore",
    },{
        id: "time_period",
        dataType: "ignore",
    },{
        id: "ground_surface",
        dataType: "ignore",
    },{
        id: "total_depth",
        dataType: "ignore",
    },{
        id: "reference_point",
        dataType: "ignore",
    },{
        id: "datum",
        dataType: "ignore",
    },{
        id: "method",
        dataType: "ignore",
    },{
        id: "location_select",
        numeric: true,
        label: "Enter Location Using...",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: ["Current Location (Use GPS)", "Click Point on Map", "Enter Latitude/Longitude"]
    },{
        id: "accuracy",
        numeric: true,
        label:"Accuracy",
        allowSort: false,
        hidden: true,
        dataType: "numeric",
        options: false
    },{
        id: "location_coordinates",
        numeric: false,
        label: "Location Coordinates",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "updateby_id",
        numeric: false,
        label: "Updated By",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false 
    },{
        id: "updateby_label",
        numeric: false,
        label: "Update Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "updatedate",
        numeric: false,
        label: "Date Updated",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "updatedate_label",
        numeric: false,
        label: "Date Updated Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "volumetric_label",
        numeric: false,
        label: "Volumetric Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "well_type_label",
        numeric: false,
        label: "Well Type Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "label",
        numeric: false,
        label: "Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id:"serverError",
        dataType: "ignore",
    }
];

export const WellSiteConfig = [
    {
        id: "id",
        numeric: true,
        label: "Actions",
        allowSort: false,
        hidden: false,
        dataType: "hidden",
        options: false,
    },{
        id: "dms_site_id",
        numeric: false,
        label: "DMS Site ID",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "local_site_id",
        numeric: false,
        label: "Local Site ID",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "dwr_well_id",
        numeric: false,
        label: "State Well Number",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "source_org",
        numeric: false,
        label: "Source GSP <br /> Organization",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "owner",
        numeric: false,
        label: "Well Owner",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "coordinate_method",
        numeric: false,
        label: "Coordinate Collection Method",
        allowSort: false,
        hidden: true,
        dataType: "dropdown", 
        options: ["GPS", "Survey", "Digitize from USGS Quad Maps", "Unknown"],
    },{
        id: "latitude",
        numeric: true,
        label: "Site Latitude",
        allowSort: false,
        hidden: true,
        dataType: "number", 
        options: [],
    },{
        id: "horz_datum",
        numeric: true,
        label: "Horizontal Datum",
        allowSort: false,
        hidden: true,
        dataType: "text",
        options: false,
    },{
        id: "monitor_by",
        numeric: false,
        label: "Monitored By",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: []
    },{
        id: "latitude",
        numeric: true,
        label: "",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: []
    },{
        id: "longitude",
        numeric: true,
        label: "",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: []
    },{
        id: "origin_site_id",
        numeric: false,
        label: "Origin Site ID",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "accuracy",
        numeric: true,
        label:"Accuracy",
        allowSort: false,
        hidden: true,
        dataType: "numeric",
        options: false
    },{
        id: "updateby_id",
        numeric: false,
        label: "Updated By",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false 
    },{
        id: "updateby_label",
        numeric: false,
        label: "Update Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "updatedate",
        numeric: false,
        label: "Date Updated",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "updatedate_label",
        numeric: false,
        label: "Date Updated Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "label",
        numeric: false,
        label: "Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id:"serverError",
        dataType: "ignore",
    }
];

// SWGWSiteConfig
export const SWGWSiteConfig = [
    {
        id: "id",
        numeric: true,
        label: "Actions",
        allowSort: false,
        hidden: false,
        dataType: "hidden",
        options: false,
    },{
        id: "dms_site_id",
        numeric: false,
        label: "DMS Site ID",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "local_site_id",
        numeric: false,
        label: "Local Site ID",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "dwr_well_id",
        numeric: false,
        label: "State Well Number",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "source_org",
        numeric: false,
        label: "Source GSP <br /> Organization",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "owner",
        numeric: false,
        label: "Well Owner",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "coordinate_method",
        numeric: false,
        label: "Coordinate Collection Method",
        allowSort: false,
        hidden: true,
        dataType: "dropdown", 
        options: ["GPS", "Survey", "Digitize from USGS Quad Maps", "Unknown"],
    },{
        id: "latitude",
        numeric: true,
        label: "Site Latitude",
        allowSort: false,
        hidden: true,
        dataType: "number", 
        options: [],
    },{
        id: "horz_datum",
        numeric: true,
        label: "Horizontal Datum",
        allowSort: false,
        hidden: true,
        dataType: "text",
        options: false,
    },{
        id: "monitor_by",
        numeric: false,
        label: "Monitored By",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: []
    },{
        id: "latitude",
        numeric: true,
        label: "",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: []
    },{
        id: "longitude",
        numeric: true,
        label: "",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: []
    },{
        id: "origin_site_id",
        numeric: false,
        label: "Origin Site ID",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "accuracy",
        numeric: true,
        label:"Accuracy",
        allowSort: false,
        hidden: true,
        dataType: "numeric",
        options: false
    },{
        id: "updateby_id",
        numeric: false,
        label: "Updated By",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false 
    },{
        id: "updateby_label",
        numeric: false,
        label: "Update Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "updatedate",
        numeric: false,
        label: "Date Updated",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "updatedate_label",
        numeric: false,
        label: "Date Updated Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "label",
        numeric: false,
        label: "Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id:"serverError",
        dataType: "ignore",
    }
];

// Subsidence Monitoring Site Config
export const SubsidenceSiteConfig = [
    {
        id: "id",
        numeric: true,
        label: "Actions",
        allowSort: false,
        hidden: false,
        dataType: "hidden",
        options: false,
    },{
        id: "dms_site_id",
        numeric: false,
        label: "DMS Site ID",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "local_site_id",
        numeric: false,
        label: "Local Site ID",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "dwr_well_id",
        numeric: false,
        label: "State Well Number",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "source_org",
        numeric: false,
        label: "Source GSP <br /> Organization",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "owner",
        numeric: false,
        label: "Well Owner",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "coordinate_method",
        numeric: false,
        label: "Coordinate Collection Method",
        allowSort: false,
        hidden: true,
        dataType: "dropdown", 
        options: ["GPS", "Survey", "Digitize from USGS Quad Maps", "Unknown"],
    },{
        id: "latitude",
        numeric: true,
        label: "Site Latitude",
        allowSort: false,
        hidden: true,
        dataType: "number", 
        options: [],
    },{
        id: "horz_datum",
        numeric: true,
        label: "Horizontal Datum",
        allowSort: false,
        hidden: true,
        dataType: "text",
        options: false,
    },{
        id: "monitor_by",
        numeric: false,
        label: "Monitored By",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: []
    },{
        id: "latitude",
        numeric: true,
        label: "",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: []
    },{
        id: "longitude",
        numeric: true,
        label: "",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: []
    },{
        id: "origin_site_id",
        numeric: false,
        label: "Origin Site ID",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "accuracy",
        numeric: true,
        label:"Accuracy",
        allowSort: false,
        hidden: true,
        dataType: "numeric",
        options: false
    },{
        id: "updateby_id",
        numeric: false,
        label: "Updated By",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false 
    },{
        id: "updateby_label",
        numeric: false,
        label: "Update Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "updatedate",
        numeric: false,
        label: "Date Updated",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "updatedate_label",
        numeric: false,
        label: "Date Updated Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "label",
        numeric: false,
        label: "Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id:"serverError",
        dataType: "ignore",
    }
];

// Water Quality Site Config
export const WaterQualitySiteConfig = [
    {
        id: "id",
        numeric: true,
        label: "Actions",
        allowSort: false,
        hidden: false,
        dataType: "hidden",
        options: false,
    },{
        id: "dms_site_id",
        numeric: false,
        label: "DMS Site ID",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "local_site_id",
        numeric: false,
        label: "Local Site ID",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "dwr_well_id",
        numeric: false,
        label: "State Well Number",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "source_org",
        numeric: false,
        label: "Source GSP <br /> Organization",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "owner",
        numeric: false,
        label: "Well Owner",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "coordinate_method",
        numeric: false,
        label: "Coordinate Collection Method",
        allowSort: false,
        hidden: true,
        dataType: "dropdown", 
        options: ["GPS", "Survey", "Digitize from USGS Quad Maps", "Unknown"],
    },{
        id: "latitude",
        numeric: true,
        label: "Site Latitude",
        allowSort: false,
        hidden: true,
        dataType: "number", 
        options: [],
    },{
        id: "horz_datum",
        numeric: true,
        label: "Horizontal Datum",
        allowSort: false,
        hidden: true,
        dataType: "text",
        options: false,
    },{
        id: "monitor_by",
        numeric: false,
        label: "Monitored By",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: []
    },{
        id: "latitude",
        numeric: true,
        label: "",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: []
    },{
        id: "longitude",
        numeric: true,
        label: "",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: []
    },{
        id: "origin_site_id",
        numeric: false,
        label: "Origin Site ID",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "accuracy",
        numeric: true,
        label:"Accuracy",
        allowSort: false,
        hidden: true,
        dataType: "numeric",
        options: false
    },{
        id: "updateby_id",
        numeric: false,
        label: "Updated By",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false 
    },{
        id: "updateby_label",
        numeric: false,
        label: "Update Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "updatedate",
        numeric: false,
        label: "Date Updated",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "updatedate_label",
        numeric: false,
        label: "Date Updated Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "label",
        numeric: false,
        label: "Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id:"serverError",
        dataType: "ignore",
    }
];

// Groundwater Storage Site Config
export const GroundwaterSiteConfig = [
    {
        id: "id",
        numeric: true,
        label: "Actions",
        allowSort: false,
        hidden: false,
        dataType: "hidden",
        options: false,
    },{
        id: "dms_site_id",
        numeric: false,
        label: "DMS Site ID",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "local_site_id",
        numeric: false,
        label: "Local Site ID",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "dwr_well_id",
        numeric: false,
        label: "State Well Number",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "source_org",
        numeric: false,
        label: "Source GSP <br /> Organization",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "owner",
        numeric: false,
        label: "Well Owner",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "coordinate_method",
        numeric: false,
        label: "Coordinate Collection Method",
        allowSort: false,
        hidden: true,
        dataType: "dropdown", 
        options: ["GPS", "Survey", "Digitize from USGS Quad Maps", "Unknown"],
    },{
        id: "latitude",
        numeric: true,
        label: "Site Latitude",
        allowSort: false,
        hidden: true,
        dataType: "number", 
        options: [],
    },{
        id: "horz_datum",
        numeric: true,
        label: "Horizontal Datum",
        allowSort: false,
        hidden: true,
        dataType: "text",
        options: false,
    },{
        id: "monitor_by",
        numeric: false,
        label: "Monitored By",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: []
    },{
        id: "latitude",
        numeric: true,
        label: "",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: []
    },{
        id: "longitude",
        numeric: true,
        label: "",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: []
    },{
        id: "origin_site_id",
        numeric: false,
        label: "Origin Site ID",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "accuracy",
        numeric: true,
        label:"Accuracy",
        allowSort: false,
        hidden: true,
        dataType: "numeric",
        options: false
    },{
        id: "updateby_id",
        numeric: false,
        label: "Updated By",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false 
    },{
        id: "updateby_label",
        numeric: false,
        label: "Update Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "updatedate",
        numeric: false,
        label: "Date Updated",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "updatedate_label",
        numeric: false,
        label: "Date Updated Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "label",
        numeric: false,
        label: "Label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id:"serverError",
        dataType: "ignore",
    }
];


export const welltimesconfig = [
    {
        id: "id",
        numeric: true,
        label: "Actions",
        allowSort: false,
        hidden: false,
        dataType: "hidden",
        options: false,
    },{
        id: "date",
        numeric: true,
        label: "Date",
        allowSort: true,
        hidden: false,
        dataType: "date",
        options: false
    },{
        id: "measurement",
        numeric: true,
        label: "Measurement",
        allowSort: true,
        hidden: false,
        dataType: "numeric",
        options: false
    },{
        id: "staticpumping",
        numeric: false,
        label: "Static Pumping",
        allowSort: true,
        hidden: false,
        dataType: "radio",
        options: ["Static", "Pumping"] // need to change values to "static" and "pumping" for the server to recognize them. 
    },{
        id: "comments",
        numeric: false,
        label: "Comments",
        allowSort: true,
        hidden: true,
        dataType: "longText",
        options: false
    },{
        id: "waterdepth",
        numeric: true,
        label: "Water Depth",
        allowSort: true,
        hidden: false,
        dataType: "numeric", // autoCalculate
        options: false
    },{
        id: "groundwaterelev",
        numeric: true,
        label: "Groundwater Elevation",
        allowSort: true,
        hidden: false,
        dataType: "numeric", // autoCalculate
        options: false
    },{
        id: "sampledby",
        numeric: true,
        label: "Sampled By",
        allowSort: true,
        hidden: true,
        dataType: "text",
        options: false,
    },{
        id: "well_id",
        numeric: true,
        label: "Well ID",
        allowSort: true,
        hidden: true,
        dataType: "hidden",
        options: false,
    },{
        id: "updateby_id",
        numeric: true,
        label: "Update By ID",
        allowSort: true,
        hidden: true,
        dataType: "numeric",
        options: false,
    },{
        id: "updateby_label",
        numeric: true,
        label: "Updated By",
        allowSort: true,
        hidden: true,
        dataType: "ignore",
        options: false,
    },{
        id: "well_label",
        numeric: true,
        label: "Well",
        allowSort: true,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "label",
        numeric: true, 
        label: "Label",
        allowSort: true,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "staticpumping_label",
        numeric: true,
        label: "Static Pumping Label",
        allowSort: true,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "date_table",
        numeric: false,
        label: "Date",
        allowSort: true,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "date_table_range",
        numeric: false,
        label:"Date Range",
        allowSort: true,
        hidden: true,
        dataType: "ignore",
        options: false
    }
];

export const WellRefConfig = [
    {
        id: "id",
        numeric: true,
        label: "Actions",
        hidden: false,
        dataType: "hidden",
        options: false,
    },{
        id: "date",
        numeric: true,
        label: "Date of Elevation",
        allowSort: true,
        hidden: false,
        dataType: "date",
        options: false
    },{
        id: "date_table",
        numeric: false,
        label: "Date",
        allowSort: true,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "date_table_range",
        numeric: false,
        label:"Date Range",
        allowSort: true,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "datum",
        numeric: false,
        label: "Elevation Datum",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "ground_surface",
        numeric: true,
        label:"Ground Surface Elevation (ft)",
        allowSort: true,
        hidden: false,
        dataType: "numeric",
        options: false
    },{
        id: "label",
        numeric: false,
        label:"label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "date_table_range",
        numeric: false,
        label:"Date Range",
        allowSort: true,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "method",
        numeric: false,
        label:"Survey Method",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false
    },{
        id: "reference_point",
        numeric: true,
        label:"Reference Point Elevation (ft)",
        allowSort: true,
        hidden: false,
        dataType: "numeric",
        options: false
    },{
        id: "total_depth",
        numeric: true,
        label:"Total Depth (ft)",
        allowSort: true,
        hidden: false,
        dataType: "numeric",
        options: false
    },{
        id: "well_id",
        numeric: true,
        label:"well_id",
        allowSort: true,
        hidden: true,
        dataType: "hidden",
        options: false
    }, {
        id: "well_label",
        numeric: false,
        label: "well_label",
        allowSort: false,
        hidden: true,
        dataType: "ignore",
        options: false
    }
];


export const wellmeasconfig = [
    {
        id: "id",
        numeric: true,
        label: "", // Actions
        allowSort: false,
        hidden: true,
        dataType: "hidden",
        options: false,
    },{
        id: "measurement_date",
        numeric: true,
        label: "Date",
        allowSort: true,
        hidden: false,
        dataType: "date",
        options: false
    },{
        id: "measurement_time",
        numeric: true,
        label: "Time",
        allowSort: true,
        hidden: false,
        dataType: "numeric",
        options: false
    },{
        id: "measurement_depth",
        numeric: true,
        label: "Meas. <br /> Depth from <br /> RP (ft)",
        allowSort: true,
        hidden: false,
        dataType: "numeric",
        options: false
    },{
        id: "surf_elev",
        numeric: true,
        label: "Water Surface <br /> Elevation (ft)",
        allowSort: true,
        hidden: false,
        dataType: "numeric",
        options: false
    },{
        id: "vert_datum",
        numeric: true,
        label: "Vertical <br /> Datum",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false
    },{
        id: "static_pumping",
        numeric: false,
        label: "Static or <br /> Pumping",
        allowSort: true,
        hidden: false,
        dataType: "radio",
        options: ["Static", "Pumping"] // need to change values to "static" and "pumping" for the server to recognize them. 
    },{
        id: "meas_method",
        numeric: false,
        label: "Meas. <br /> Method",
        allowSort: true,
        hidden: true,
        dataType: "text",
        options: false
    },{
        id: "meas_accuracy",
        numeric: true,
        label: "Meas. <br /> Accuracy",
        allowSort: true,
        hidden: false,
        dataType: "text", // autoCalculate
        options: false
    },{
        id: "quest_meas",
        numeric: false,
        label: "Quest. <br /> Meas.",
        allowSort: true,
        hidden: false,
        dataType: "text", // autoCalculate
        options: false
    },{
        id: "measured_by",
        numeric: false,
        label: "Measured By",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "meas_comments",
        numeric: false,
        label: "Meas. <br /> Comments",
        allowSort: true,
        hidden: false,
        dataType: "text",
        options: false,
    },{
        id: "label",
        numeric: true, 
        label: "Label",
        allowSort: true,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "staticpumping_label",
        numeric: true,
        label: "Static Pumping Label",
        allowSort: true,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "date_table",
        numeric: false,
        label: "Date",
        allowSort: true,
        hidden: true,
        dataType: "ignore",
        options: false
    },{
        id: "date_table_range",
        numeric: false,
        label:"Date Range",
        allowSort: true,
        hidden: true,
        dataType: "ignore",
        options: false
    }
];
/*
// Well Parameters

{
    id: # number that's not useful
    label: use as the label [check]
    name: probably better as the label.
    order: do an .orderby
    type: map to dataType // Text, Numeric, Checkbox, Drop Down
    values: map to options
}

// Well Results / Custom
/*
-> Convert to key/value pairs.
key: type_label (matches WellParameter.label)
value: value

{
    id: 14
    label: Result object (14)
    type_id: 5
    type_label: Test text (text)
    value: Text Tested
    well_id: 203
    well_label: 14S19E20N001MX
}
*/
