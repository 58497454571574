import "./index.css";

import React from "react";
import ReactDOM from "react-dom";

import { HashRouter, Route, Switch } from "react-router-dom";
import {
  MuiThemeProvider,
  createMuiTheme,
  createGenerateClassName,
} from "@material-ui/core/styles";
import JssProvider from "react-jss/lib/JssProvider";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { Provider as ReduxProvider } from "react-redux";
import { createReducer } from "redux-orm";
import { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import localForage from "localforage";
//import registerServiceWorker from "./registerServiceWorker";
import "whatwg-fetch";
import navReducer from "./components/common/reducers";
import saveReducer from "./components/charts/reducers";
import { storeReady } from "./components/common/actions";
import authReducer from "./components/auth/reducers";
import orm, { reloadAll, syncReducer } from "./components/common/orm";
import WarningDialogWrapper from "./components/common/WarningDialogWrapper";
import LoginPage from "./components/auth/LoginPage";
import PasswordPage from "./components/auth/PasswordPage";
import WellsMap from "./components/wells/WellsMap";
import WaterVolume from "./components/wells/WaterVolume";
import WellsList from "./components/wells/WellsList";
import MaintenanceLog from "./components/wells/MaintenanceLog";
import PumpTests from "./components/wells/PumpTests";

import WaterLevelList from "./components/wells/WaterLevelList";
import SWGWList from "./components/wells/SWGWList";
import SubsidenceList from "./components/wells/SubsidenceList";
import WaterQualityList from "./components/wells/WaterQualityList";
import GroundwaterStorageList from "./components/wells/GroundwaterStorageList";

import GroundwaterExtractionList from "./components/wells/GroundwaterExtractionList";
import SurfacewaterSupplyList from "./components/wells/SurfacewaterSupplyList";
import SurfacewaterUseList from "./components/wells/SurfacewaterUseList";

import WaterLevelDetail from "./components/wells/WaterLevelDetail";
import WaterQualityDetail from "./components/wells/WaterQualityDetail";
import SWGWDetail from "./components/wells/SWGWDetail";
import SubsidenceDetail from "./components/wells/SubsidenceDetail";

// import GroundwaterDetail from "./components/wells/GroundwaterDetail";
// Form Wizards
import ImportMonitoringData from "./components/datawizard/ImportMonitoringData";
import ImportMonitoringGSPs from "./components/datawizard/ImportMonitoringGSPs";
import FormWizard from "./components/datawizard/wizard";
import PasswordReset from "./components/adm/PasswordReset";
import UserProfile from "./components/adm/UserProfile";
import RegistrationForm from "./components/adm/RegistrationForm";

import WellCreateForm from "./components/forms/WellCreateForm";
import MaintenanceForm from "./components/forms/MaintenanceForm";
import PumpTestForm from "./components/forms/PumpTestForm";
import WellVolumePumpForm from "./components/forms/WellVolumePumpForm";

// Admin Screens
import AdminNavigation from "./components/administration/AdminNavigation";
import AdminUsers from "./components/administration/AdminUsers";
import EditUsers from "./components/administration/EditUsers";
import AdminEditUserPW from "./components/administration/AdminEditUserPW";
import AdminAgencies from "./components/administration/AdminAgencies";
import EditAgency from "./components/administration/EditAgency";
import AdminGSAs from "./components/administration/AdminGSAs";
import EditGSA from "./components/administration/EditGSA";
import AdminGSPs from "./components/administration/AdminGSPs";
import EditGSP from "./components/administration/EditGSP";
import AdminGSPReportingStatus from "./components/administration/AdminGSPReportingStatus";
import ReportingGWStorage from "./components/administration/ReportingGWStorage";
import ReportingGWExtraction from "./components/administration/ReportingGWExtraction";
import ReportingSWSupply from "./components/administration/ReportingSWSupply";
import ReportingGSA from "./components/administration/ReportingGSA";
import ReportingTotalWaterUse from "./components/administration/ReportingTotalWaterUse";
import ReportingHistory from "./components/administration/ReportingHistory";
import ImportMonitoringHistory from "./components/datawizard/ImportMonitoringHistory";
import GSPReportingStatus from "./components/administration/GSPReportingStatus";
import AdminWellSites from "./components/administration/AdminWellSites";
import EditWellSites from "./components/administration/EditWellSites";
import AdminSubsidenceSites from "./components/administration/AdminSubsidenceSites";
import EditSubsidenceSites from "./components/administration/EditSubsidenceSites";
import AdminSurfaceWaterSites from "./components/administration/AdminSurfaceWaterSites";
import EditSurfaceWaterSites from "./components/administration/EditSurfaceWaterSites";

//data views
import GridViewGroundwaterStorage from "./components/wells/GridViewGroundwaterStorage";
import GridViewGroundwaterExtraction from "./components/wells/GridViewGroundwaterExtraction";
import GridViewSurfaceWaterSupply from "./components/wells/GridViewSurfaceWaterSupply";
import GridViewTotalWaterUse from "./components/wells/GridViewTotalWaterUse";
import AdminCreateReport from "./components/administration/AdminCreateReport";
import AdminEditWaterYear from "./components/administration/AdminEditWaterYear";

// Skipping over the theme for now.
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "'Source Sans Pro', sans-serif",
  },
  overrides: {
    // Make the font color uniform
    MuiFormHelperText: {
      root: {
        lineHeight: "1.375em",
        color: "#666666",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "16px",
        fontWeight: 300,
        color: "#666666",
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "16px",
        fontWeight: 300,
        color: "#666666 !important",
      },
      shrink: {
        transform: "translate(0, 0px) scale(1)",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      root: {
        alignItems: "left",
      },
    },
    MuiListItem: {
      root: {
        fontSize: "16px",
        fontWeight: 300,
        color: "#666666",
      },
    },
    MuiTableSortLabel: {
      root: {
        whiteSpace: "nowrap",
      },
    },
    MuiTypography: {
      caption: {
        fontSize: 10,
      },
      // Page Header
      h4: {
        fontSize: "24px",
        fontWeight: 400,
        color: "black",
      },
      display2: {
        fontSize: "18px",
        fontWeight: 400,
        color: "#fff",
      },
      //Form group headings
      display3: {
        borderBottom: "2px solid #efefef",
        fontSize: "30px",
        fontWeight: 200,
        marginLeft: 0,
        letterSpacing: 0,
      },
      //Form group heading no underline
      display4: {
        fontSize: "30px",
        fontWeight: 200,
        marginLeft: 0,
        letterSpacing: 0,
      },
      // Smaller headings
      h6: {
        fontWeight: 300,
        fontSize: "20px",
        color: "#666666",
      },
      // Normal text
      body1: {
        fontWeight: 400,
        fontSize: "16px",
        color: "#666666",
      },
      subtitle1: {
        fontWeight: 400,
        fontSize: "1rem",
        color: "#ffffff",
      },
      gutterBottom: {
        marginBottom: ".6em",
      },
    },
    // Table cell 13px weight 400 defined in common/tablecell
    MuiButton: {
      root: {
        textTransform: "none",
        fontSize: "18px",
        fontWeight: 400,
        color: "#1364f7",
      },
    },
    MuiTableRow: {
      root: {
        height: 40,
      },
    },
  },
  palette: {
    primary: {
      // Blues
      light: "#6ae1ff",
      main: "#13aff1",
      dark: "#0080be",
      contrastText: "#fff",
    },
    secondary: {
      // Greys
      light: "#D3D3D3",
      main: "#808080",
      dark: "#A9A9A9",
      contrastText: "#fff",
    },
    navigation: {
      backgroundColor: "#242424",
    },
  },
  formField: {
    margin: "0px",
    width: "100%",
    padding: "0px",
    borderRadius: "3px",
    /*margin: '0px',*/
  },
});
const generateClassName = createGenerateClassName({
  //dangerouslyUseGlobalCSS: true,
  productionPrefix: "c",
});

const logger = createLogger();
const reducer = combineReducers({
  auth: authReducer,
  orm: createReducer(orm),
  sync: syncReducer,
  nav: navReducer,
  saver: saveReducer, // Assume this is for the navigation menu.
});

const initialState = {
  nav: {
    navigationOpen: true,
    libraryOpen: true,
    supportOpen: false,
    projectOpen: false,
    selectedPage: "dashboard",
    selectedButton: "table",
    wellSelected: false,
    dms_site_id: false,
    welltime_id: false,
    id: false,
    sw: { checking: true },
  },
};

const middleware =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(thunk)
    : applyMiddleware(thunk, logger);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancers(
    middleware,
    offline({
      ...offlineConfig,
      retry: (action, retries) => (retries > 3 ? null : retries * 1000),
      persistOptions: {
        storage: localForage,
        serialize: false,
      },
      persistAutoRehydrate: () =>
        offlineConfig.persistAutoRehydrate({
          stateReconciler: (state, inboundState) => {
            // Don't carry over pending from previous session
            // Loading icon becomes stuck
            if (inboundState.auth && inboundState.auth.pending) {
              inboundState.auth.pending = null;
            }
            // Don't wipe out sw notification if it happened during init
            if (state.nav && state.nav.sw) {
              inboundState.nav = {
                ...inboundState.nav,
                sw: state.nav.sw,
              };
            }
            inboundState.offline = {
              ...state.offline,
              ...inboundState.offline,
              online: state.offline.online,
              netInfo: state.offline.netInfo,
              busy: state.offline.busy,
            };
            inboundState.orm = {
              ...state.orm,
              ...inboundState.orm,
            };
            return inboundState;
          },
        }),
      persistCallback: () => {
        //const state = store.getState();
        store.dispatch(storeReady());
        //if (state && state.auth && state.auth.user && state.auth.user.auth_token)
        //    stopImpersonate(state.auth.user.auth_token);
        store.dispatch(reloadAll(true));
      },
    })
  )
);
/* Don't think we need this, Looks like it warns that the pages is about to change?  */
const getConfirmation = (message, callback) => {
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <WarningDialogWrapper message={message} callback={callback} />
    </MuiThemeProvider>,
    document.getElementById("pp")
  );
};

ReactDOM.render(
  <ReduxProvider store={store}>
    <JssProvider generateClassName={generateClassName}>
      <MuiThemeProvider theme={theme}>
        <HashRouter getUserConfirmation={getConfirmation}>
          <Switch>
            <Route exact path="/" component={LoginPage} />

            <Route path="/password/email" component={PasswordPage} />
            <Route path="/password/reset" component={PasswordReset} />
            <Route path="/userprofile" component={UserProfile} />

            {/* Agency Staff Screens */}
            <Route
              path="/dashboard"
              render={(props) => <WellsMap {...props} />}
            />
            <Route
              path="/waterlevellist"
              render={(props) => <WaterLevelList {...props} />}
            />
            <Route
              path="/swgwlist"
              render={(props) => <SWGWList {...props} />}
            />
            <Route
              path="/subsidencelist"
              render={(props) => <SubsidenceList {...props} />}
            />

            {/* unused */}
            <Route
              path="/waterqualitylist"
              render={(props) => <WaterQualityList {...props} />}
            />
            {/* unused */}
            <Route
              path="/groundwaterlist"
              render={(props) => <GroundwaterStorageList {...props} />}
            />

            <Route
              path="/groundwaterstorage"
              render={(props) => <GridViewGroundwaterStorage {...props} />}
            />
            <Route
              path="/groundwaterextraction"
              render={(props) => <GridViewGroundwaterExtraction {...props} />}
            />
            <Route
              path="/surfacewatersupply"
              render={(props) => <GridViewSurfaceWaterSupply {...props} />}
            />
            <Route
              path="/totalwateruse"
              render={(props) => <GridViewTotalWaterUse {...props} />}
            />

            {/* Import data screens */}
            <Route
              path="/import/:id"
              render={(props) => <ImportMonitoringData {...props} />}
            />
            <Route
              path="/import"
              render={(props) => <ImportMonitoringGSPs {...props} />}
            />
            <Route
              path="/wizard/:id"
              render={(props) => <FormWizard {...props} />}
            />

            <Route
              path="/importHistory/:id/:gsp"
              render={(props) => <ImportMonitoringHistory {...props} />}
            />
            <Route
              path="/gspReportingHistory/:id/:gsp"
              render={(props) => <ImportMonitoringHistory {...props} />}
            />
            <Route
              path="/importMonitoringHistory/:id/:gsp"
              render={(props) => <ImportMonitoringHistory {...props} />}
            />

            {/*<Route path="/reportwater" render={(props) => <ReportWater {...props} />} />*/}
            <Route
              path="/reporting/gwstorage/:gsp/:id"
              render={(props) => <ReportingGWStorage {...props} />}
            />
            <Route
              path="/reporting/gwstorage/:gsp"
              render={(props) => <ReportingGWStorage {...props} />}
            />
            <Route
              path="/reporting/gwextraction/:gsp/:id"
              render={(props) => <ReportingGWExtraction {...props} />}
            />
            <Route
              path="/reporting/gwextraction/:gsp"
              render={(props) => <ReportingGWExtraction {...props} />}
            />
            <Route
              path="/reporting/swsupply/:gsp/:id"
              render={(props) => <ReportingSWSupply {...props} />}
            />
            <Route
              path="/reporting/swsupply/:gsp"
              render={(props) => <ReportingSWSupply {...props} />}
            />
            <Route
              path="/reporting/totaluse/:gsp/:id"
              render={(props) => <ReportingTotalWaterUse {...props} />}
            />
            <Route
              path="/reporting/totaluse/:gsp"
              render={(props) => <ReportingTotalWaterUse {...props} />}
            />
            <Route
              path="/reporting"
              render={(props) => <ReportingGSA {...props} />}
            />
            <Route
              path="/reportinghistory/:id/:gsp"
              render={(props) => <ReportingHistory {...props} />}
            />
            <Route
              path="/reportinghistorygsp/:id/:gsp"
              render={(props) => <ReportingHistory {...props} />}
            />

            {/* Administrative Screens */}
            <Route
              path="/administration"
              render={(props) => <AdminNavigation {...props} />}
            />
            <Route
              path="/adminusers/:id/resetpassword"
              component={AdminEditUserPW}
            />
            <Route path="/adminusers/:id" component={EditUsers} />
            <Route
              path="/adminusers"
              render={(props) => <AdminUsers {...props} />}
            />
            <Route
              path="/adminagencies/:id"
              render={(props) => <EditAgency {...props} />}
            />
            <Route
              path="/adminagencies"
              render={(props) => <AdminAgencies {...props} />}
            />
            <Route
              path="/admingsas/:id"
              render={(props) => <EditGSA {...props} />}
            />
            <Route
              path="/admingsas"
              render={(props) => <AdminGSAs {...props} />}
            />
            <Route
              path="/admingsps/:id"
              render={(props) => <EditGSP {...props} />}
            />
            <Route
              path="/admingsps"
              render={(props) => <AdminGSPs {...props} />}
            />
            <Route
              path="/adminwellsites/:id"
              render={(props) => <EditWellSites {...props} />}
            />
            <Route
              path="/adminwellsites"
              render={(props) => <AdminWellSites {...props} />}
            />
            <Route
              path="/adminsubsidencesites/:id"
              render={(props) => <EditSubsidenceSites {...props} />}
            />
            <Route
              path="/adminsubsidencesites"
              render={(props) => <AdminSubsidenceSites {...props} />}
            />
            <Route
              path="/adminsurfacewatersites/:id"
              render={(props) => <EditSurfaceWaterSites {...props} />}
            />
            <Route
              path="/adminsurfacewatersites"
              render={(props) => <AdminSurfaceWaterSites {...props} />}
            />

            {/* unused */}
            <Route
              path="/adminreporting"
              render={(props) => <AdminGSPReportingStatus {...props} />}
            />

            <Route
              path="/gspreportingstatus"
              render={(props) => <GSPReportingStatus {...props} />}
            />

            <Route
              path="/admincreatereport"
              render={(props) => <AdminCreateReport {...props} />}
            />

            <Route
              path="/adminwateryear"
              render={(props) => <AdminEditWaterYear {...props} />}
            />

            {/* unused */}
            <Route
              path="/wellslist"
              render={(props) => <WellsList {...props} />}
            />

            {/* unused */}
            <Route
              path="/wellcreate"
              render={(props) => <WellCreateForm {...props} />}
            />

            {/* unused */}
            <Route
              path="/wellsmap"
              render={(props) => <WellsMap {...props} />}
            />

            {/* unused */}
            <Route
              path="/watervolume"
              render={(props) => <WaterVolume {...props} />}
            />

            {/* unused */}
            <Route
              path="/maintenancelog"
              render={(props) => <MaintenanceLog {...props} />}
            />

            {/* unused */}
            <Route
              path="/pumptests"
              render={(props) => <PumpTests {...props} />}
            />

            <Route
              path="/waterleveldetail/:id"
              render={(props) => <WaterLevelDetail {...props} />}
            />
            <Route
              path="/waterqualitydetail/:id"
              render={(props) => <WaterQualityDetail {...props} />}
            />
            <Route
              path="/swgwdetail/:id"
              render={(props) => <SWGWDetail {...props} />}
            />
            <Route
              path="/subsidencedetail/:id"
              render={(props) => <SubsidenceDetail {...props} />}
            />

            {/* unused */}
            <Route
              path="/gwextractionlist"
              render={(props) => <GroundwaterExtractionList {...props} />}
            />

            {/* unused */}
            <Route
              path="/swuselist"
              render={(props) => <SurfacewaterUseList {...props} />}
            />

            {/* unused */}
            <Route
              path="/swsupplylist"
              render={(props) => <SurfacewaterSupplyList {...props} />}
            />

            {/* unused */}
            <Route
              path="/maintenanceform"
              render={(props) => <MaintenanceForm {...props} />}
            />

            {/* unused */}
            <Route
              path="/pumptestform"
              render={(props) => <PumpTestForm {...props} />}
            />

            {/* unused */}
            <Route
              path="/watervolumepumping"
              render={(props) => <WellVolumePumpForm {...props} />}
            />

            <Route
              path="/register"
              render={(props) => <RegistrationForm {...props} />}
            />
          </Switch>
        </HashRouter>
      </MuiThemeProvider>
    </JssProvider>
  </ReduxProvider>,
  document.getElementById("root")
);
/*
 *<Route path="/login" component={LoginPage} />
 *<Route path="/activate/:uid/:token" component={AccountConfirmation
 *<Route path="/dashboard" component={Dashboard} />
 **/

//<Route path="/reset/confirm/:uid/:token" component={PasswordRecoverPage} />
//<Route path="/register" component={RegisterPage} />
//<Route path="/updateuser" component={UpdatePage} />
//registerServiceWorker(store.dispatch);
