import React, { Component } from "react";
import { Well } from "./models";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";

import PageHeader from "../common/PageHeader";

import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DashboardMap from "../common/map/Map";
import { withStyles } from "@material-ui/core/styles";

import AppContainer from "../common/AppContainer";
import * as authActions from "../auth/actions";
import * as navActions from "../common/actions";
import MaterialIcon from "material-icons-react";
import BreadcrumbNav from "../common/BreadCrumb";
import FilterControl from "../common/FilterControl";
import { wellTypeOpts, agencyOpts } from "../../api/constants";
const allWells = Well.selectAll();

const styles = theme => ({});

class Filter extends Component {
  render() {
    return "<div> Filter Groundwater Wells: <input name='wells_filter' /></div>";
  }
}

class Paginator extends Component {
  render() {
    return "`Page 1 of 2 `<Button>Next 1000</Button>";
  }
}
class WellsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterwell: true,
      filterWellId: "",
      filterAgency: "",
      filterWellType: "",
      filterStateWell: "",
      agencyOpts: agencyOpts
    };
    this.getUniqueAgencies(props);
  }
  componentDidUpdate(prevProps) {
    if (this.props.wells.length > 0 && prevProps.wells.length === 0)
      this.getUniqueAgencies(this.props);
  }
  getUniqueAgencies(props) {
    const { wells } = props;
    let uniqueAgencies = [""];
    wells.forEach(well => {
      if (!uniqueAgencies.includes(well.agency))
        uniqueAgencies.push(well.agency);
    });
    this.setState({
      agencyOpts: uniqueAgencies
    });
  }
  toggleMap(item) {
    this.setState({ filterwell: !this.state.filterwell });
  }
  handleNav = item => {
    this.props.well_selected(item.well_id, item.id);
    this.props.navSelectPage("welldetail_" + item.id);
    this.props.history.push("/welldetail/" + item.id);
  };

  applyFilters = (column, event) => {
    if (column === "Well ID") {
      this.setState({
        filterWellId: event.target.value
      });
    } else if (column === "State Well") {
      this.setState({
        filterStateWell: event.target.value
      });
    } else if (column === "Agency") {
      this.setState({
        filterAgency: event.target.value
      });
    } else if (column === "Well Type") {
      this.setState({
        filterWellType: event.target.value
      });
    }
  };

  render() {
    const { authState, wells } = this.props; // navigation, well_selected, navSelectPage,
    const {
      filterwell,
      filterWellId,
      filterAgency,
      filterWellType,
      filterStateWell,
      agencyOpts
    } = this.state;
    if (!authState) return "...";
    if (wells.length === 0) {
      return (
        <AppContainer>
          <PageHeader title="Wells List" />
          Loading...
        </AppContainer>
      );
    } else {
      return (
        <AppContainer authenticated>
          <BreadcrumbNav level1="Groundwater Wells" level1link="/wellslist" />
          <FilterControl
            style={{ width: "99%", margin: "0 auto" }}
            toggleMap={this.toggleMap.bind(this)}
            selectedSection={filterwell}
            filterWellId={filterWellId}
            filterAgency={filterAgency}
            agencyOpts={agencyOpts}
            filterWellType={filterWellType}
            filterStateWell={filterStateWell}
            wellTypeOpts={wellTypeOpts}
            applyFilters={this.applyFilters}
          />
          {Filter}
          {!filterwell ? (
            <DashboardMap />
          ) : (
            <div>
              <Table style={{ width: "99%", margin: "0 auto" }}>
                <TableHead style={{ "background-color": "#f0f0f0" }}>
                  <TableRow>
                    <TableCell>Actions</TableCell>
                    <TableCell>Well ID</TableCell>
                    <TableCell>Agency</TableCell>
                    <TableCell>Well Type</TableCell>
                    <TableCell>State Well</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {wells
                    .filter(item => item.well_id.includes(filterWellId))
                    .filter(item => item.agency.includes(filterAgency))
                    .filter(
                      item =>
                        (item.well_type === null && filterWellType === "") ||
                        (item.well_type !== null &&
                          item.well_type.includes(filterWellType))
                    )
                    .filter(
                      item =>
                        (item.state_well === null && filterStateWell === "") ||
                        (item.state_well !== null &&
                          item.state_well.includes(filterStateWell))
                    )
                    .slice(0, 30)
                    .map(item => (
                      <TableRow>
                        <TableCell style={{ "padding-right": "30px" }}>
                          <Button
                            style={{
                              float: "left",
                              padding: "0px",
                              "min-width": "10px"
                            }}
                            component={Link}
                            to={"/welldetail/" + item.id}
                            onClick={() => this.handleNav(item)}
                          >
                            <MaterialIcon icon="remove_red_eye" color="blue" />
                          </Button>
                        </TableCell>
                        <TableCell>{item.well_id}</TableCell>
                        <TableCell>{item.agency}</TableCell>
                        <TableCell>{item.well_type}</TableCell>
                        <TableCell>{item.state_well}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          )}
          {Paginator}
        </AppContainer>
      );
    }
  }
}

WellsList = connect(
  state => ({
    wells: allWells(state),
    authState: state.auth,
    error: state.error
  }),
  {
    ...Well.actions,
    ...authActions,
    ...navActions
  }
)(WellsList);

export default compose(withStyles(styles))(WellsList);
