import React, { Component } from "react";
import { FormField } from "react-form";

import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  formControl: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    width: "100%",
    border: "1px solid #979797",
    padding: "8px 0px 8px 8px",
    borderRadius: 4
  },
  opacity: {
    opacity: 0.8,
    [theme.breakpoints.up("xl")]: {
      width: "33% !important"
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    marginRight: 14,
    display: "flex",
    alignItems: "center"
  },
  groupName: {
    fontWeight: "bold",
    marginTop: 24,
    color: "rgba(0, 0, 0, 0.54) !important"
  }
});

function ArrayFormField(FormComponent) {
  const ConnectedFormField = FormField(FormComponent);
  class ConnectedArrayFormField extends ConnectedFormField {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
      const field = this.props.field,
        currentApi = this.context.formApi,
        nextApi = nextContext.formApi,
        currentValue = currentApi.values[field],
        nextValue = nextApi.values[field];
      if (
        currentValue &&
        nextValue &&
        currentValue.join(":") === nextValue.join(":")
      ) {
        // Copy exact array reference so FormField's equality comparsion works
        nextApi.values[field] = currentApi.values[field];
      }
      return super.shouldComponentUpdate(nextProps, nextState, nextContext);
    }
  }
  return ConnectedArrayFormField;
}

class CheckboxGroupWrapper extends Component {
  state = {
    disabled: []
  };
  componentDidMount() {
    this.handleChange();
  }

  handleChange = event => {
    const { fieldApi, eventHandle } = this.props;
    const { getValue, setValue, getFieldName } = fieldApi;

    const value = [...(getValue() || [])];

    if (event) {
      if (event.target.checked) value.push(event.target.value);
      else {
        var index = value.indexOf(event.target.value);
        if (index > -1) {
          value.splice(index, 1);
        }
      }
      setValue(value);
    }

    if (eventHandle) eventHandle(value, getFieldName(), this);
  };

  render() {
    const {
      fieldApi,
      label,
      children,
      options,
      classes,
      required,
      helperText,
      fullWidth,
      row,
      width,
      groups,
      standardWidth,
      ...rest
    } = this.props;

    const { getValue, getError } = fieldApi;

    const value = getValue() || [],
      error = getError();

    return (
      <>
        <FormHelperText>{children}</FormHelperText>
        <FormControl
          component="fieldset"
          required={required}
          error={error}
          fullWidth={fullWidth}
          className={classes.formControl}
        >
          <FormLabel component="legend">{label}</FormLabel>
          {!groups && (
            <FormGroup row={row}>
              {options.map(object => (
                <FormControlLabel
                  key={object.value}
                  style={{ width: width, marginRight: 14 }}
                  className={classes.opacity}
                  control={
                    <Checkbox
                      color="primary"
                      checked={value.includes(object.value)}
                      onChange={this.handleChange}
                      value={object.value}
                      disabled={this.state.disabled[object.value]}
                      {...rest}
                    />
                  }
                  label={object.label}
                />
              ))}
            </FormGroup>
          )}
          {groups &&
            options.map(group => (
              <div>
                <FormLabel component="legend" className={classes.groupName}>
                  {group.label}
                </FormLabel>
                <FormGroup row={row}>
                  {group.children.map(object => (
                    <FormControlLabel
                      key={group.label + " - " + object}
                      style={{ width: width, marginRight: 14 }}
                      className={classes.opacity}
                      control={
                        <Checkbox
                          color="primary"
                          checked={value.includes(group.label + " - " + object)}
                          onChange={this.handleChange}
                          value={group.label + " - " + object}
                          disabled={
                            this.state.disabled[group.label + " - " + object]
                          }
                          {...rest}
                        />
                      }
                      label={object}
                    />
                  ))}
                </FormGroup>
              </div>
            ))}
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </>
    );
  }
}

const FppCheckboxGroup = ArrayFormField(CheckboxGroupWrapper);

export default withStyles(styles)(FppCheckboxGroup);
