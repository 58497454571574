import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
//import { CircularProgress } from "material-ui/Progress";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  submitWrapper: {
    position: "relative"
  },
  progress: {
    top: "50%",
    left: "50%",
    color: "#4CAF50",
    position: "absolute",
    marginTop: -12,
    marginLeft: -12
  }
};

export const BlueButton = ({ label, theAction, theIcon }) => (
  <Button
    fullWidth
    component={Link}
    to={theAction}
    variant="contained"
    style={{ backgroundColor: "blue", color: "white" }}
  >
    {theIcon} &nbsp;&nbsp; {label}
  </Button>
);
export const BlueSubmitButton = ({ label, theIcon }) => (
  <Button
    fullWidth
    variant="contained"
    style={{ backgroundColor: "blue", color: "white" }}
  >
    {theIcon} &nbsp;&nbsp; {label}
  </Button>
);
export const BlueOnClick = ({ label, onClick, theIcon }) => (
  <Button
    fullWidth
    onClick={onClick}
    variant="contained"
    style={{ backgroundColor: "blue", color: "white" }}
  >
    {theIcon} &nbsp;&nbsp; {label}
  </Button>
);

const BackButton = ({
  label,
  subLabel,
  altAction,
  altLabel,
  classes,
  loading,
  gridSize = 12,
  justify = "center",
  greyButton = false
}) => (
  <Grid container justify={justify}>
    {greyButton ? (
      <Grid item xs={gridSize / 2}>
        <Button
          fullWidth
          component={Link}
          to={altAction}
          variant="contained"
          color="grey"
        >
          {altLabel}
        </Button>
      </Grid>
    ) : (
      <Grid item xs={gridSize / 2}>
        <Button
          fullWidth
          component={Link}
          to={altAction}
          variant="contained"
          color="primary"
        >
          {altLabel}
        </Button>
      </Grid>
    )}
  </Grid>
);
//{loading && <CircularProgress size={24} className={classes.progress} />}
export default withStyles(styles)(BackButton);
