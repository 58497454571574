import React from "react";
//import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
//import { CircularProgress } from "@material-ui/core/Progress";
import { withStyles } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
//import MaterialIcon from 'material-icons-react';
import TextField from '@material-ui/core/TextField';

import Icon from "@mdi/react";
import { mdiMap, mdiTableLarge } from "@mdi/js";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    filterInput: {
        padding: '3px 12px',
        width: '90%',
    },
});
const doNone = function() {
    console.log('doing nothing');
}

class FilterControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            wellFilterActive: false,
            filterValue: "",
        };
    }
    wellToggle = (e) => {
        if (e.target.value === "Well ID" || e.target.value === "State Well") {
            this.setState({
                wellFilterActive: true,
                filterValue: e.target.value,
            });
        } else {
            this.setState({
                wellFilterActive: false,
                filterValue: e.target.value,
            });
            this.props.applyFilters("Well ID", e); // Sets back to blank. 
        }
    }
    
    passFilters = column => event => {
        this.props.applyFilters(column, event);
    }
    
    render() {
        const { classes, toggleMap, selectedSection} = this.props; 
        
        return (
            <Grid container className={classes.root} justify="center" style={{ "width":"99%", "margin":"0 auto" }} spacing={24}>
                <Grid item xs={10}>Filter by Category</Grid>
                <Grid item xs={2}>Select View</Grid>
                <Grid item xs={10}>
                    <Grid container className={classes.root} spacing={24}>
                        <Grid item xs={4}>
                            <Paper>
                                <Select
                                    className={classes.filterInput}
                                    fullWidth
                                    value={this.state.filterValue}
                                    onChange={this.wellToggle.bind(this)}
                                >
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value={"Well ID"}>Well ID</MenuItem>
                                    <MenuItem value={"State Well"}>State Well</MenuItem>
                                </Select>
                                <TextField
                                    className={classes.filterInput}
                                    style={{"display": this.state.wellFilterActive ? 'block' : 'none'}}
                                    fullWidth
                                    value={this.state.filterValue === 'Well ID' ? this.props.filterWellId : this.props.filterStateWell}
                                    onChange={this.state.filterValue === 'Well ID' ? this.passFilters("Well ID") : this.passFilters('State Well')}
                                    type="text"
                                />
                            </Paper>
                        </Grid>
                        {/*
                        <Grid item xs={4}>
                            <Paper>
                                <Select
                                    className={classes.filterInput}
                                    fullWidth
                                    value={this.props.filterAgency}
                                    onChange={this.passFilters('Agency')}
                                >
                                    {this.props.agencyOpts.map(item =>
                                       <MenuItem value={item}>{item}</MenuItem>
                                    )}
                                </Select>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper>
                                <Select
                                    className={classes.filterInput}
                                    fullWidth
                                    value={this.props.filterWellType}
                                    onChange={this.passFilters('Well Type')}
                                >
                                    {this.props.wellTypeOpts.map(item =>
                                       <MenuItem value={item}>{item}</MenuItem>
                                    )}
                                </Select>
                            </Paper>
                        </Grid>
                        */}
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button onClick={selectedSection ? toggleMap : doNone}>
                                    <Icon path={mdiMap} size={1} color="Blue" />
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button onClick={selectedSection ? doNone : toggleMap}>
                                    <Icon path={mdiTableLarge} size={1} color="Blue" />
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
};

export default withStyles(styles)(FilterControl);


/*
 *
4. Breadcrumbs Links:
background-color: inherit
color: black
border-color:blue
double-right-arrow

:first-child
right_arrow
*/