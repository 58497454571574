import React, { Component } from "react";
import { connect } from "react-redux";

import PageHeader from '../common/PageHeader';
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as navActions from "../common/actions";


const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
});

class WaterVolume extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded:false,
        };
        this.handleNav = this.handleNav.bind(this);
    }
    handleNav(item, history) {
        console.log('Navigating');
        console.log(item);
        history.push({pathname: '/watervolumepumping', item:item});
    }     
    
    render() {
        const { error, isLoaded, item, itemres } = this.state;
        const { authState } = this.props; 
        if (!authState) {
            return "...";     
        } if (error) {
            return <div>Error loading Wells</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <PageHeader title={item.well_id + ' Well Volume Pumps'} />
                    <Button
                        title={item.well_id}
                        onClick={() => this.handleNav(item, this.props.history)}
                    >Create New Well Volume Pump</Button>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Actions</TableCell>
                                <TableCell>Start Date</TableCell>
                                <TableCell>End Date</TableCell>
                                <TableCell>Calculation Type</TableCell>
                                <TableCell>Collected By</TableCell>
                                <TableCell>Starting Flow Meter</TableCell>
                                <TableCell>Kw/h</TableCell>
                                <TableCell>Hours</TableCell>
                                <TableCell>Volume Pumped (AF)</TableCell>
                                <TableCell>Additional Calculation Parameters</TableCell>
                                <TableCell>Notes</TableCell>
                                <TableCell>New PG&E Meter</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {itemres.map(it => (
                                <TableRow>
                                    <TableCell><Button
                                        title={it.id}
                                        onClick={() => this.handleNav(item, this.props.history)}
                                    >View/Edit</Button></TableCell>
                                    <TableCell>{it.datestart}</TableCell>
                                    <TableCell>{it.dateend}</TableCell>
                                    <TableCell>{it.calctype}</TableCell>
                                    <TableCell>{it.collectedby}</TableCell>
                                    <TableCell>{it.startflow}</TableCell>
                                    <TableCell>{it.kwh}</TableCell>
                                    <TableCell>{it.hours}</TableCell>
                                    <TableCell>{it.volumepumped}</TableCell>
                                    <TableCell>{it.additionalparameters}</TableCell>
                                    <TableCell>{it.notes}</TableCell>
                                    <TableCell>{it.pgmeter}</TableCell>
                                </TableRow> 
                            ))}
                        </TableBody>
                    </Table>
                </div>
            );
        }
    }
}
WaterVolume = connect(
    state => ({
        navState: state.nav,
        authState: state.auth
    }),
    {
        ...navActions
    }
)(WaterVolume);

export default withStyles(styles)(WaterVolume); //withStyles(styles)
