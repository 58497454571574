import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "../common/orm";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import BreadcrumbNav from "../common/BreadCrumb";
import TableCell from "../common/TableCell";
import NumberFormat from "react-number-format";
import Icon from "@mdi/react";
import { mdiEye } from "@mdi/js";

const historicWaterReporting = createSelector((session, options) => {
  let gsp = session.GSP.all().toRefArray();
  let wy = session.WaterYear.all().toRefArray();
  let gws =
    gsp && wy
      ? session.SurfaceWaterSupply.filter((r) => r.submitted == true)
          .toRefArray()
          .map((water) => {
            return {
              sws_id: water.id,
              ...water,
              ...wy.find((w) => w.id === water.water_year_id),
              ...gsp.find((g) => g.id === water.gsp),
            };
          })
      : [];
  if (gws.length) {
    console.log(gws);
  }
  return gws;
});
const styles = (theme) => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee",
  },
  expansionBorder: {
    borderTop: "1px solid " + theme.palette.primary.light,
    borderBottom: "1px solid " + theme.palette.primary.light,
  },
  lastBorder: {
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lastBorder2: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  expandedMargin: {
    marginTop: "12px",
    minHeight: "48px !important",
    maxHeight: 48,
  },
  borderContainer: {
    borderTop: "1px solid " + theme.palette.primary.light,
    borderBottom: "1px solid " + theme.palette.primary.light,
    borderRight: "2px solid " + theme.palette.primary.light,
    borderLeft: "2px solid " + theme.palette.primary.light,
  },
  borderContainer2: {
    border: "1px solid rgb(221, 221, 221)",
  },
  buttonSelected: {
    border: "3px solid #13aff1",
    minWidth: "44px",
  },
  buttonNotSelected: {
    border: "3px solid #666",
    minWidth: "44px",
  },
  iconSelected: {
    color: "#13aff1",
  },
  iconNotSelected: {
    color: "#666",
  },
  divideButton: {
    marginBottom: 16,
    width: "calc(50% - 16px)",
    marginTop: 16,
    minWidth: 44,
  },
  divideContainer: {
    backgroundColor: "#ddd",
    textAlign: "center",
    margin: "0 -4px",
  },
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 500,
    "& tbody tr:nth-child(even)": {
      backgroundColor: "rgb(106,225,255,.2)",
    },
  },
  centerAlign: {
    textAlign: "center",
  },
  marginLeft: {
    marginLeft: -16,
  },
  chartTitle: {
    backgroundColor: "rgb(221, 221, 221)",
    textAlign: "center",
    padding: "8px 4px !important",
  },
});
class GridViewSurfaceWaterSupply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "desc",
      orderBy: "water_year",
      page: 0,
      rowsPerPage: 10,
      histdata: [],
    };
  }
  componentDidMount() {}
  columnData() {
    const groundwaterStorageCols = [
      {
        id: "gsp_label",
        numeric: false,
        label: "GSP Organization Name",
        allowSort: true,
      },
      { id: "water_year", numeric: true, label: "Year", allowSort: true },
      // { id: "details", numeric: true, label: "Details", allowSort: false },
      {
        id: "sw_dom_import_cv",
        numeric: true,
        // label: "Urban | Imported Central Valley Project",
        label: "Central Valley Project",
        allowSort: true,
      },
      {
        id: "sw_dom_import_sw",
        numeric: true,
        // label: "Urban | Imported State Water Project",
        label: "State Water Project",
        allowSort: true,
      },
      {
        id: "sw_dom_import_l",
        numeric: true,
        // label: "Urban | Imported Local Imported Supplies",
        label: "Local Imported Supplies",
        allowSort: true,
      },
      {
        id: "sw_dom_riv",
        numeric: true,
        // label: "Urban | River",
        label: "Kings River",
        allowSort: true,
      },
      // {
      //   id: "sw_dom_creek",
      //   numeric: true,
      //   label: "Urban | Creek",
      //   allowSort: true,
      // },
      {
        id: "sw_dom_recycled",
        numeric: true,
        // label: "Urban | Recycled",
        label: "Recycled Water",
        allowSort: true,
      },
      // {
      //   id: "sw_dom_desalination",
      //   numeric: true,
      //   label: "Urban | Desalination",
      //   allowSort: true,
      // },
      {
        id: "sw_dom_other",
        numeric: true,
        // label: "Urban | Other",
        label: "Other",
        allowSort: true,
      },
      // {
      //   id: "sw_dom_other_desc",
      //   numeric: true,
      //   label: "Urban | Other Desc.",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_dom_total",
      //   numeric: true,
      //   label: "Urban",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_dom_notes",
      //   numeric: false,
      //   label: "Urban | Source Notes",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_dom_calcm",
      //   numeric: false,
      //   label: "Urban | Calculation Method",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_dom_acc",
      //   numeric: false,
      //   label: "Urban | Calculation Accuracy",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_ind_import_cv",
      //   numeric: true,
      //   label: "Industrial | Imported Central Valley Project",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_ind_import_sw",
      //   numeric: true,
      //   label: "Industrial | Imported State Water Project",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_ind_import_l",
      //   numeric: true,
      //   label: "Industrial | Imported Local Imported Supplies",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_ind_riv",
      //   numeric: true,
      //   label: "Industrial | River",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_ind_creek",
      //   numeric: true,
      //   label: "Industrial | Creek",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_ind_recycled",
      //   numeric: true,
      //   label: "Industrial | Recycled",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_ind_desalination",
      //   numeric: true,
      //   label: "Industrial | Desalination",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_ind_other",
      //   numeric: true,
      //   label: "Industrial | Other",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_ind_other_desc",
      //   numeric: true,
      //   label: "Industrial | Other Desc.",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_ind_total",
      //   numeric: true,
      //   label: "Industrial",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_ind_notes",
      //   numeric: false,
      //   label: "Industrial | Source Notes",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_ind_calcm",
      //   numeric: false,
      //   label: "Industrial | Calculation Method",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_ind_acc",
      //   numeric: false,
      //   label: "Industrial | Calculation Accuracy",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_agg_import_cv",
      //   numeric: true,
      //   label: "Agricultural | Imported Central Valley Project",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_agg_import_sw",
      //   numeric: true,
      //   label: "Agricultural | Imported State Water Project",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_agg_import_l",
      //   numeric: true,
      //   label: "Agricultural | Imported Local Imported Supplies",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_agg_riv",
      //   numeric: true,
      //   label: "Agricultural | River",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_agg_creek",
      //   numeric: true,
      //   label: "Agricultural | Creek",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_agg_recycled",
      //   numeric: true,
      //   label: "Agricultural | Recycled",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_agg_desalination",
      //   numeric: true,
      //   label: "Agricultural | Desalination",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_agg_other",
      //   numeric: true,
      //   label: "Agricultural | Other",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_agg_other_desc",
      //   numeric: true,
      //   label: "Agricultural | Other Desc.",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_agg_total",
      //   numeric: true,
      //   label: "Agricultural",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_agg_notes",
      //   numeric: false,
      //   label: "Agricultural | Source Notes",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_agg_calcm",
      //   numeric: false,
      //   label: "Agricultural | Calculation Method",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_agg_acc",
      //   numeric: false,
      //   label: "Agricultural | Calculation Accuracy",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_wet_import_cv",
      //   numeric: true,
      //   label: "Managed Wetlands | Imported Central Valley Project",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_wet_import_sw",
      //   numeric: true,
      //   label: "Managed Wetlands | Imported State Water Project",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_wet_import_l",
      //   numeric: true,
      //   label: "Managed Wetlands | Imported Local Imported Supplies",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_wet_riv",
      //   numeric: true,
      //   label: "Managed Wetlands | River",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_wet_creek",
      //   numeric: true,
      //   label: "Managed Wetlands | Creek",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_wet_recycled",
      //   numeric: true,
      //   label: "Managed Wetlands | Recycled",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_wet_desalination",
      //   numeric: true,
      //   label: "Managed Wetlands | Desalination",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_wet_other",
      //   numeric: true,
      //   label: "Managed Wetlands | Other",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_wet_other_desc",
      //   numeric: true,
      //   label: "Managed Wetlands | Other Desc.",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_wet_total",
      //   numeric: true,
      //   label: "Managed Wetlands",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_wet_notes",
      //   numeric: false,
      //   label: "Managed Wetlands | Source Notes",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_wet_calcm",
      //   numeric: false,
      //   label: "Managed Wetlands | Calculation Method",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_wet_acc",
      //   numeric: false,
      //   label: "Managed Wetlands | Calculation Accuracy",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_out_import_cv",
      //   numeric: true,
      //   label: "Outside Subbasin | Imported Central Valley Project",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_out_import_sw",
      //   numeric: true,
      //   label: "Outside Subbasin | Imported State Water Project",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_out_import_l",
      //   numeric: true,
      //   label: "Outside Subbasin | Imported Local Imported Supplies",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_out_riv",
      //   numeric: true,
      //   label: "Outside Subbasin | River",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_out_creek",
      //   numeric: true,
      //   label: "Outside Subbasin | Creek",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_out_recycled",
      //   numeric: true,
      //   label: "Outside Subbasin | Recycled",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_out_desalination",
      //   numeric: true,
      //   label: "Outside Subbasin | Desalination",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_out_other",
      //   numeric: true,
      //   label: "Outside Subbasin | Other",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_out_other_desc",
      //   numeric: true,
      //   label: "Outside Subbasin | Other Desc.",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_out_total",
      //   numeric: true,
      //   label: "Outside Subbasin",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_out_notes",
      //   numeric: false,
      //   label: "Outside Subbasin | Source Notes",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_out_calcm",
      //   numeric: false,
      //   label: "Outside Subbasin | Calculation Method",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_out_acc",
      //   numeric: false,
      //   label: "Outside Subbasin | Calculation Accuracy",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_veg_import_cv",
      //   numeric: true,
      //   label: "Native Vegetation | Imported Central Valley Project",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_veg_import_sw",
      //   numeric: true,
      //   label: "Native Vegetation | Imported State Water Project",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_veg_import_l",
      //   numeric: true,
      //   label: "Native Vegetation | Imported Local Imported Supplies",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_veg_riv",
      //   numeric: true,
      //   label: "Native Vegetation | River",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_veg_creek",
      //   numeric: true,
      //   label: "Native Vegetation | Creek",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_veg_recycled",
      //   numeric: true,
      //   label: "Native Vegetation | Recycled",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_veg_desalination",
      //   numeric: true,
      //   label: "Native Vegetation | Desalination",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_veg_other",
      //   numeric: true,
      //   label: "Native Vegetation | Other",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_veg_other_desc",
      //   numeric: true,
      //   label: "Native Vegetation | Other Desc.",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_veg_total",
      //   numeric: true,
      //   label: "Native Vegetation",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_veg_notes",
      //   numeric: false,
      //   label: "Native Vegetation | Source Notes",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_veg_calcm",
      //   numeric: false,
      //   label: "Native Vegetation | Calculation Method",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_veg_acc",
      //   numeric: false,
      //   label: "Native Vegetation | Calculation Accuracy",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_other_use_desc",
      //   numeric: true,
      //   label: "Other | Other Use Desc.",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_other_import_cv",
      //   numeric: true,
      //   label: "Other | Imported Central Valley Project",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_other_import_sw",
      //   numeric: true,
      //   label: "Other | Imported State Water Project",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_other_import_l",
      //   numeric: true,
      //   label: "Other | Imported Local Imported Supplies",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_other_riv",
      //   numeric: true,
      //   label: "Other | River",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_other_creek",
      //   numeric: true,
      //   label: "Other | Creek",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_other_recycled",
      //   numeric: true,
      //   label: "Other | Recycled",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_other_desalination",
      //   numeric: true,
      //   label: "Other | Desalination",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_other_other",
      //   numeric: true,
      //   label: "Other | Other",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_other_other_desc",
      //   numeric: true,
      //   label: "Other | Other Desc.",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_other_total",
      //   numeric: true,
      //   label: "Other",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_other_notes",
      //   numeric: false,
      //   label: "Other | Source Notes",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_other_calcm",
      //   numeric: false,
      //   label: "Other | Calculation Method",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_other_acc",
      //   numeric: false,
      //   label: "Other | Calculation Accuracy",
      //   allowSort: true,
      // },
    ];
    return groundwaterStorageCols;
  }
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    const colType = this.props.match.params["id"];
    const columnData = this.columnData(colType);
    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.state.histdata.sort(function (a, b) {
      var numeric = columnData.find((cD) => cD.id === orderBy).numeric;
      if (numeric) {
        if (order === "desc")
          return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
        else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
      } else {
        if (order === "desc")
          return (b[orderBy] || "").toUpperCase() <
            (a[orderBy] || "").toUpperCase()
            ? -1
            : 1;
        else
          return (a[orderBy] || "").toUpperCase() <
            (b[orderBy] || "").toUpperCase()
            ? -1
            : 1;
      }
    });
    this.setState({ order, orderBy });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };
  render() {
    const { classes, waterhist } = this.props;
    const { page, rowsPerPage, order, orderBy, histdata } = this.state;
    if (waterhist && waterhist.length) {
      if (!histdata.length) {
        this.setState({ histdata: waterhist });
      }
    }
    let level1 = "Surface Water Supply";
    let level1link = "/surfacewatersupply";
    return (
      <AppContainer authenticated>
        <Grid container spacing={24}>
          <Grid item xs={12} className={classes.breadCrumb}>
            <BreadcrumbNav level1={level1} level1link={level1link} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
            Submitted Surface Water Supply Data (AF)
            </Typography>
          </Grid>
          {histdata ? (
            <Grid item xs={12}>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <EnhancedTableHead
                    columnData={this.columnData()}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                  />
                  <TableBody>
                    {histdata
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.gsp_label}</TableCell>
                          <TableCell>{item.water_year}</TableCell>
                          {/* <TableCell>
                            <Tooltip title={"View Data"}>
                              <Button
                                className={classes.tableCellIcon}
                                onClick={() =>
                                  this.props.history.push({
                                    pathname:
                                      "/reporting/swsupply/" +
                                      item.id +
                                      "/" +
                                      item.sws_id,
                                  })
                                }
                              >
                                <Icon
                                  path={mdiEye}
                                  size={1}
                                  color="primary"
                                ></Icon>{" "}
                              </Button>
                            </Tooltip>
                          </TableCell> */}
                           <TableCell>
                            <NumberFormat
                              value={item.sw_dom_import_cv}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_dom_import_sw}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_dom_riv}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_dom_import_l}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          {/* <TableCell>
                            <NumberFormat
                              value={item.sw_dom_creek}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          <TableCell>
                            <NumberFormat
                              value={item.sw_dom_recycled}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          {/* <TableCell>
                            <NumberFormat
                              value={item.sw_dom_desalination}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          <TableCell>
                            <NumberFormat
                              value={item.sw_dom_other}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          {/*<TableCell>{item.sw_dom_other_desc}</TableCell> */}
                          {/* <TableCell>
                            <NumberFormat
                              value={item.sw_dom_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          {/* <TableCell>{item.sw_dom_notes}</TableCell>
                          <TableCell>{item.sw_dom_calcm}</TableCell>
                          <TableCell>{item.sw_dom_acc}</TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_ind_import_cv}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_ind_import_sw}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_ind_import_l}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_ind_riv}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_ind_creek}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_ind_recycled}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_ind_desalination}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_ind_other}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>{item.sw_ind_other_desc}</TableCell> */}
                          {/* <TableCell>
                            <NumberFormat
                              value={item.sw_ind_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          {/* <TableCell>{item.sw_ind_notes}</TableCell>
                          <TableCell>{item.sw_ind_calcm}</TableCell>
                          <TableCell>{item.sw_ind_acc}</TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_agg_import_cv}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_agg_import_sw}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_agg_import_l}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_agg_riv}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_agg_creek}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_agg_recycled}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_agg_desalination}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_agg_other}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>{item.sw_agg_other_desc}</TableCell> */}
                          {/* <TableCell>
                            <NumberFormat
                              value={item.sw_agg_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          {/* <TableCell>{item.sw_agg_notes}</TableCell>
                          <TableCell>{item.sw_agg_calcm}</TableCell>
                          <TableCell>{item.sw_agg_acc}</TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_wet_import_cv}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_wet_import_sw}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_wet_import_l}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_wet_riv}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_wet_creek}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_wet_recycled}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_wet_desalination}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_wet_other}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>{item.sw_wet_other_desc}</TableCell> */}
                          {/* <TableCell>
                            <NumberFormat
                              value={item.sw_wet_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          {/* <TableCell>{item.sw_wet_notes}</TableCell>
                          <TableCell>{item.sw_wet_calcm}</TableCell>
                          <TableCell>{item.sw_wet_acc}</TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_out_import_cv}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_out_import_sw}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_out_import_l}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_out_riv}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_out_creek}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_out_recycled}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_out_desalination}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_out_other}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>{item.sw_out_other_desc}</TableCell> */}
                          {/* <TableCell>
                            <NumberFormat
                              value={item.sw_out_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          {/* <TableCell>{item.sw_out_notes}</TableCell>
                          <TableCell>{item.sw_out_calcm}</TableCell>
                          <TableCell>{item.sw_out_acc}</TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_veg_import_cv}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_veg_import_sw}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_veg_import_l}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_veg_riv}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_veg_creek}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_veg_recycled}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_veg_desalination}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_veg_other}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>{item.sw_veg_other_desc}</TableCell> */}
                          {/* <TableCell>
                            <NumberFormat
                              value={item.sw_veg_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          {/* <TableCell>{item.sw_veg_notes}</TableCell>
                          <TableCell>{item.sw_veg_calcm}</TableCell>
                          <TableCell>{item.sw_veg_acc}</TableCell>
                          <TableCell>{item.sw_other_use_desc}</TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_other_import_cv}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_other_import_sw}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_other_import_l}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_other_riv}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_other_creek}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_other_recycled}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_other_desalination}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_other_other}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>{item.sw_other_other_desc}</TableCell> */}
                          {/* <TableCell>
                            <NumberFormat
                              value={item.sw_other_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          {/* <TableCell>{item.sw_other_notes}</TableCell>
                          <TableCell>{item.sw_other_calcm}</TableCell>
                          <TableCell>{item.sw_other_acc}</TableCell> */}
                        </TableRow>
                      ))}
                    {histdata.length < 1 && (
                      <TableRow>
                        <TableCell colSpan={7} className={classes.centerAlign}>
                          No Historical Data Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  {histdata.length > rowsPerPage && (
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          colSpan={7}
                          count={histdata.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActionsWrapped}
                        />
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              </Paper>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </AppContainer>
    );
  }
}
GridViewSurfaceWaterSupply = connect(
  (state, ownProps) => ({
    waterhist: historicWaterReporting(state, ownProps),
    authState: state.auth,
  }),
  {}
)(GridViewSurfaceWaterSupply);
export default withStyles(styles)(GridViewSurfaceWaterSupply);
