import React, { Component } from "react";
import { WaterQualitySite } from "./models";
import { connect } from "react-redux";
import { compose } from 'redux';

import DashboardMap from "../common/map/Map";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import AppContainer from "../common/AppContainer";
import * as authActions from "../auth/actions";
import * as navActions from "../common/actions";

import BreadcrumbNav from "../common/BreadCrumb";
import FilterControl from "../common/FilterControl";
import { wellTypeOpts, agencyOpts } from "../../api/constants";
import TimeSeriesTable from "../charts/TimeSeriesTable";
import { WaterQualitySiteConfig } from "../../api/formConfigs";

const allWells = WaterQualitySite.selectAll();

const styles = theme => ({
    tc: {
        padding: '4px 10px 4px 10px',
    },
    breadCrumb: {
        width: "100vw",
        margin: "0 -20px",
        padding: "0px 35px !important",
        maxWidth: "105%",
        flexBasis: "auto",
        backgroundColor: "#eeeeee"
    },
});

class SurfacewaterSupplyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterwell: true,
            filterWellId: '',
            filterAgency: '',
            filterWellType: '',
            filterStateWell: '',
            agencyOpts: agencyOpts,
        };
        this.getUniqueAgencies(props);
    }
    componentDidUpdate(prevProps) {
        if (this.props.wells.length > 0 && prevProps.wells.length === 0)
            this.getUniqueAgencies(this.props);
    }
    getUniqueAgencies(props) {
        const { wells } = props;
        let uniqueAgencies = [""];
        wells.forEach((well) => {
            if (!uniqueAgencies.includes(well.agency))
                uniqueAgencies.push(well.agency);
        });
        this.setState({
            agencyOpts: uniqueAgencies,
        });
    }
    toggleMap() {
        this.setState({filterwell: !this.state.filterwell});
    };
    handleNav = (item) => {
        console.log('navigating with item: ');
        this.props.well_selected(item.dms_site_id, item.id);
        this.props.navSelectPage("waterlevel" + item.id);
        this.props.history.push("/waterlevel/" + item.id);
    };
    
    applyFilters = (column, event) => {
        if (column === "DMS Site ID") {
            this.setState({
                dmsSiteId: event.target.value,
            });
        } else if (column === "Local Site ID") {
            this.setState({
                localSiteId: event.target.value,
            });
        } else if (column === "State Well Number") {
            this.setState({
                stateWellNumber: event.target.value,
            });
        } else if (column === "Source GSP Organization") {
            this.setState({
                sourceOrg: event.target.value,
            });
        } else if (column === "Well Owner") {
            this.setState({
                wellOwner: event.target.value,
            })
        }
    };
    
    render() {
        const { authState, wells, classes } = this.props; 
        const { filterwell, dmsSiteId, localSiteId, stateWellNumber, sourceOrg, wellOwner, } = this.state;
        if (!authState) return "...";
        if (wells.length === 0) {
            return (
                <AppContainer authenticated>
                    <Grid container spacing={24}>
                        <Grid item xs={12} className={classes.breadCrumb}>
                            <BreadcrumbNav level1='Surface Water Supply Sites' level1link='/swsupplylist' />
                        </Grid>
                        <Grid item xs={12} mg={9} lg={6} xl={3}>
                            <Typography>No Data to See</Typography>
                        </Grid>
                    </Grid>
                </AppContainer>
            )
        } else {
            return (
                <AppContainer authenticated>
                    <BreadcrumbNav level1='Water Quality Sites' level1link='/waterqualitylist' />
                    <FilterControl
                        style={{ "width":"99%", "margin":"0 auto" }}
                        toggleMap={this.toggleMap.bind(this)}
                        selectedSection={filterwell}
                        filter1={dmsSiteId}
                        filter2={localSiteId}
                        filter3={stateWellNumber}
                        filter4={sourceOrg}
                        filter5={wellOwner}
                        gspOpts={wellTypeOpts}
                        wellTypeOpts={wellTypeOpts}
                        applyFilters={this.applyFilters}
                    />
                    {!filterwell ? 
                        <DashboardMap />
                        : 
                        <div>
                            <TimeSeriesTable
                                fieldconf={WaterQualitySiteConfig}
                                data={wells}
                                editFunc={this.handleNav.bind(this)} />
                            
                        </div>
                    }
                </AppContainer>
            );
        }
    }
}

SurfacewaterSupplyList = connect(
    state => ({
        wells: allWells(state),
        authState: state.auth,
        error: state.error
    }), {
        ...WaterQualitySite.actions,
        ...authActions,
        ...navActions
    }
)(SurfacewaterSupplyList);

export default compose(
    withStyles(styles)
)(SurfacewaterSupplyList)