import React from "react";
import { Agency } from "./models";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import BreadcrumbNav from "../common/BreadCrumb";
import TextField from "../common/TextField";
import Icon from "@mdi/react";
import { mdiUndo } from "@mdi/js";

const getAgency = Agency.selectByUrlId();

const styles = theme => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee"
  }
});

class EditAgency extends React.Component {
  constructor(props) {
    super(props);

    this.is_new = false;
    if (props.match.params["id"] === "new") this.is_new = true;
  }

  componentDidMount() {
    const { authState, history } = this.props;
    if (
      authState &&
      authState.user &&
      authState.user.role !== "Administrator"
    ) {
      history.push("/dashboard");
    }
  }

  errorValidator = values => {
    const validateName = name => {
      return !name ? "Field is required" : null;
    };
    var valObj = {};
    valObj["name"] = validateName(values.name);
    return valObj;
  };

  submitForm = values => {
    const {
      agency,
      ormAgencyCreate,
      ormAgencyUpdate,
      match,
      history
    } = this.props;
    if (match.params["id"] === "new") {
      ormAgencyCreate(values);
    } else {
      ormAgencyUpdate({
        id: agency.id,
        ...values
      });
    }
    history.push("/adminagencies");
  };

  render() {
    const { agency, classes, history, match } = this.props;
    if (agency.name === "Not Found")
      // name = not found is from rest framework
      agency.name = "";
    return (
      <AppContainer authenticated>
        <Grid container spacing={24}>
          <Grid item xs={12} className={classes.breadCrumb}>
            <BreadcrumbNav
              level1="Administration"
              level1link="/administration"
              level2="Manage Agencies"
              level2link="/adminagencies"
              level3={(this.is_new ? "Add" : "Edit") + " Agency"}
              level3link={"/adminagencies/" + match.params["id"]}
            />
          </Grid>
          <Grid item xs={12} mg={9} lg={6} xl={3}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => history.push("/adminagencies")}
            >
              <Icon path={mdiUndo} size={1} color="white"></Icon>
              &nbsp;&nbsp;&nbsp; Return to Manage Agencies
            </Button>
          </Grid>
          <Form
            dontValidateOnMount="true"
            validateOnSubmit="true"
            defaultValues={agency}
            validateError={this.errorValidator}
            onSubmit={this.submitForm}
          >
            {formApi => (
              <Grid item xs={12}>
                <form onSubmit={formApi.submitForm}>
                  <Grid container spacing={40}>
                    <Grid item xs={10} sm={6} md={4} lg={3}>
                      <TextField field="name" label="Name*" fullWidth />
                      <TextField
                        field="abbreviation"
                        label="Abbreviation"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={40}>
                    <Grid item xs={12} md={8} lg={6}>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        color="primary"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            )}
          </Form>
        </Grid>
      </AppContainer>
    );
  }
}

EditAgency = connect(
  (state, ownProps) => ({
    agency: getAgency(state, ownProps),
    authState: state.auth
  }),
  {
    ...Agency.actions
  }
)(EditAgency);

export default withStyles(styles)(withRouter(EditAgency));
