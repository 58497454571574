import React from "react";
import { GSA, GSP } from "./models";
import { SWGWSite } from "../wells/models";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import BreadcrumbNav from "../common/BreadCrumb";
import Select from "../common/Select";
import TextField from "../common/TextField";
import Icon from "@mdi/react";
import { mdiUndo, mdiAlphaP } from "@mdi/js";
import { createSelector } from "../common/orm";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContactMail from "@material-ui/icons/ContactMail";
import Typography from "@material-ui/core/Typography";
import RoomIcon from "@material-ui/icons/Room";
import EditMap from "../common/map/EditMap";

const getOptions = SWGWSite.getOptions();
const getSite = SWGWSite.selectByUrlId();
const getGSAs = GSA.selectAll("name");
const getGSPs = GSP.selectAll("name");

const getUserData = createSelector(
  (state, ownProps) =>
    state.auth && state.auth.user ? state.auth.user.id : {},
  (session, id) => {
    let user = session.User.filter(u => u.id === id).toRefArray()[0];

    let association = session.GSPAssociation.filter(
      u => u.user === id
    ).toRefArray()[0];

    let gsp = association
      ? session.GSP.filter(u => u.id === association.gsp).toRefArray()[0]
      : {};

    return user && association && gsp
      ? {
          ...user,
          association: association ? association : {},
          gsp: gsp ? gsp : {}
        }
      : {};
  }
);

const styles = theme => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee"
  }
});

class EditSurfaceWaterSite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      quarter_section_choices: null,
      callOptions: false,
      lat: null,
      lng: null,
      zoom: null
    };

    this.is_new = false;
    if (props.match.params["id"] === "new") this.is_new = true;

    this.map = React.createRef();
  }

  componentDidMount() {
    const { authState, history } = this.props;
    if (
      authState &&
      authState.user &&
      authState.user.role !== "Administrator"
    ) {
      history.push("/dashboard");
    }
  }

  MAP_OPTIONS = table => {
    return table
      ? table.map(row => ({
          label: row.display_name,
          value: row.value
        }))
      : [];
  };

  MAKE_OPTIONS = table => {
    return table
      ? table.map(row => ({
          label: row.name,
          value: row.id
        }))
      : [];
  };

  setLatLngState(geometry) {
    if (geometry) {
      this.setState({
        lat: geometry.coordinates[1],
        lng: geometry.coordinates[0],
        zoom: 12
      });
    }
  }

  updateLatLng(e) {
    const { site } = this.props;

    var marker = e.target;
    var loc = marker.getLatLng();
    var map = marker._map;

    var zoom = map.getZoom();
    site.geometry = {
      type: "Point",
      coordinates: [loc.lng, loc.lat]
    };

    this.setState({
      lat: loc.lat,
      lng: loc.lng,
      zoom: zoom
    });
  }

  errorValidator = values => {
    const validateName = name => {
      return !name ? "Field is required" : null;
    };

    var valObj = {};
    valObj["dms_site_id"] = validateName(values.dms_site_id);
    valObj["local_site_id"] = validateName(values.local_site_id);
    valObj["origin_site_id"] = validateName(values.origin_site_id);
    valObj["stream_site_name"] = validateName(values.stream_site_name);
    valObj["dms_sa_site_id"] = validateName(values.dms_sa_site_id);
    valObj["dms_ua_site_id"] = validateName(values.dms_ua_site_id);
    valObj["dms_la_site_id"] = validateName(values.dms_la_site_id);
    valObj["monitor_by"] = validateName(values.monitor_by);
    valObj["owner"] = validateName(values.owner);
    valObj["source_org_id"] = validateName(values.source_org_id);
    valObj["meas_type_stream"] = validateName(values.meas_type_stream);
    valObj["meas_type_sa"] = validateName(values.meas_type_sa);
    valObj["meas_type_ua"] = validateName(values.meas_type_ua);
    valObj["meas_type_la"] = validateName(values.meas_type_la);

    return valObj;
  };

  handleChange = evt => {
    this.setState({
      [evt.target.name]: evt.target.value
    });
  };

  handleChangeNumeric = evt => {
    const { site } = this.props;

    const re = /^-?\d*\.?\d*$/;
    if (evt.target.value === "" || re.test(evt.target.value)) {
      this.setState({
        [evt.target.name]: evt.target.value
      });

      //state doent pick up changes fast enough
      let dlat = parseFloat(this.state.lat);
      let dlng = parseFloat(this.state.lng);

      if (evt.target.name === "lat") {
        dlat = parseFloat(evt.target.value);
      } else if (evt.target.name === "lng") {
        dlng = parseFloat(evt.target.value);
      }

      if (dlat && dlng) {
        site.geometry = {
          type: "Point",
          coordinates: [dlng, dlat]
        };
        this.map.current.geometry = site.geometry;
      }
    }
  };

  submitForm = values => {
    const {
      site,
      ormSWGWSiteCreate,
      ormSWGWSiteUpdate,
      match,
      history,
      user
    } = this.props;

    values.updateby_id = user ? user.id : null;

    if (match.params["id"] === "new") {
      values.geometry = site.geometry;
      ormSWGWSiteCreate(values);
    } else {
      ormSWGWSiteUpdate({
        id: site.id,
        ...values
      });
    }
    history.push("/adminsurfacewatersites");
  };

  render() {
    const { site, classes, history, match, gsas, gsp, optionsReq } = this.props;

    const { siteChoices, callOptions, lat, lng } = this.state;

    //OPTIONS comes back as a promise
    if (optionsReq && !callOptions) {
      optionsReq.then(data => {
        this.setState({
          siteChoices: data,
          callOptions: true
        });
      });
    }

    if (site && site.geometry && !lat && !lng) {
      this.setLatLngState(site.geometry);
    } else if (site && site.name !== "Not Found" && !site.geometry) {
      this.setLatLngState(site.geometry);
    }

    return (
      <AppContainer authenticated>
        <Grid container spacing={24}>
          <Grid item xs={12} className={classes.breadCrumb}>
            <BreadcrumbNav
              level1="Administration"
              level1link="/administration"
              level2="Manage Surface Water Monitoring Sites"
              level2link="/adminsurfacewatersites"
              level3={(this.is_new ? "Add" : "Edit") + " Surface Water Site"}
              level3link={"/adminsurfacewatersites/" + match.params["id"]}
            />
          </Grid>
          <Grid item xs={12} mg={9} lg={6} xl={3}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => history.push("/adminsurfacewatersites")}
            >
              <Icon path={mdiUndo} size={1} color="white"></Icon>
              &nbsp;&nbsp;&nbsp; Return to Monitoring Sites
            </Button>
          </Grid>

          <Form
            dontValidateOnMount="true"
            validateOnSubmit="true"
            defaultValues={site}
            validateError={this.errorValidator}
            onChange={this.onChange}
            onSubmit={this.submitForm}
          >
            {formApi => (
              <Grid item xs={12}>
                <form onSubmit={formApi.submitForm}>
                  <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                      classes={{ root: classes.expandedMargin }}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <ContactMail style={{ marginTop: 5 }} />
                      <Typography style={{ paddingLeft: 32 }} variant="h6">
                        Identification
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid
                        container
                        spacing={16}
                        className={classes.borderContainer}
                      >
                        <Grid
                          className={classes.expansionBorder}
                          item
                          xs={12}
                          md={6}
                          xl={4}
                        >
                          <TextField
                            field="stream_site_name"
                            label="Stream Site Name"
                            fullWidth
                          />
                          <TextField
                            field="dms_site_id"
                            label="DMS ID"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          className={classes.expansionBorder}
                          item
                          xs={12}
                          md={6}
                          xl={4}
                        >
                          <TextField
                            field="origin_site_id"
                            label="Origin Site ID"
                            fullWidth
                          />

                          <TextField
                            field="local_site_id"
                            label="Local Site ID"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          className={classes.expansionBorder}
                          item
                          xs={12}
                          md={6}
                          xl={4}
                        >
                          <TextField
                            field="dms_sa_site_id"
                            label="Site ID (SA)"
                            fullWidth
                          />
                          <TextField
                            field="dms_ua_site_id"
                            label="Site ID (UA)"
                            fullWidth
                          />
                          <TextField
                            field="dms_la_site_id"
                            label="Site ID (LA)"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                      classes={{ root: classes.expandedMargin }}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <RoomIcon style={{ marginTop: 5 }} />
                      <Typography style={{ paddingLeft: 32 }} variant="h6">
                        Location
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid
                        container
                        spacing={16}
                        className={classes.borderContainer}
                      >
                        <div style={{ height: "300px", width: "100%" }}>
                          <EditMap
                            ref={this.map}
                            height={"300px"}
                            width={"100%"}
                            data={site}
                            handler={this.updateLatLng.bind(this)}
                            geometry={site.geometry}
                            zoom={this.state.zoom}
                          />
                        </div>
                        <Grid
                          className={classes.expansionBorder}
                          item
                          xs={12}
                          md={6}
                          xl={4}
                        >
                          <TextField
                            value={this.state.lat ? this.state.lat : undefined}
                            name="lat"
                            field="geometry.coordinates[1]"
                            label="Latitude"
                            onChange={this.handleChangeNumeric}
                            placeholder="Enter Latttude or move map marker"
                            fullWidth
                          />

                          <TextField
                            value={this.state.lng ? this.state.lng : undefined}
                            name="lng"
                            field="geometry.coordinates[0]"
                            label="Longitude"
                            onChange={this.handleChangeNumeric}
                            placeholder="Enter Longitude or move map marker"
                            fullWidth
                          />
                          {siteChoices ? (
                            <Select
                              field="horz_datum"
                              label="Horizontal Datum"
                              options={this.MAP_OPTIONS(
                                siteChoices.horz_datum.choices
                              )}
                              fullWidth
                            />
                          ) : (
                            <div />
                          )}

                          {siteChoices ? (
                            <Select
                              field="vert_datum"
                              label="Vertical Datum"
                              options={this.MAP_OPTIONS(
                                siteChoices.vert_datum.choices
                              )}
                              fullWidth
                            />
                          ) : (
                            <div />
                          )}
                        </Grid>
                        <Grid
                          className={classes.expansionBorder}
                          item
                          xs={12}
                          md={6}
                          xl={4}
                        >
                          {siteChoices ? (
                            <Select
                              field="meas_type_stream"
                              label="Measurement Type (Stream)"
                              options={this.MAP_OPTIONS(
                                siteChoices.meas_type_stream.choices
                              )}
                              fullWidth
                            />
                          ) : (
                            <div />
                          )}
                          {siteChoices ? (
                            <Select
                              field="meas_type_sa"
                              label="Measurement Type (SA)"
                              options={this.MAP_OPTIONS(
                                siteChoices.meas_type_sa.choices
                              )}
                              fullWidth
                            />
                          ) : (
                            <div />
                          )}
                          {siteChoices ? (
                            <Select
                              field="meas_type_ua"
                              label="Measurement Type (UA)"
                              options={this.MAP_OPTIONS(
                                siteChoices.meas_type_ua.choices
                              )}
                              fullWidth
                            />
                          ) : (
                            <div />
                          )}
                          {siteChoices ? (
                            <Select
                              field="meas_type_la"
                              label="Measurement Type (LA)"
                              options={this.MAP_OPTIONS(
                                siteChoices.meas_type_la.choices
                              )}
                              fullWidth
                            />
                          ) : (
                            <div />
                          )}
                        </Grid>
                        <Grid
                          className={classes.expansionBorder}
                          item
                          xs={12}
                          md={6}
                          xl={4}
                        >
                          <TextField
                            field="origin_horz_grad_sa"
                            label="Origin Horizontal Grade (SA)"
                            fullWidth
                          />
                          <TextField
                            field="origin_horz_grad_ua"
                            label="Origin Horizontal Grade (UA)"
                            fullWidth
                          />
                          <TextField
                            field="origin_horz_grad_la"
                            label="Origin Horizontal Grade (LA)"
                            fullWidth
                          />
                          <TextField
                            field="origin_vert_grad_sa_ua"
                            label="Origin Vertical Grade (SA/UA)"
                            fullWidth
                          />
                          <TextField
                            field="origin_vert_grad_ua_la"
                            label="Origin Vertical Grade (UA/LA)"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                      classes={{ root: classes.expandedMargin }}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Icon
                        path={mdiAlphaP}
                        className={classes.marginLeft}
                        size={2}
                      ></Icon>
                      <Typography
                        style={{ paddingLeft: 16, marginTop: 8 }}
                        variant="h6"
                      >
                        {" "}
                        Plan Information
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid
                        container
                        spacing={16}
                        className={classes.borderContainer}
                      >
                        <Grid
                          className={classes.expansionBorder}
                          item
                          xs={12}
                          md={6}
                          xl={4}
                        >
                          <Select
                            field="owner"
                            label="Site Owner"
                            options={this.MAKE_OPTIONS(gsas)}
                            fullWidth
                          />
                          {siteChoices ? (
                            <Select
                              field="monitor_by"
                              label="Monitored By"
                              options={this.MAKE_OPTIONS(gsp)}
                              fullWidth
                            />
                          ) : (
                            <div />
                          )}
                          {siteChoices ? (
                            <Select
                              field="source_org_id"
                              label="Source GSP Organization"
                              options={this.MAKE_OPTIONS(gsp)}
                              fullWidth
                            />
                          ) : (
                            <div />
                          )}
                        </Grid>
                        <Grid
                          className={classes.expansionBorder}
                          item
                          xs={12}
                          md={6}
                          xl={4}
                        >
                          <TextField
                            field="interim_goal_2025"
                            label="Interim Goal 2025"
                            type="number"
                            fullWidth
                          />

                          <TextField
                            field="interim_goal_2030"
                            label="Interim Goal 2030"
                            type="number"
                            fullWidth
                          />
                          <TextField
                            field="interim_goal_2035"
                            label="Interim Goal 2035"
                            type="number"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          className={classes.expansionBorder}
                          item
                          xs={12}
                          md={6}
                          xl={4}
                        >
                          <TextField
                            field="min_threshold"
                            label="Minimum Threshold"
                            type="number"
                            fullWidth
                          />
                          <TextField
                            field="meas_objective"
                            label="Measurement Objective"
                            type="number"
                            fullWidth
                          />
                          <TextField
                            multiline={true}
                            rows={3}
                            rowsMax="4"
                            field="site_notes"
                            label="Site Notes"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <Grid
                    container
                    spacing={40}
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item xs={8}>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        color="primary"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            )}
          </Form>
        </Grid>
      </AppContainer>
    );
  }
}

EditSurfaceWaterSite = connect(
  (state, ownProps) => ({
    optionsReq: getOptions(state, ownProps),
    site: getSite(state, ownProps),
    gsas: getGSAs(state, ownProps),
    gsp: getGSPs(state, ownProps),
    user: getUserData(state, ownProps),
    authState: state.auth
  }),
  {
    ...SWGWSite.actions
  }
)(EditSurfaceWaterSite);

export default withStyles(styles)(withRouter(EditSurfaceWaterSite));
