import React, { Component } from "react";
import { Popup, Tooltip } from "react-leaflet";
import CircleMarker from "./CircleMarker";
import L from "leaflet";
import MarkerClusterGroup from "./MarkerCluster";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { Agency } from "../../administration/models";

const allAgencies = Agency.selectAll();

const aliases = [
  {
    code: "aquifer_designation",
    name: "Aquifer Designation or Principal Aquifer",
    type: "str",
  },
  { code: "casgem_well_id", name: "CASGEM Well ID", type: "str" },
  {
    code: "coordinate_accuracy",
    name: "Coordinate Accuracy (ft)",
    type: "float",
  },
  {
    code: "coordinate_method",
    name: "Coordinate Collection Method",
    type: "str",
  },
  { code: "date_drilled", name: "Drill Date", type: "date" },
  { code: "dms_site_id", name: "DMS Site ID", type: "str" },
  { code: "dwr_well_id", name: "State Well Number", type: "str" },
  { code: "elevation_accuracy", name: "Elevation Accuracy", type: "str" },
  {
    code: "elevation_method",
    name: "Elevation Collection Method",
    type: "str",
  },
  {
    code: "ground_surface_elev",
    name: "Ground Surface Elevation (ft)",
    type: "float",
  },
  { code: "ground_surface_source", name: "Ground Surface Source", type: "str" },
  { code: "gsp_mon_network", name: "GSP Monitoring Network", type: "bool" },
  {
    code: "gsp_wl_threshold_elev",
    name: "GSP WL Threshold Elevation",
    type: "float",
  },
  { code: "horz_datum", name: "Horizontal Datum", type: "str" },
  {
    code: "interconnected_monitoring",
    name: "Interconnected Monitoring",
    type: "bool",
  },
  { code: "local_site_id", name: "Local Site ID or Name", type: "str" },
  { code: "meas_elev_date", name: "Elevation Measurement Date", type: "date" },
  {
    code: "measurable_objective",
    name: "Measurable Objective Depth",
    type: "str",
  },
  { code: "monitor_by", name: "Monitored By", type: "int" },
  { code: "monitor_site_type", name: "Monitoring Site Type", type: "str" },
  { code: "origin_site_id", name: "Origin Site ID", type: "str" },
  { code: "owner", name: "Owner or Operator", type: "str" },
  {
    code: "perforated_interval_bottom",
    name: "Perforated Interval Bottom",
    type: "float",
  },
  {
    code: "perforated_intervals_screen",
    name: "Perforated Intervals Screen",
    type: "float",
  },
  {
    code: "perforated_interval_top",
    name: "Perforated Interval Top",
    type: "float",
  },
  { code: "power_type", name: "Power Type", type: "str" },
  { code: "quarter_section", name: "Quarter Section", type: "str" },
  { code: "range", name: "Range", type: "str" },
  { code: "rp_desc", name: "Reference Point Description", type: "str" },
  { code: "rpe", name: "Reference Point Elevation (ft)", type: "float" },
  { code: "section", name: "Section", type: "str" },
  { code: "source_org", name: "Source GSP", type: "str" },
  { code: "total_well_depth", name: "Total Well Depth", type: "float" },
  { code: "township", name: "Township", type: "str" },
  { code: "updatedate", name: "udpatedate", type: "date" },
  { code: "vert_datum", name: "Vertical Datum", type: "str" },
  {
    code: "volumetric_calc_type",
    name: "Volumetric Pumping Calculation Type",
    type: "str",
  },
  { code: "well_completion_type", name: "Well Completion Type", type: "str" },
  { code: "well_notes", name: "Well Notes", type: "str" },
  { code: "well_status", name: "Well Status", type: "str" },
  { code: "well_use", name: "Well Use Type", type: "str" },
  { code: "wl_monitoring", name: "Water Level Monitoring", type: "str" },
  { code: "wq_monitoring", name: "Water Quality Monitoring", type: "str" },
  {
    code: "yr10_milestone",
    name: "Year 10 Interim Milestone (2030)",
    type: "float",
  },
  {
    code: "yr15_milestone",
    name: "Year 15 Interim Milestone (2035)",
    type: "float",
  },
  {
    code: "yr5_milestone",
    name: "Year 5 Interim Milestone (2025)",
    type: "float",
  },
];

class PageLayer extends Component {
  iconCreateFunction = (cluster) => {
    return new L.divIcon({
      html: "<div><span>" + cluster.getChildCount() + "</span></div>",
      className: "marker-cluster marker-cluster-" + this.props.clusterColor,
      iconSize: new L.Point(40, 40),
    });
  };

  mapPosition(item) {
    return [item.geometry.coordinates[1], item.geometry.coordinates[0]];
  }

  getValue = (item, key) => {
    const type = aliases.filter((a) => {
      return a.code === key;
    })[0]["type"];

    if (type === "float") {
      return parseFloat(item[key]).toFixed(1);
    }

    if (type === "date") {
      const d = new Date(item[key] + " 12:00:00");
      return d.toLocaleDateString();
    }
    return item[key];
  };

  render() {
    const {
      legend,
      clusterColor,
      features,
      title,
      link,
      agencies,
      markerSize,
    } = this.props;

    return (
      <MarkerClusterGroup
        legend={legend}
        clusterColor={clusterColor}
        disableClusteringAtZoom={0}
        spiderfyOnMaxZoom={false}
        iconCreateFunction={this.iconCreateFunction}
      >
        {features.map((item) => {
          if (!item.geometry) return null;

          return (
            <CircleMarker
              key={item.id}
              color={clusterColor}
              radius={markerSize}
              position={this.mapPosition(item)}
              center={this.mapPosition(item)}
            >
              {/* <Popup>
                                <Grid container spacing={0}>
                                    <Grid
                                        style={{
                                            backgroundColor: "#eee",
                                            padding: "14px 20px",
                                            margin: "-14px -20px 0 -20px",
                                            whiteSpace: "nowrap",
                                            borderRadius: 12,
                                            paddingRight: "calc(100% + 20px)",
                                        }}
                                        item
                                        xs={12}>
                                        <Typography style={{ margin: 0 }}>{title}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ maxHeight: "250px", overflowY: "auto" }}>
                                            <Grid container spacing={0}>
                                                {aliases
                                                    .filter(a => {
                                                        return item[a.code];
                                                    })
                                                    .map(a => a.code)
                                                    .sort()
                                                    .map((key, index) => {
                                                        if (
                                                            typeof item[key] === "string" ||
                                                            item[key] instanceof String
                                                        ) {
                                                            if (key === "monitor_by" && item[key]) {
                                                                return (
                                                                    <Grid key={index} item xs={12}>
                                                                        <Typography
                                                                            variant="caption"
                                                                            style={{ margin: 0 }}>
                                                                            <b>
                                                                                {
                                                                                    aliases.filter(
                                                                                        a => a.code === key
                                                                                    )[0]["name"]
                                                                                }
                                                                            </b>
                                                                            :{" "}
                                                                            {
                                                                                agencies.filter(
                                                                                    a => a.id.toString() === item[key]
                                                                                )[0]["name"]
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                );
                                                            } else {
                                                                return (
                                                                    <Grid key={index} item xs={12}>
                                                                        <Typography
                                                                            variant="caption"
                                                                            style={{ margin: 0 }}>
                                                                            <b>
                                                                                {
                                                                                    aliases.filter(
                                                                                        a => a.code === key
                                                                                    )[0]["name"]
                                                                                }
                                                                            </b>
                                                                            : {this.getValue(item, key)}
                                                                        </Typography>
                                                                    </Grid>
                                                                );
                                                            }
                                                        }
                                                        return null;
                                                    })}
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Typography variant="caption" style={{ margin: 0, marginTop: 16 }}>
                                        <Link href={"#/" + link + item.id}>Details</Link>
                                    </Typography>
                                </Grid>
                            </Popup> */}
              <Tooltip direction="top" offset={[0, -15]} opacity={1}>
                {"DMS Site Id: " + item.dms_site_id}
              </Tooltip>
            </CircleMarker>
          );
        })}
      </MarkerClusterGroup>
    );
  }
}

PageLayer = connect(
  (state) => ({
    agencies: allAgencies(state),
    authState: state.auth,
  }),
  null
)(PageLayer);

export default withRouter(PageLayer);
