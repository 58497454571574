import React from "react";
import TextField from "./TextField";
import MenuItem from "@material-ui/core/MenuItem";

// FIXME: If a menu item with a value of 0 is selected the item doesn't appear to be selected
// https://github.com/mui-org/material-ui/issues/10531
// https://github.com/mui-org/material-ui/issues/8990

//SelectProps={{displayEmpty:true}}
//SelectProps={{...rest}}
const Select = ({ options, native, ...rest }) => (
  <TextField select {...rest}>
    {native &&
      options.map(({ label, value, optgroup, items }) => {
        if (optgroup) {
          return (
            <optgroup label={label}>
              {items.map(({ iLabel, iValue }) => (
                <option value={iValue}>{iLabel}</option>
              ))}
            </optgroup>
          );
        } else {
          return <option value={value}>{label}</option>;
        }
      })}
    {!native &&
      options.map((o, i) => (
        <MenuItem key={i} value={o.value}>
          {o.label}
        </MenuItem>
      ))}
  </TextField>
);

export default Select;
