import React, { useEffect, useRef } from "react";
import { DomEvent } from "leaflet";
import { useLeaflet } from "react-leaflet";

const PreventLeafletClickControl = (props) => {
  const controlRef = useRef();
  const leaflet = useLeaflet();
  useEffect(() => {
    if (controlRef) {
      const eventsClone = leaflet.map._events;
      const clickEmpty = Object.assign({}, eventsClone);
      clickEmpty["click"] = [];
      clickEmpty["dblClick"] = [];

      DomEvent.addListener(controlRef.current, "mouseover", () => {
        // leaflet.map._events = eventsEmpty;
        leaflet.map._events = clickEmpty;
        leaflet.map._handlers.forEach((handler) => handler.disable());
      });
      DomEvent.addListener(controlRef.current, "mouseout", () => {
        leaflet.map._handlers.forEach((handler) => handler.enable());
        leaflet.map._events = eventsClone;
      });
    }
  });

  return <div ref={controlRef}>{props.children}</div>;
};

export default PreventLeafletClickControl;

// Original code from ononline.

// import React, { useEffect, useRef } from 'react'
// import { DomEvent } from 'leaflet'
// import { useLeaflet } from 'react-leaflet'

// const PreventLeafletControl = (props) => {
//   const controlRef = useRef()
//   const leaflet = useLeaflet()
//   useEffect(() => {
//     if (controlRef) {
//       const eventsClone = leaflet.map._events
//       const eventsEmpty = Object.keys(eventsClone).reduce((accumulator, key) =>
//         accumulator[key] = []
//       , {})
//       DomEvent.addListener(controlRef.current, 'mouseover', () => {
//         leaflet.map._events = eventsEmpty
//         leaflet.map._handlers.forEach(handler => handler.disable())
//       })
//       DomEvent.addListener(controlRef.current, 'mouseout', () => {
//         leaflet.map._handlers.forEach(handler => handler.enable())
//         leaflet.map._events = eventsClone
//       })
//     }
//   })

//   return <div ref={controlRef}>
//     {props.children}
//   </div>
// }

// export default PreventLeafletControl
// Just wrap <PreventLeafletControl> around your custom UI component and you should be good.
