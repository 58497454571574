import React, { createRef } from "react";
import L from "leaflet";
import { Map, TileLayer, WMSTileLayer } from "react-leaflet";
import { connect } from "react-redux";
import * as fullscreen from "leaflet-fullscreen"; // eslint-disable-line
import { withStyles } from "@material-ui/core";
import CollapsibleLayersControl from "./CollapsibleLayersControl";
import MapLayers from "./MapLayers";
import ZoomExtent from "./ZoomExtent";
import { WellSite, SubsidenceSite, SWGWSite } from "../../wells/models";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";

import SectionData from "./measurable_threshold_sections.json";
import SectionSearchBar from "./SectionSearchBar";
import CoordinateSearchBar from "./CoordinateSearchBar";
import PreventLeafletClickControl from "./PreventLeafletClickControl";

import { createSelector } from "../orm";
import * as app_settings from "../../../app_settings";
import geojsonBbox from "./geojson-bbox";
const allWells = WellSite.selectAll();
const allSub = SubsidenceSite.selectAll();
const allSWGW = SWGWSite.selectAll();
const styles = (theme) => ({
  height: {
    height: "100%",
    margin: "0 !important",
    width: "100% !important",
  },
});

// const settings = createSelector(schema => {
//     console.log("blah");
//     return schema.MapSetting.first();
// });

// const layers = createSelector(schema => {
//     return schema.MapLayer.all().orderBy("name").toModelArray();
// });

const colors = [
  "",
  { hex: "#CF382D", color: "red" },
  { hex: "#EF8D2F", color: "orange" },
  { hex: "#6DA42E", color: "green" },
  { hex: "#31A2CC", color: "teal" },
  { hex: "#C24EB1", color: "purple" },
  { hex: "#962E34", color: "darkred" },
  { hex: "#095B97", color: "darkblue" },
  { hex: "#717825", color: "lime" },
  { hex: "#593466", color: "darkpurple" },
  { hex: "#406470", color: "royalblue" },
  { hex: "#FE8878", color: "peach" },
  { hex: "#F8CA85", color: "yellow" },
  { hex: "#B7F272", color: "lightgreen" },
  { hex: "#8CD5FF", color: "skyblue" },
  { hex: "#FC8CE6", color: "pink" },
  { hex: "#E57C86", color: "lightred" },
  { hex: "#A4A2A5", color: "gray" },
  { hex: "#2F2D30", color: "black" },
];

var pointInPolygon = require("point-in-polygon");

class DashboardMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = app_settings.map_initial_state;

    this.state = {
      ...app_settings.map_initial_state,
      coordinateSearchSuccess: false,
      coordinateSearchMarker: {},
    };

    this.zoomToSection = this.zoomToSection.bind(this);
    this.mapRef = createRef();
  }

  // ref = React.createRef();

  wmsIdentify = (e) => {
    //wells are a graphic layer so need to add it as default
    var layer_names = ["wells"];

    //only pull layers where identify = true
    Object.keys(e.target._layers).filter((id) => {
      var p = e.target._layers[id].wmsParams;
      if (p !== undefined && p.info !== undefined && p.info.identify === true) {
        layer_names.push(p.layers);
      }
    });

    var params = {};

    delete params["leaflet"];

    params["layers"] = layer_names;
    params["query_layers"] = layer_names;

    var map = e.target;
    var bounds = map.getBounds();
    var size = map.getSize();
    var crs = map.options.crs;
    var nw = crs.project(bounds.getNorthWest());
    var se = crs.project(bounds.getSouthEast());

    params["request"] = "GetFeatureInfo";
    params["INFO_FORMAT"] = "text/html";
    params["url"] = "https://kingsdms.houstoneng.net/cgi-bin/mapserv";
    params["map"] = "/var/www/kings/maps/map.map";
    params["version"] = "1.1.1";
    params["pathname"] = "/cgi-bin/mapserv";
    params["service"] = ["WMS"];
    params["srs"] = "EPSG:3857";
    params["request"] = "GetFeatureInfo";
    params["X"] = Math.round(e.containerPoint.x);
    params["Y"] = Math.round(e.containerPoint.y);
    params["width"] = size.x;
    params["height"] = size.y;
    params["bbox"] = [nw.x, se.y, se.x, nw.y].join(",");

    var url = new URL(params["url"]);

    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );

    var link = '<a href="/waterleveldetail/">Details</a>';

    fetch(url)
      .then((results) => {
        map.openPopup(
          "<iframe id='wmsiden' src='" +
            url +
            "' style='border:none'></iframe>",
          e.latlng
        );
      })
      .catch((error) => {
        map.openPopup(
          "<iframe id='wmsiden' src='" +
            url +
            "' style='border:none'></iframe>",
          e.latlng
        );
      });

    console.log(layer_names);
  };

  zoomToSection = (d) => {
    console.log(d);
    const bbox = geojsonBbox(d);
    const map = this.mapRef.current.leafletElement;
    var corner_1 = [bbox[1], bbox[0]];
    var corner_2 = [bbox[3], bbox[2]];
    map.fitBounds([corner_1, corner_2]);
    // map.setView();
  };

  coordinateSearchVerifyCoordinates = (coords) => {
    console.log(coords);

    if (parseFloat(coords[0]) && parseFloat(coords[1])) {
      var xcoord = parseFloat(coords[0]);
      var ycoord = parseFloat(coords[1]);

      var polygon = [
        [-114, 39.5],
        [-114, 33.75],
        [-124.2, 33.75],
        [-124.2, 39.5],
      ];

      var success = pointInPolygon([xcoord, ycoord], polygon);
      this.setState({ coordinateSearchSuccess: success });
    } else {
      this.setState({ coordinateSearchSuccess: false });
    }
  };

  coordinateSearchZoomToCoordinates = (coords) => {
    const map = this.mapRef.current.leafletElement;

    if (this.state.coordinateSearchMarker != undefined) {
      map.removeLayer(this.state.coordinateSearchMarker);
    }

    var marker = L.marker([coords[1], coords[0]]).addTo(map);

    this.setState({
      coordinateSearchMarker: marker,
    });

    map.flyTo([coords[1], coords[0]], 16);
  };

  coordinateSearchClearMarker = () => {
    const map = this.mapRef.current.leafletElement;
    map.removeLayer(this.state.coordinateSearchMarker);
    this.setState({
      coordinateSearchMarker: {},
    });
  };

  render() {
    const { zoom, lat, lng, collapsed, coordinateSearchSuccess } = this.state;
    const {
      classes,
      wells,
      subsidences,
      swgws,
      pageType,
      filtered_data,
      // setRef,
    } = this.props;
    var position = [lat, lng];
    var controlPosition = this.props.controlPosition;
    if (!controlPosition) {
      controlPosition = "topleft";
    }
    const features = {
      indicator_well_monitoring_sites: wells.filter(
        (w) => w.gsp_mon_network === "True"
      ),
      well_sites: wells.filter((w) => w.gsp_mon_network === "False"),
      subsidence_monitoring_sites: subsidences,
      surface_water_monitoring_sites: swgws,
    };
    return (
      <Map
        className={classes.height}
        center={position}
        zoom={zoom}
        maxZoom={18}
        onclick={this.wmsIdentify}
        // ref={setRef}
        ref={this.mapRef}
        fullscreenControl={{ position: controlPosition }}
      >
        <PreventLeafletClickControl>
          <SectionSearchBar
            data={SectionData}
            verifyCoordinates={this.verifyCoordinates}
            zoomToSection={this.zoomToSection}
            map={this.mapRef}
          />
          <CoordinateSearchBar
            coordinateSearchSuccess={coordinateSearchSuccess}
            zoomToCoordinates={this.coordinateSearchZoomToCoordinates}
            verifyCoordinates={this.coordinateSearchVerifyCoordinates}
            clearMarker={this.coordinateSearchClearMarker}
          />
        </PreventLeafletClickControl>
        <ZoomExtent position="topleft" zoom={this.state.zoom} />
        <CollapsibleLayersControl
          position="topright"
          controlPosition={controlPosition}
          collapsed={collapsed}
        >
          {app_settings.map_layers
            .filter((l) => l.add === true && l.type === "baselayer")
            .map((l) => (
              <CollapsibleLayersControl.BaseLayer
                key={l.id}
                checked={l.checked}
                name={l.name}
              >
                <TileLayer
                  info={null}
                  attribution={l.attribution}
                  url={l.url}
                />
              </CollapsibleLayersControl.BaseLayer>
            ))}

          {pageType === "Water Level" ? (
            <CollapsibleLayersControl.Overlay
              checked={pageType === "Water Level"}
              name="Water Level Sites"
            >
              <MapLayers
                info={{
                  legend: [
                    { title: "Water Level Sites", color: colors[1].hex },
                  ],
                }}
                features={pageType === "Water Level" ? filtered_data : []}
                title="Water Level Sites"
                link="waterleveldetail/"
                clusterHex={colors[1].hex}
                clusterColor={colors[1].color}
                markerSize={6}
                legend={[{ title: "Water Level Sites", color: colors[1].hex }]}
              />
            </CollapsibleLayersControl.Overlay>
          ) : (
            <></>
          )}
          {pageType !== "Water Level" &&
            app_settings.map_layers
              .filter((l) => l.add === true && l.type === "maplayer")
              .sort((a, b) => a.index - b.index)
              .map((l) => (
                <CollapsibleLayersControl.Overlay
                  key={l.id}
                  checked={
                    pageType ? pageType === l.page_type || !pageType : l.checked
                  }
                  name={l.title}
                >
                  <MapLayers
                    info={l}
                    features={
                      pageType === l.page_type
                        ? filtered_data
                        : features[l.type2]
                    }
                    title={l.title}
                    link={l.link}
                    clusterHex={l.color.hex}
                    clusterColor={l.color.rgb}
                    legend={[{ title: l.title, color: l.color.hex }]}
                    markerSize={l.markerSize}
                  />
                </CollapsibleLayersControl.Overlay>
              ))}
          {pageType !== "Water Level" &&
            app_settings.map_layers
              .filter((l) => l.add === true && l.type === "wmstilelayer")
              .map((l) => (
                <CollapsibleLayersControl.Overlay2
                  key={l.id}
                  checked={l.checked}
                  name={l.title}
                >
                  <WMSTileLayer
                    info={l}
                    layers={l.layers}
                    transparent={l.transparent}
                    opacity={l.opacity}
                    format={l.format}
                    map={l.map}
                    legend={l.legend}
                    url={l.url}
                  />
                </CollapsibleLayersControl.Overlay2>
              ))}
        </CollapsibleLayersControl>
      </Map>
    );
  }
}
DashboardMap = connect(
  (state, ownProps) => ({
    // settings: settings(state),
    // layers: layers(state),
    wells: allWells(state),
    subsidences: allSub(state),
    swgws: allSWGW(state),
  }),
  {}
)(DashboardMap);
export default withStyles(styles)(DashboardMap);
