import React from "react";
import { GSA, GSP } from "./models";
import { WellSite } from "../wells/models";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import BreadcrumbNav from "../common/BreadCrumb";
import Select from "../common/Select";
import TextField from "../common/TextField";
import Icon from "@mdi/react";
import { mdiUndo, mdiAndroidStudio, mdiAlphaP } from "@mdi/js";
import { createSelector } from "../common/orm";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContactMail from "@material-ui/icons/ContactMail";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import PowerIcon from "@material-ui/icons/Power";
import RoomIcon from "@material-ui/icons/Room";
import Typography from "@material-ui/core/Typography";
import EditMap from "../common/map/EditMap";
const getOptions = WellSite.getOptions();
const getWellSite = WellSite.selectByUrlId();
const getGSAs = GSA.selectAll("name");
const getGSPs = GSP.selectAll("name");
const getUserData = createSelector(
    (state, ownProps) => (state.auth && state.auth.user ? state.auth.user.id : {}),
    (session, id) => {
        let user = session.User.filter(u => u.id === id).toRefArray()[0];
        let association = session.GSPAssociation.filter(u => u.user === id).toRefArray()[0];
        let gsp = association ? session.GSP.filter(u => u.id === association.gsp).toRefArray()[0] : {};
        return user && association && gsp
            ? {
                  ...user,
                  association: association ? association : {},
                  gsp: gsp ? gsp : {},
              }
            : {};
    }
);
const styles = theme => ({
    breadCrumb: {
        width: "100vw",
        margin: "0 -20px",
        padding: "0px 35px !important",
        maxWidth: "105%",
        flexBasis: "auto",
        backgroundColor: "#eeeeee",
    },
});
class EditWellSite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quarter_section_choices: null,
            callOptions: false,
            lat: null,
            lng: null,
            zoom: null,
        };
        this.is_new = false;
        if (props.match.params["id"] === "new") this.is_new = true;
        this.map = React.createRef();
    }
    componentDidMount() {
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Administrator") {
            history.push("/dashboard");
        }
    }
    MAP_OPTIONS = table => {
        return table.map(row => ({
            label: row.display_name,
            value: row.value,
        }));
    };
    MAKE_OPTIONS = table => {
        return table.map(row => ({
            label: row.name,
            value: row.id,
        }));
    };
    setLatLngState(geometry) {
        if (geometry) {
            this.setState({
                lat: geometry.coordinates[1],
                lng: geometry.coordinates[0],
                zoom: 12,
            });
        }
    }
    updateLatLng(e) {
        const { ws } = this.props;
        var marker = e.target;
        var loc = marker.getLatLng();
        var map = marker._map;
        var zoom = map.getZoom();
        ws.geometry = {
            type: "Point",
            coordinates: [loc.lng, loc.lat],
        };
        this.setState({
            lat: loc.lat,
            lng: loc.lng,
            zoom: zoom,
        });
    }
    errorValidator = values => {
        const validateName = name => {
            return !name ? "Field is required" : null;
        };
        var valObj = {};
        valObj["dms_site_id"] = validateName(values.dms_site_id);
        valObj["dwr_well_id"] = validateName(values.dwr_well_id);
        valObj["owner"] = validateName(values.owner);
        valObj["source_org_id"] = validateName(values.source_org_id);
        return valObj;
    };
    handleChange = evt => {
        this.setState({
            [evt.target.name]: evt.target.value,
        });
    };
    handleChangeNumeric = evt => {
        const { ws } = this.props;
        const re = /^-?\d*\.?\d*$/;
        if (evt.target.value === "" || re.test(evt.target.value)) {
            this.setState({
                [evt.target.name]: evt.target.value,
            });
            //state doent pick up changes fast enough
            let dlat = parseFloat(this.state.lat);
            let dlng = parseFloat(this.state.lng);
            if (evt.target.name === "lat") {
                dlat = parseFloat(evt.target.value);
            } else if (evt.target.name === "lng") {
                dlng = parseFloat(evt.target.value);
            }
            if (dlat && dlng) {
                ws.geometry = {
                    type: "Point",
                    coordinates: [dlng, dlat],
                };
                this.map.current.geometry = ws.geometry;
            }
        }
    };
    submitForm(values) {
        const { ws, ormWellSiteCreate, ormWellSiteUpdate, match, history, user } = this.props;
        values.updateby_id = user ? user.id : null;
        values.measurements = [];

        if (match.params["id"] === "new") {
            values.geometry = ws.geometry;
            ormWellSiteCreate(values);
        } else {
            ormWellSiteUpdate({
                id: ws.id,
                ...values,
            });
        }
        history.push("/adminwellsites");
    }
    render() {
        const { ws, classes, history, match, gsas, gsp, optionsReq } = this.props;
        const { wsChoices, callOptions, lat, lng } = this.state;
        //OPTIONS comes back as a promise
        if (optionsReq && !callOptions) {
            optionsReq.then(data => {
                this.setState({
                    wsChoices: data,
                    callOptions: true,
                });
            });
        }
        if (ws && ws.geometry && !lat && !lng) {
            this.setLatLngState(ws.geometry);
        } else if (ws && ws.name !== "Not Found" && !ws.geometry) {
            this.setLatLngState(ws.geometry);
        }
        return (
            <AppContainer authenticated>
                <Grid container spacing={24}>
                    <Grid item xs={12} className={classes.breadCrumb}>
                        <BreadcrumbNav
                            level1="Administration"
                            level1link="/administration"
                            level2="Manage Well Monitoring Sites"
                            level2link="/adminwellsites"
                            level3={(this.is_new ? "Add" : "Edit") + " Well Site"}
                            level3link={"/adminwellsites/" + match.params["id"]}
                        />
                    </Grid>
                    <Grid item xs={12} mg={9} lg={6} xl={3}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            onClick={() => history.push("/adminwellsites")}>
                            <Icon path={mdiUndo} size={1} color="white"></Icon>
                            &nbsp;&nbsp;&nbsp; Return to Well Monitoring Sites
                        </Button>
                    </Grid>
                    <Form
                        dontValidateOnMount="true"
                        validateOnSubmit="true"
                        defaultValues={ws}
                        validateError={this.errorValidator}
                        onChange={this.onChange}
                        onSubmit={values => this.submitForm(values)}>
                        {formApi => (
                            <Grid item xs={12}>
                                <form onSubmit={formApi.submitForm}>
                                    <ExpansionPanel defaultExpanded>
                                        <ExpansionPanelSummary
                                            classes={{ root: classes.expandedMargin }}
                                            expandIcon={<ExpandMoreIcon />}>
                                            <ContactMail style={{ marginTop: 5 }} />
                                            <Typography style={{ paddingLeft: 32 }} variant="h6">
                                                Identification
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container spacing={16} className={classes.borderContainer}>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    <TextField field="dms_site_id" label="DMS ID" fullWidth />
                                                    <TextField field="dwr_well_id" label="DWR Well ID" fullWidth />
                                                    <TextField
                                                        field="origin_site_id"
                                                        label="Origin Site ID"
                                                        fullWidth
                                                    />
                                                    {wsChoices ? (
                                                        <Select
                                                            field="well_status"
                                                            label="Well Status"
                                                            options={this.MAP_OPTIONS(wsChoices.well_status.choices)}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <div />
                                                    )}
                                                </Grid>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    <TextField field="local_site_id" label="Local Site ID" fullWidth />
                                                    <TextField
                                                        field="casgem_well_id"
                                                        label="CASGEM Well ID"
                                                        fullWidth
                                                    />
                                                    <Select
                                                        field="monitor_by"
                                                        label="Monitor By"
                                                        options={this.MAKE_OPTIONS(gsas)}
                                                        fullWidth
                                                    />
                                                    {wsChoices ? (
                                                        <Select
                                                            field="well_completion_type"
                                                            label="Well Completion Type"
                                                            options={this.MAP_OPTIONS(
                                                                wsChoices.well_completion_type.choices
                                                            )}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <div />
                                                    )}
                                                </Grid>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    <Select
                                                        field="owner"
                                                        label="Owner"
                                                        options={this.MAKE_OPTIONS(gsas)}
                                                        fullWidth
                                                    />
                                                    <Select
                                                        field="source_org_id"
                                                        label="Source Org."
                                                        options={this.MAKE_OPTIONS(gsp)}
                                                        fullWidth
                                                    />
                                                    {wsChoices ? (
                                                        <Select
                                                            field="well_use"
                                                            label="Well Use"
                                                            options={this.MAP_OPTIONS(wsChoices.well_use.choices)}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <div />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel defaultExpanded>
                                        <ExpansionPanelSummary
                                            classes={{ root: classes.expandedMargin }}
                                            expandIcon={<ExpandMoreIcon />}>
                                            <RoomIcon style={{ marginTop: 5 }} />
                                            <Typography style={{ paddingLeft: 32 }} variant="h6">
                                                Location
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container spacing={16} className={classes.borderContainer}>
                                                <div style={{ height: "300px", width: "100%" }}>
                                                    <EditMap
                                                        ref={this.map}
                                                        height={"300px"}
                                                        width={"100%"}
                                                        data={ws}
                                                        handler={this.updateLatLng.bind(this)}
                                                        geometry={ws.geometry}
                                                        zoom={this.state.zoom}
                                                    />
                                                </div>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    <TextField field="township" label="Township" fullWidth />
                                                    {wsChoices ? (
                                                        <Select
                                                            field="section"
                                                            label="Section"
                                                            options={this.MAP_OPTIONS(wsChoices.section.choices)}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <div />
                                                    )}
                                                    <TextField field="range" label="Range" fullWidth />
                                                </Grid>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    {wsChoices ? (
                                                        <Select
                                                            field="quarter_section"
                                                            label="Quarter Section"
                                                            options={this.MAP_OPTIONS(
                                                                wsChoices.quarter_section.choices
                                                            )}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <div />
                                                    )}
                                                    <TextField field="horz_datum" label="Horizontal Datum" fullWidth />
                                                    {wsChoices ? (
                                                        <Select
                                                            field="coordinate_method"
                                                            label="Coordinate Method"
                                                            options={this.MAP_OPTIONS(
                                                                wsChoices.coordinate_method.choices
                                                            )}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <div />
                                                    )}
                                                </Grid>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    <TextField
                                                        value={this.state.lat ? this.state.lat : undefined}
                                                        name="lat"
                                                        field="geometry.coordinates[1]"
                                                        label="Latitude"
                                                        onChange={this.handleChangeNumeric}
                                                        placeholder="Enter Latttude or move map marker"
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        value={this.state.lng ? this.state.lng : undefined}
                                                        name="lng"
                                                        field="geometry.coordinates[0]"
                                                        label="Longitude"
                                                        onChange={this.handleChangeNumeric}
                                                        placeholder="Enter Longitude or move map marker"
                                                        fullWidth
                                                    />
                                                    {wsChoices ? (
                                                        <Select
                                                            field="coordinate_accuracy"
                                                            label="Coordinate Accuracy"
                                                            options={this.MAP_OPTIONS(
                                                                wsChoices.coordinate_accuracy.choices
                                                            )}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <div />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel defaultExpanded>
                                        <ExpansionPanelSummary
                                            classes={{ root: classes.expandedMargin }}
                                            expandIcon={<ExpandMoreIcon />}>
                                            <Icon path={mdiAndroidStudio} className={classes.marginTop} size={1}></Icon>
                                            <Typography style={{ paddingLeft: 32 }} variant="h6">
                                                Ground Surface and Reference Point Information
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container spacing={16} className={classes.borderContainer}>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    <TextField
                                                        field="meas_elev_date"
                                                        label="RP and GS Elevation Measurement Date"
                                                        type="date"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                    {wsChoices ? (
                                                        <Select
                                                            field="elevation_accuracy"
                                                            label="Ground Surface Elevation Accuracy"
                                                            options={this.MAP_OPTIONS(
                                                                wsChoices.elevation_accuracy.choices
                                                            )}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <div />
                                                    )}
                                                    <TextField field="vert_datum" label="Vertical Datum" fullWidth />
                                                </Grid>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    <TextField
                                                        field="ground_surface_elev"
                                                        type="number"
                                                        label="Ground Surface Elevation"
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        field="rpe"
                                                        type="text"
                                                        label="Reference Point Elevation"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    {wsChoices ? (
                                                        <Select
                                                            field="elevation_method"
                                                            label="Ground Surface Elevation Method"
                                                            options={this.MAP_OPTIONS(
                                                                wsChoices.elevation_method.choices
                                                            )}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <div />
                                                    )}
                                                    <TextField
                                                        field="rp_desc"
                                                        label="Reference Point Description"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel defaultExpanded>
                                        <ExpansionPanelSummary
                                            classes={{ root: classes.expandedMargin }}
                                            expandIcon={<ExpandMoreIcon />}>
                                            <Icon path={mdiAlphaP} className={classes.marginLeft} size={2}></Icon>
                                            <Typography style={{ paddingLeft: 32 }} variant="h6">
                                                Plan Information
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container spacing={16} className={classes.borderContainer}>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    <TextField
                                                        field="gsp_wl_threshold_elev"
                                                        type="number"
                                                        label="GSP WL Threshold Elevation"
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        field="yr5_milestone"
                                                        type="text"
                                                        label="Year 5 Interim Milestone (2025)"
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        field="yr10_milestone"
                                                        type="text"
                                                        label="Year 10 Interim Milestone (2030)"
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        field="yr15_milestone"
                                                        type="text"
                                                        label="Year 15 Interim Milestone (2035)"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    <TextField
                                                        field="measurable_objective"
                                                        type="text"
                                                        label="GSP WL Measurable Objective"
                                                        fullWidth
                                                    />
                                                    {wsChoices ? (
                                                        <Select
                                                            field="aquifer_designation"
                                                            label="Aquifer Designation"
                                                            options={this.MAP_OPTIONS(
                                                                wsChoices.aquifer_designation.choices
                                                            )}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <div />
                                                    )}
                                                    {wsChoices ? (
                                                        <Select
                                                            field="monitor_site_type"
                                                            label="SGMA Site Monitoring Type"
                                                            options={this.MAP_OPTIONS(
                                                                wsChoices.monitor_site_type.choices
                                                            )}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <div />
                                                    )}
                                                    {wsChoices ? (
                                                        <Select
                                                            field="gsp_mon_network"
                                                            label="GSP Monitoring Network"
                                                            options={this.MAP_OPTIONS(
                                                                wsChoices.gsp_mon_network.choices
                                                            )}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <div />
                                                    )}
                                                </Grid>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    {wsChoices ? (
                                                        <Select
                                                            field="wq_monitoring"
                                                            label="Water Quality Monitoring"
                                                            options={this.MAP_OPTIONS(wsChoices.wq_monitoring.choices)}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <div />
                                                    )}
                                                    {wsChoices ? (
                                                        <Select
                                                            field="interconnected_monitoring"
                                                            label="Interconnected Monitoring"
                                                            options={this.MAP_OPTIONS(
                                                                wsChoices.interconnected_monitoring.choices
                                                            )}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <div />
                                                    )}
                                                    {wsChoices ? (
                                                        <Select
                                                            field="wl_monitoring"
                                                            label="Water Level Monitoring"
                                                            options={this.MAP_OPTIONS(wsChoices.wl_monitoring.choices)}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <div />
                                                    )}
                                                    <TextField
                                                        multiline={true}
                                                        rows={3}
                                                        rowsMax="4"
                                                        field="well_notes"
                                                        label="Well Notes"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel defaultExpanded>
                                        <ExpansionPanelSummary
                                            classes={{ root: classes.expandedMargin }}
                                            expandIcon={<ExpandMoreIcon />}>
                                            <LocalDrinkIcon style={{ marginTop: 10 }} />
                                            <Typography style={{ paddingLeft: 32 }} variant="h6">
                                                Drilling Information
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container spacing={16} className={classes.borderContainer}>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    <TextField
                                                        field="total_well_depth"
                                                        type="number"
                                                        label="Total Well Depth (ft)"
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        field="perforated_intervals_screen"
                                                        type="text"
                                                        label="Perforated Intervals (Screen)"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    <TextField
                                                        field="perforated_interval_top"
                                                        type="text"
                                                        label="Perforated Intervals Top (ft)"
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        field="perforated_interval_bottom"
                                                        type="text"
                                                        label="Perforated Intervals Bottom (ft)"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    <TextField
                                                        field="date_drilled"
                                                        type="date"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        label="Date Drilled"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel defaultExpanded>
                                        <ExpansionPanelSummary
                                            classes={{ root: classes.expandedMargin }}
                                            expandIcon={<ExpandMoreIcon />}>
                                            <PowerIcon style={{ marginTop: 10 }} />
                                            <Typography style={{ paddingLeft: 32 }} variant="h6">
                                                Motor/Pump Information
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container spacing={16} className={classes.borderContainer}>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    {wsChoices ? (
                                                        <Select
                                                            field="power_type"
                                                            label="Power Type"
                                                            options={this.MAP_OPTIONS(wsChoices.power_type.choices)}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <div />
                                                    )}
                                                </Grid>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    {wsChoices ? (
                                                        <Select
                                                            field="volumetric_calc_type"
                                                            label="Volumetric Pumping Calculation Type"
                                                            options={this.MAP_OPTIONS(
                                                                wsChoices.volumetric_calc_type.choices
                                                            )}
                                                            fullWidth
                                                        />
                                                    ) : (
                                                        <div />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <Grid container spacing={40} alignItems="center" justify="center">
                                        <Grid item xs={8}>
                                            <Button variant="contained" type="submit" fullWidth color="primary">
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        )}
                    </Form>
                </Grid>
            </AppContainer>
        );
    }
}
EditWellSite = connect(
    (state, ownProps) => ({
        optionsReq: getOptions(state, ownProps),
        ws: getWellSite(state, ownProps),
        gsas: getGSAs(state, ownProps),
        gsp: getGSPs(state, ownProps),
        user: getUserData(state, ownProps),
        authState: state.auth,
    }),
    {
        ...WellSite.actions,
    }
)(EditWellSite);
export default withStyles(styles)(withRouter(EditWellSite));
