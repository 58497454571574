import React from "react";

class SectionSearchBar extends React.Component {
  constructor() {
    super();
    this.state = {
      filteredData: [],
      searchWord: "",
    };
  }

  handleFilter = (searchWord) => {
    const filteredData =
      searchWord == ""
        ? []
        : this.props.data["features"].filter((d) => {
            return d["properties"]["TRS"]
              .toUpperCase()
              .includes(searchWord.toUpperCase());
          });

    return filteredData;
  };

  handleChange = (event) => {
    const newSearchWord = event.target.value.trim().toUpperCase();

    const filteredData = this.handleFilter(newSearchWord);

    this.setState({
      searchWord: newSearchWord,
      filteredData: filteredData,
    });
  };

  handleReturn = (event) => {
    if (event.keyCode == 13) {
      const filteredData = this.handleFilter(this.state.searchWord);
      const section = filteredData.length == 0 ? null : filteredData[0];
      if (section) {
        this.setState({
          searchWord: section["properties"]["TRS"],
          filteredData: this.handleFilter(section["properties"]["TRS"]),
        });
        this.handleClick(section);
      }
    }
  };

  handleClick = (d) => {
    this.setState({
      searchWord: d["properties"]["TRS"],
    });
    this.props.zoomToSection(d);
  };

  handleClear = () => {
    this.setState({
      searchWord: "",
      filteredData: [],
    });
  };

  render() {
    const { filteredData, searchWord } = this.state;

    return (
      <div
        id="SectionSearchBar"
        className="leaflet-control-geosearch bar section-search-bar"
      >
        <form>
          <input
            className="glass"
            type="text"
            placeholder="Enter Township/Range/Section..."
            onChange={(event) => this.handleChange(event)}
            onKeyUp={(event) => this.handleReturn(event)}
            value={searchWord}
          />
          <a className="reset" onClick={() => this.handleClear()}>
            X
          </a>
          {filteredData.length != 0 && (
            <div
              className="results active"
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              {filteredData.map((d) => {
                return (
                  <div
                    key={d["properties"]["TRS"]}
                    onClick={() => {
                      this.handleClick(d);
                    }}
                  >
                    {d["properties"]["TRS"]}
                  </div>
                );
              })}
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default SectionSearchBar;
