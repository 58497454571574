import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Form } from "react-form";
import { withStyles } from "@material-ui/core/styles";
import DashboardMap from "./map/Map";
import * as navActions from "../common/actions";
import Submit from "../common/Submit";
import TextField from "../common/TextField";
import CustomRadioGroup from "../common/RadioGroup";
import Select from "../common/Select";
//https://goshakkk.name/array-form-inputs/
import { YES_NO_OPTIONS } from "../../api/constants";

const styles = theme => ({
  svgStyle: {
    width: "24px",
    height: "24px",
    paddingRight: "24px"
  },
  MuiFormControlLabel: {
    alignItems: "left"
  }
});

function makeOptions(table) {
  if (typeof table !== "object" || table === undefined) {
    return { label: "", value: "" };
  } else {
    return table.map(row => ({
      label: row,
      value: row.toLowerCase()
    }));
  }
}

class FlexibleField extends Component {
  constructor(props) {
    super(props);
    let filterarray = Object.values(props.fieldconf).filter(
      rowdata => rowdata.id === props.field
    );
    if (filterarray.length !== 0) {
      filterarray = filterarray[0];
    }
    this.state = { filterarray: filterarray };
  }
  render() {
    const { field, value } = this.props;
    const { filterarray } = this.state;
    return (
      <div
        style={{
          display:
            filterarray.hidden && filterarray.dataType !== "locationFlex"
              ? "none"
              : "block"
        }}
      >
        {
          {
            text: (
              <TextField
                field={field}
                label={filterarray.label}
                disabled={filterarray.disabled}
                readOnly={filterarray.readonly}
                style={{ display: filterarray.hidden ? "none" : "block" }}
                fullWidth
                defaultValue={
                  typeof value !== "object" && value !== null
                    ? value.toString()
                    : "None"
                }
              />
            ),
            readonly: (
              <TextField
                field={field}
                label={filterarray.label}
                readOnly={true}
                style={{ display: filterarray.hidden ? "none" : "block" }}
                fullWidth
                defaultValue={
                  typeof value !== "object" && value !== null
                    ? value.toString()
                    : "None"
                }
              />
            ),
            hidden: (
              <TextField
                field={field}
                label={filterarray.label}
                readOnly={true}
                style={{ display: "none" }}
                defaultValue={
                  typeof value !== "object" && value !== null
                    ? value.toString()
                    : "None"
                }
              />
            ),

            date: (
              <TextField
                field={field}
                label={filterarray.label}
                type="date"
                readOnly={filterarray.readonly}
                style={{ display: filterarray.hidden ? "none" : "block" }}
                fullWidth
                defaultValue={
                  typeof value !== "object" && value !== null
                    ? value.toString()
                    : "None"
                }
              />
            ),
            numeric: (
              <TextField
                field={field}
                label={filterarray.label}
                type="number"
                readOnly={filterarray.readonly}
                style={{ display: filterarray.hidden ? "none" : "block" }}
                fullWidth
                defaultValue={
                  typeof value !== "object" && value !== null
                    ? value.toString()
                    : "None"
                }
              />
            ),
            longText: (
              <TextField
                field={field}
                label={filterarray.label}
                disabled={filterarray.disabled}
                readOnly={filterarray.readonly}
                style={{ display: filterarray.hidden ? "none" : "block" }}
                fullWidth
                multiline
                defaultValue={
                  typeof value !== "object" && value !== null
                    ? value.toString()
                    : "None"
                }
              />
            ),
            radio: (
              <CustomRadioGroup
                fullWidth
                field={field}
                name={field}
                options={makeOptions(filterarray.options)}
                alignment={true}
              />
            ),
            ignore: "",
            truefalse: (
              <CustomRadioGroup
                fullWidth
                field="TEST"
                name="name"
                options={YES_NO_OPTIONS}
                label={"Help Label"}
              />
            ),
            dropdown: (
              <Select
                fullWidth
                label={filterarray.label}
                field={filterarray.id}
                eventHandle={this.handleChange}
                options={makeOptions(filterarray.options)}
              />
            )
          }[filterarray.dataType]
        }
      </div>
    );
  }
}
/*********************Usage************************
 * 1. fieldconf (array of objects)
 *      {
            id: "id",               * Required. Used as the field name for matching to the fields.
            numeric: true,          * Options: true/false
            label: "Actions",       * Required. Used as the label for the form
            hidden: false,          * Used independent of datatype to decide whether to display.
            dataType: "hidden",     * Required. Options: hidden, date, ignore (excludes non-fields), numeric, text, longText, radio, dropdown, truefalse, readonly
            options: false,         * Array of options for dropdown or radio
        }
 * 2. fields (an object of key/value pairs)
 * 3. saveFlexible parameter. This handles form submissions and receives the values
 * 4. handleChange Allows the state to change on the parent object.
 * 5. hasMap: true/false
 * 6. Map Parameters: 
 *      a. updatePosition: Sets the location fields on the redux ORM object.
 *      b. changeLocationEvent: Also sets location, but sets additionally the mode of entry.
 *      c. markerpos: The location position for the map.
 *      d. locationState: the state's selected value for the mode of entry.
 *      e. changeLat / changeLng: Event handling to update the location on the state.
 *      f. editable: Sets the map mode to editable. 
 ********************************************/

/****************TODOS*************************
 * 1. Add "order" parameter for the fieldconf to sort the order on the forms.
 * 2. Add some sort of override so the hidden "ID" fields are shown separately.
 * 3. View only mode, for when permissions aren't there.
 *      a. View form without fields for view-only form mode.
 *      b. View table for multiple view-only mode. Then allow for editing individual units.
 * 4. Entry mode, rendering blank forms.
 *      a. ormWellCreateLocalOnly (should work)
 *      b. ormWellPushCreated (or something like that) to save.
 * 5. Abstract out the save method so just the action can be passed
 **********************************************/

class FlexibleForm extends Component {
  onSubmit = (values, is_update) => {
    console.log("is Update: " + is_update);
    // How do we know whether this is a "UPDATE/PATCH" or a "CREATE/POST" request?
    /*
     * 1. this.defaultValues !== values, but that's the case with a changed item as well. */
    this.props.saveFlexible(values, is_update);
  };
  handleChange = (value, event) => {
    if (
      value === "current location (use gps)" ||
      value === "click point on map" ||
      value === "enter latitude/longitude"
    ) {
      this.props.changeLocationEvent(value);
    }
  };

  makeBlankOfType = (fieldType, options) => {
    switch (fieldType) {
      case "dropdown":
        // Need to get options at this point.
        if (options.includes("")) {
          return "";
        } else if (options === false) {
          return "";
        } else if (options.length > 0) {
          return options[0];
        } else {
          return "";
        }
      case "truefalse":
        return null;
      case "ignore":
        return null;
      case "radio":
        return null;
      case "longText":
        return "";
      case "numeric":
        return null;
      case "date":
        return null;
      case "hidden":
        return null;
      case "readonly":
        return null;
      case "text":
        return "";
      default:
        return "";
    }
  };

  generateBlankFields = (fields, fieldconf) => {
    if (Object.keys(fields).length > 0) {
      return fields;
    } else {
      let fieldCreated = {};
      Object.values(fieldconf).forEach(fld => {
        fieldCreated[fld.id] = this.makeBlankOfType(fld.dataType, fld.options);
      });
      return fieldCreated;
    }
  };

  render() {
    const {
      fields = [{ latitude: "", longitude: "" }],
      fieldconf,
      markerpos,
      locationState,
      changeLat,
      changeLng,
      hasMap
    } = this.props; //isEdit=true,

    const _fields = this.generateBlankFields(fields, fieldconf);
    let is_update = true;
    if (_fields !== fields) {
      is_update = false;
    }

    return (
      <div style={{ marginTop: "40px", marginBottom: "40px" }}>
        <Grid container spacing={24}>
          <Form
            dontValidateOnSubmit="true"
            validateOnSubmit="true"
            validateError={this.errorValidator}
            defaultValues={_fields}
            onSubmit={values => {
              this.onSubmit(values, is_update);
            }}
            onSubmitFailure={() =>
              function(err) {
                console.log(err);
              }
            }
          >
            {formApi => (
              <Grid item xs={12}>
                <form onSubmit={formApi.submitForm}>
                  <Grid container spacing={40}>
                    {Object.keys(_fields)
                      .filter(fieldname =>
                        fieldconf
                          .filter(row => row.dataType !== "ignore")
                          .map(row => row.id)
                          .includes(fieldname)
                      )
                      .map(fld => (
                        <Grid item xs={10} sm={6} md={4} lg={3}>
                          {
                            <FlexibleField
                              fieldconf={fieldconf}
                              field={fld}
                              value={_fields[fld]}
                              locationState={locationState}
                            />
                          }
                        </Grid>
                      ))}
                  </Grid>
                  {hasMap ? (
                    <Grid container spacing={40}>
                      <Grid item xs={10} sm={6} md={4} lg={3}>
                        <Select
                          field="location_select"
                          value={locationState}
                          fullWidth
                          label="Enter Location Using..."
                          eventHandle={this.handleChange}
                          options={makeOptions([
                            "Current Location (Use GPS)",
                            "Click Point on Map",
                            "Enter Latitude/Longitude"
                          ])}
                        />
                      </Grid>

                      <Grid item xs={10} sm={6} md={4} lg={3}>
                        <TextField
                          field={"latitude"}
                          label={"Latitude"}
                          name="latitude"
                          type="number"
                          style={{
                            display:
                              locationState !== "enter latitude/longitude"
                                ? "none"
                                : "block"
                          }}
                          eventHandle={changeLat}
                          value={_fields.latitude}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={10} sm={6} md={4} lg={3}>
                        <TextField
                          field={"longitude"}
                          label={"Longitude"}
                          name="longitude"
                          type="number"
                          style={{
                            display:
                              locationState !== "enter latitude/longitude"
                                ? "none"
                                : "block"
                          }}
                          eventHandle={changeLng}
                          value={_fields.longitude}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {hasMap && locationState !== "enter latitude/longitude" ? (
                    <Grid container spacing={40}>
                      <DashboardMap
                        containerheight={null}
                        markerpos={markerpos}
                        editable={this.props.editable}
                        formApi={formApi}
                        updatePosition={this.props.updatePosition}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid container spacing={40}>
                    <Grid item xs={10} sm={6} md={4} lg={3}>
                      &nbsp;
                    </Grid>
                  </Grid>
                  <Grid container spacing={40}>
                    <Grid item xs={10} sm={6} md={4} lg={3}></Grid>
                    <Grid item xs={10} sm={6} md={4} lg={3}>
                      <Submit
                        label="Submit"
                        gridSize={12}
                        altLabel="Back"
                        altAction={""}
                      />
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            )}
          </Form>
        </Grid>
      </div>
    );
  }
}
FlexibleForm = connect(
  state => ({
    navState: state.nav,
    authState: state.auth
  }),
  {
    ...navActions
  }
)(FlexibleForm);
export default withStyles(styles)(FlexibleForm);
