import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Form } from "react-form";
import moment from "moment";
import { createSelector } from "../common/orm";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  ReferenceLine,
} from "recharts";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import ListIcon from "@material-ui/icons/List";
import PollIcon from "@material-ui/icons/Poll";
import PowerIcon from "@material-ui/icons/Power";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import ChevronRight from "@material-ui/icons/ChevronRight";
import MapIcon from "@material-ui/icons/Map";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContactMail from "@material-ui/icons/ContactMail";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Select from "../common/Select";
import TextField from "../common/TextField";
import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import Icon from "@mdi/react";
import { mdiAlphaP, mdiAndroidStudio } from "@mdi/js";
import BreadcrumbNav from "../common/BreadCrumb";
import TableCell from "../common/TableCell";
import { WellSite } from "./models";
import WaterLevelDetailMap from "../common/map/WellSiteDetailMap";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReactToPrint from "react-to-print";

import { Measurements } from "../../api/models";
import { array } from "prop-types";

import NotReady from "../common/NotReady";
import WellSiteDetails from "../../api/wellsiteDetailsHOC";

import { saveAs } from "file-saver";

const styles = (theme) => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee",
  },
  expansionBorder: {
    borderTop: "1px solid " + theme.palette.primary.light,
    borderBottom: "1px solid " + theme.palette.primary.light,
  },
  lastBorder: {
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lastBorder2: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lastBorder3: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  expandedMargin: {
    marginTop: "12px",
    minHeight: "48px !important",
    maxHeight: 48,
    backgroundColor: "#eee",
  },
  borderContainer: {
    borderTop: "1px solid " + theme.palette.primary.light,
    borderBottom: "1px solid " + theme.palette.primary.light,
    borderRight: "2px solid " + theme.palette.primary.light,
    borderLeft: "2px solid " + theme.palette.primary.light,
  },
  borderContainer2: {
    border: "1px solid rgb(221, 221, 221)",
  },
  buttonSelected: {
    border: "3px solid #13aff1",
    minWidth: "44px",
  },
  buttonNotSelected: {
    border: "3px solid #666",
    minWidth: "44px",
  },
  iconSelected: {
    color: "#13aff1",
  },
  iconNotSelected: {
    color: "#666",
  },
  divideButton: {
    marginBottom: 16,
    width: "calc(50% - 16px)",
    marginTop: 16,
    minWidth: 44,
  },
  divideContainer: {
    backgroundColor: "#ddd",
    textAlign: "center",
    margin: "0 -4px",
  },
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 500,
    "& tbody tr:nth-child(even)": {
      backgroundColor: "rgb(106,225,255,.2)",
    },
  },
  centerAlign: {
    textAlign: "center",
  },
  marginLeft: {
    marginLeft: -16,
  },
  chartTitle: {
    backgroundColor: "rgb(221, 221, 221)",
    textAlign: "center",
    padding: "8px 4px !important",
  },
  marginTop: {
    marginTop: 8,
    marginRight: 8,
  },
});

const columnData = [
  { id: "measurement_date", numeric: false, label: "Date", allowSort: true },
  { id: "measurement_time", numeric: false, label: "Time", allowSort: true },
  {
    id: "measurement_depth",
    numeric: true,
    label: (
      <>
        Meas. Depth
        <br />
        from RP (ft)
      </>
    ),
    allowSort: true,
  },
  {
    id: "surf_elev",
    numeric: true,
    label: (
      <>
        Water Surface
        <br />
        Elevation (ft)
      </>
    ),
    allowSort: true,
  },
  {
    id: "vert_datum",
    numeric: false,
    label: "Vertical Datum",
    allowSort: true,
  },
  { id: "meas_method", numeric: false, label: "Meas. Method", allowSort: true },
  {
    id: "meas_accuracy",
    numeric: false,
    label: "Meas. Accuracy",
    allowSort: true,
  },
  { id: "no_measurement", numeric: false, label: "No Meas.", allowSort: true },
  {
    id: "quest_meas",
    numeric: false,
    label: "Questionable Meas.",
    allowSort: true,
  },
  { id: "measured_by", numeric: false, label: "Meas. By", allowSort: true },
  { id: "meas_comments", numeric: false, label: "Comments", allowSort: true },
];

const CustomTooltip = ({ active, payload, label }) => {
  function formatFloat(value, decimal_places) {
    try {
      const float_value = parseFloat(value);
      return float_value.toFixed(decimal_places);
    } catch {
      return "";
    }
  }
  if (active && payload != null) {
    var the_label = "";
    var the_value = "";
    if (payload[0]["dataKey"] === "measurement_depth") {
      the_label = "Water Depth";
      the_value = formatFloat(payload[0]["payload"]["measurement_depth"], 1);
    }
    if (payload[0]["dataKey"] === "surf_elev") {
      the_label = "Water Surface Elevation";
      the_value = formatFloat(payload[0]["payload"]["surf_elev"], 1);
    }

    const date_parts = payload[0]["payload"]["measurement_date"].split("-");
    const date_string = [date_parts[1], date_parts[2], date_parts[0]].join("-");

    return (
      <div
        style={{
          border: "1px solid black",
          backgroundColor: "rgba(255, 255, 255, 0.6)",
          padding: "10px",
        }}
      >
        <Typography variant="body1">Measurement Date: {date_string}</Typography>
        <Typography variant="body1">
          {the_label} (ft): {the_value}
        </Typography>
      </div>
    );
  }

  return null;
};

class WaterLevelDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonState: "chart",
      order: "desc",
      orderBy: "measurement_date",
      page: 0,
      rowsPerPage: 10,
      y_axis: "surf_elev",
      y_axis_label: "Groundwater Surface Elevation",
      start_date: null,
      end_date: null,
      min_value: null,
      max_value: null,
      mapState: false,
      printOpen: false,
      measurements: [],
    };
    this.formKey = window.performance.now();
    this.printChart = this.printChart.bind(this);
  }

  componentDidUpdate() {
    const { start_date, measurements } = this.state;
    if (measurements.length > 0 && !start_date) {
      this.setState({
        start_date: this.getMinDate(measurements).getFullYear(),
        end_date: new Date().getFullYear(),
      });
      this.formKey = window.performance.now();
    }
  }

  remove_null_measurements = (measurements) => {
    if (this.state.y_axis === "surf_elev") {
      return measurements.filter((m) => m.surf_elev !== null);
    } else {
      return measurements.filter((m) => m.measurement_depth !== null);
    }
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.state.measurements.sort(function (a, b) {
      var numeric = columnData.find((cD) => cD.id === orderBy).numeric;
      if (numeric) {
        if (order === "desc")
          return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
        else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
      } else {
        if (order === "desc")
          return (b[orderBy] || "").toUpperCase() <
            (a[orderBy] || "").toUpperCase()
            ? -1
            : 1;
        else
          return (a[orderBy] || "").toUpperCase() <
            (b[orderBy] || "").toUpperCase()
            ? -1
            : 1;
      }
    });
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };

  formatDate(dateString) {
    if (!dateString) {
      return null;
    }
    const d = new Date(dateString + " 12:00:00");
    return d.toLocaleDateString();
  }

  formatTime(timeString) {
    if (!timeString) {
      return null;
    }
    const d = new Date("2000-01-01 " + timeString);
    return d.toLocaleTimeString();
  }

  getMinDate(arr) {
    if (arr === undefined || arr.length === 0) {
      return null;
    }

    return new Date(
      arr.reduce(
        (min, p) =>
          new Date(p.measurement_date + " 12:00:00") < min
            ? new Date(p.measurement_date + " 12:00:00")
            : min,
        new Date(arr[0].measurement_date + " 12:00:00")
      )
    );
  }

  getMaxDate(arr) {
    if (arr === undefined || arr.length === 0) {
      return null;
    }
    return new Date(
      arr.reduce(
        (max, p) =>
          new Date(p.measurement_date + " 12:00:00") > max
            ? new Date(p.measurement_date + " 12:00:00")
            : max,
        new Date(arr[0].measurement_date + " 12:00:00")
      )
    );
  }

  getMinMaxVal(arr, val, site) {
    let value = val === "surf_elev" ? "surf_elev" : "measurement_depth";
    let min = 1000000000,
      max = 0;
    for (let i = 1, len = arr.length; i < len; i++) {
      let v = arr[i][value];
      min = v < min ? v : min;
      max = v > max ? v : max;
    }
    if (value === "surf_elev") {
      if (site.gsp_wl_threshold_elev && site.gsp_wl_threshold_elev > max) {
        max = site.gsp_wl_threshold_elev;
      }
      if (
        site.measurable_objective &&
        parseFloat(site.measurable_objective) > max
      ) {
        max = parseFloat(site.measurable_objective);
      }
      if (site.ground_surface_elev && site.ground_surface_elev > max) {
        max = site.ground_surface_elev;
      }
      if (site.gsp_wl_threshold_elev && site.gsp_wl_threshold_elev < min) {
        min = site.gsp_wl_threshold_elev;
      }
      if (
        site.measurable_objective &&
        parseFloat(site.measurable_objective) < min
      ) {
        min = parseFloat(site.measurable_objective);
      }
      if (site.ground_surface_elev && site.ground_surface_elev < min) {
        min = site.ground_surface_elev;
      }
    }

    if (value == "measurement_depth") {
      if (site.measurable_objective_depth) {
        if (site.measurable_objective_depth < min) {
          min = site.measurable_objective_depth;
        }
        if (site.measurable_objective_depth > max) {
          max = site.measurable_objective_depth;
        }
      }
      if (site.minimum_threshold_depth) {
        if (site.minimum_threshold_depth < min) {
          min = site.minimum_threshold_depth;
        }
        if (site.minimum_threshold_depth > max) {
          max = site.minimum_threshold_depth;
        }
      }
    }

    if (min && min != 0) {
      min = this.round(min - 5, 0);
    }
    max = this.round(max + 5, 0);
    return { min, max };
  }

  round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  formatFloat = (value, decimal_places) => {
    try {
      const float_value = parseFloat(value);
      return float_value.toFixed(decimal_places);
    } catch {
      return "";
    }
  };

  printChart = () => {
    const { site } = this.props;
    var printWindow = window.open("", "PrintMap", "width=1200,height=850");
    printWindow.document.writeln(
      "DMS Site ID: " +
        site.dms_site_id +
        this.refs.chartContainer.container.innerHTML
    );
    printWindow.document.close();
  };

  handlePrintClose = () => {
    this.setState({ printOpen: false });
  };

  handleAPIChange(data) {
    this.setState({ site: data.site, measurements: data.site.measurements });
  }

  exportData = (data, key) => {
    let heading = key === "surf_elev" ? "Elevation" : "Depth";
    var huh = "date,Elevation,Depth\n";

    data.forEach((d) => {
      huh = `${huh}${d["measurement_date"]},${
        d["surf_elev"] ? d["surf_elev"] : ""
      },${d["measurement_depth"] ? d["measurement_depth"] : ""}\n`;
    });

    var filename = `${this.state.site.dms_site_id}_water_levels.csv`;
    var blob = new Blob([huh], { type: "text/plain;charset=utf-8" });
    saveAs(blob, filename);
  };

  render() {
    const { classes } = this.props;
    const {
      buttonState,
      page,
      rowsPerPage,
      order,
      orderBy,
      min_value,
      max_value,
      y_axis,
      y_axis_label,
      start_date,
      end_date,
      site,
      measurements,
    } = this.state;

    let siteId = parseInt(this.props.match.params["id"]);

    let level1 = "Well Level Monitoring";
    let level1link = "/waterlevellist";
    let level2link = "/waterleveldetail/" + (site ? site.id : "");

    const measurements_without_nulls =
      this.remove_null_measurements(measurements);

    var filtered_measurements = [];
    // Custom formatting of values for chart
    if (measurements_without_nulls && measurements_without_nulls.length > 0) {
      var filtered_measurements = measurements_without_nulls.slice();
      filtered_measurements.forEach(function (m) {
        if (!m.chart_date && m.measurement_date) {
          var d;
          if (m.measurement_time && m.meaurement_time !== "")
            d = new Date(m.measurement_date + " " + m.measurement_time);
          else d = new Date(m.measurement_date + " 12:00:00");
          m.chart_date = d.getTime();
        }
        if (m.measurement_depth) {
          m.measurement_depth = parseFloat(m.measurement_depth);
        }
        if (m.surf_elev) {
          m.surf_elev = parseFloat(m.surf_elev);
        }
      });
      filtered_measurements.sort((m, n) => m.chart_date - n.chart_date);
      var minDate = this.getMinDate(filtered_measurements);
      var maxDate = this.getMaxDate(filtered_measurements);
      var yearoptions = [];
      var y = minDate.getFullYear();
      var z = new Date().getFullYear();
      while (y <= z) {
        yearoptions.push({ label: y, value: y });
        y++;
      }
      if (start_date) {
        filtered_measurements = filtered_measurements.filter(
          (m) =>
            new Date(m.measurement_date + " 12:00:00").getFullYear() >=
            parseInt(start_date)
        );
      }
      if (end_date) {
        filtered_measurements = filtered_measurements.filter(
          (m) =>
            new Date(m.measurement_date + " 12:00:00").getFullYear() <=
            parseInt(end_date)
        );
      }
    }

    if (site === undefined) {
      return (
        <AppContainer authenticated>
          <WellSiteDetails
            apitarget={"wellsitemeasurements"}
            siteid={siteId}
            handler={this.handleAPIChange.bind(this)}
          />
          <NotReady message="Loading..." />
        </AppContainer>
      );
    } else {
      return (
        <AppContainer authenticated>
          <Dialog
            style={{ minWidth: 800 }}
            open={this.state.printOpen}
            onClose={this.handlePrintClose}
            fullWidth={true}
            maxWidth={"md"}
          >
            <DialogTitle
              id="alert-dialog-slide-title"
              style={{ backgroundColor: "primary" }}
            >
              Print Well Site
            </DialogTitle>
            <DialogContent>
              <div ref={(el) => (this.componentRef = el)}>
                <Grid
                  container
                  spacing={24}
                  style={{ width: "700px", marginLeft: "20px" }}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant={"h5"}
                      gutterBottom
                      style={{ textAlign: "center" }}
                    >
                      DMS Site ID: {site.dms_site_id}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <ResponsiveContainer
                      width="100%"
                      // height={300}
                      ref="mapContainer"
                    >
                      <WaterLevelDetailMap
                        geometry={site.geometry.coordinates}
                        // height={300}
                        width={"100%"}
                        zoom={8}
                      />
                    </ResponsiveContainer>
                  </Grid>

                  <Grid item xs={12}>
                    <ResponsiveContainer
                      width="100%"
                      height={550}
                      ref="chartContainer"
                    >
                      <LineChart
                        data={
                          y_axis === "surf_elev"
                            ? filtered_measurements.filter(
                                (s) => s.surf_elev != null
                              )
                            : filtered_measurements.filter(
                                (s) => s.measurement_depth != null
                              )
                        }
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="chart_date"
                          name="Measurement Date"
                          domain={[
                            this.getMinDate(filtered_measurements),
                            this.getMaxDate(filtered_measurements),
                          ]}
                          type="number"
                          tickFormatter={(unixTime) =>
                            moment(unixTime).format("MM/DD/YYYY")
                          }
                        />
                        {y_axis === "surf_elev" && (
                          <YAxis
                            // domain has to be in functions otherwise it doesnt update?
                            domain={[
                              (dataMin) =>
                                min_value
                                  ? parseFloat(min_value)
                                  : this.getMinMaxVal(
                                      filtered_measurements,
                                      y_axis,
                                      site
                                    ).min,
                              (dataMax) =>
                                max_value
                                  ? parseFloat(max_value)
                                  : this.getMinMaxVal(
                                      filtered_measurements,
                                      y_axis,
                                      site
                                    ).max,
                            ]} // 'auto' doesnt work instead of math.ceil
                            dataKey={y_axis}
                            type="number"
                          >
                            <Label value="feet" position="middle" angle={-90} />
                          </YAxis>
                        )}
                        {y_axis === "measurement_depth" && (
                          <YAxis
                            //flip the min/max
                            domain={[
                              (dataMax) =>
                                max_value
                                  ? parseFloat(max_value)
                                  : this.getMinMaxVal(
                                      filtered_measurements,
                                      y_axis,
                                      site
                                    ).max,
                              (dataMin) =>
                                min_value ? parseFloat(min_value) : 0,
                            ]} // 'auto' doesnt work instead of math.ceil
                            dataKey={y_axis}
                            type="number"
                          >
                            <Label value="feet" position="middle" angle={-90} />
                          </YAxis>
                        )}
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Line
                          connectNulls
                          isAnimationActive={false} // Dots are broken on re-render if true https://github.com/recharts/recharts/issues/804
                          name={y_axis_label}
                          type="linear"
                          dataKey={y_axis}
                          stroke="#0080be"
                        />
                        {y_axis === "surf_elev" &&
                          site.gsp_wl_threshold_elev && (
                            <ReferenceLine
                              y={site.gsp_wl_threshold_elev}
                              label={
                                "Minimum Threshold " +
                                this.round(site.gsp_wl_threshold_elev, 1)
                              }
                              stroke="red"
                            />
                          )}
                        {y_axis === "surf_elev" &&
                          parseFloat(site.measurable_objective) && (
                            <ReferenceLine
                              y={parseFloat(site.measurable_objective)}
                              label={
                                "Measureable Objective " +
                                this.round(
                                  parseFloat(site.measurable_objective),
                                  1
                                )
                              }
                              stroke="green"
                            />
                          )}
                        {y_axis === "surf_elev" && site.ground_surface_elev && (
                          <ReferenceLine
                            y={site.ground_surface_elev}
                            label={
                              "Ground Surface Elevation " +
                              this.round(site.ground_surface_elev, 1)
                            }
                            stroke="brown"
                          />
                        )}
                        {y_axis === "measurement_depth" &&
                          site.measurable_objective_depth && (
                            <ReferenceLine
                              y={site.measurable_objective_depth}
                              label={
                                "Measurable Objective " +
                                this.round(site.measurable_objective_depth, 1)
                              }
                              stroke="red"
                            />
                          )}

                        {y_axis === "measurement_depth" &&
                          site.minimum_threshold_depth && (
                            <ReferenceLine
                              y={site.minimum_threshold_depth}
                              label={
                                "Minimum Threshold " +
                                this.round(site.minimum_threshold_depth, 1)
                              }
                              stroke="green"
                            />
                          )}
                      </LineChart>
                    </ResponsiveContainer>
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
            <DialogActions>
              <ReactToPrint
                trigger={() => <Button color="primary">Print</Button>}
                title={"Print"}
                content={() => this.componentRef}
                copyStyles={true}
              />
              <Button
                color="primary"
                onClick={() => this.setState({ printOpen: false })}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Grid container spacing={24}>
            <Grid item xs={12} className={classes.breadCrumb}>
              <BreadcrumbNav
                level1={level1}
                level1link={level1link}
                level2={"DMS ID: " + site.dms_site_id}
                level2link={level2link}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Monitoring Site Details</Typography>
            </Grid>
            <Grid item xs={12}>
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary
                  classes={{ root: classes.expandedMargin }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <ContactMail style={{ marginTop: 5 }} />
                  <Typography style={{ paddingLeft: 32 }} variant="h6">
                    Identification/Location
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid
                    container
                    spacing={16}
                    className={classes.borderContainer}
                  >
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>DMS ID</b>: {site.dms_site_id}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Local Site ID</b>: {site.local_site_id}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Original Site ID</b>: {site.origin_site_id}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>State Well Number</b>: {site.dwr_well_id}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>CASGEM Well Number</b>: {site.casgem_well_id}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Source GSP Organization</b>: {site.source_org_label}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Owner</b>: {site.owner_name}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Monitored By</b>: {site.monitor_by_name}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Latitude</b>:{" "}
                        {site.geometry && site.geometry.coordinates[1]}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Longitude</b>:{" "}
                        {site.geometry && site.geometry.coordinates[0]}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Coordinate Collection Method</b>:{" "}
                        {site.coordinate_method}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Coordinate Accuracy (ft)</b>:{" "}
                        {site.coordinate_accuracy}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Horizontal Datum</b>: {site.horz_datum}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>TRSQQ</b>:{" "}
                        {site.township +
                          site.range +
                          site.section +
                          site.quarter_section || ""}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Well Use</b>: {site.well_use}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Well Status</b>: {site.well_status}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Well Completion Type</b>: {site.well_completion_type}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classNames(
                        classes.expansionBorder,
                        classes.lastBorder2
                      )}
                      item
                      xs={6}
                      xl={4}
                    ></Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  classes={{ root: classes.expandedMargin }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Icon
                    path={mdiAndroidStudio}
                    className={classes.marginTop}
                    size={1}
                  ></Icon>
                  <Typography
                    style={{ paddingLeft: 16, marginTop: 8 }}
                    variant="h6"
                  >
                    {" "}
                    Ground Surface and Reference Point Information
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid
                    container
                    spacing={16}
                    className={classes.borderContainer}
                  >
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>RP and GS Elevation Measurement Date</b>:{" "}
                        {this.formatDate(site.meas_elev_date)}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Ground Surface Elevation</b>:{" "}
                        {this.formatFloat(site.ground_surface_elev, 1)}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Ground Surface Elevation Method</b>:{" "}
                        {site.elevation_method}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Ground Surface Elevation Accuracy</b>:{" "}
                        {site.elevation_accuracy}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Reference Point Elevation</b>:{" "}
                        {this.formatFloat(site.rpe, 1)}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Reference Point Description</b>: {site.rp_desc}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Vertical Datum</b>: {site.vert_datum}
                      </Typography>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  classes={{ root: classes.expandedMargin }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Icon
                    path={mdiAlphaP}
                    className={classes.marginLeft}
                    size={2}
                  ></Icon>
                  <Typography
                    style={{ paddingLeft: 16, marginTop: 8 }}
                    variant="h6"
                  >
                    {" "}
                    Plan Information
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid
                    container
                    spacing={16}
                    className={classes.borderContainer}
                  >
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>GSP WL Threshold Elevation</b>:{" "}
                        {site.gsp_wl_threshold_elev}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Year 5 Interim Milestone (2025)</b>:{" "}
                        {site.yr5_milestone
                          ? this.formatFloat(site.yr5_milestone, 1)
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Year 10 Interim Milestone (2030)</b>:{" "}
                        {site.yr10_milestone
                          ? this.formatFloat(site.yr10_milestone, 1)
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Year 15 Interim Milestone (2035)</b>:{" "}
                        {site.yr15_milestone
                          ? this.formatFloat(site.yr15_milestone, 1)
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>GSP WL Measurable Objective</b>:{" "}
                        {site.measurable_objective}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>GSP Monitoring Network</b>:{" "}
                        {site.gsp_mon_network ? "Yes" : "No"}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>SGMA Site Monitoring Type</b>:{" "}
                        {site.monitor_site_type}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Aquifier Designation</b>: {site.aquifer_designation}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Interconnected Monitoring</b>:{" "}
                        {site.interconnected_monitoring}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Water Quality Monitoring</b>: {site.wq_monitoring}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Water Level Monitoring</b>: {site.wl_monitoring}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Well Notes</b>: {site.well_notes}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classNames(
                        classes.expansionBorder,
                        classes.lastBorder
                      )}
                      item
                      xs={6}
                    ></Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  classes={{ root: classes.expandedMargin }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <LocalDrinkIcon style={{ marginTop: 10 }} />
                  <Typography
                    style={{ paddingLeft: 16, marginTop: 8 }}
                    variant="h6"
                  >
                    {" "}
                    Drilling Information
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid
                    container
                    spacing={16}
                    className={classes.borderContainer}
                  >
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Total Well Depth (ft)</b>:{" "}
                        {site.total_well_depth
                          ? this.formatFloat(site.total_well_depth, 1)
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Perforated Intervals Top (ft)</b>:{" "}
                        {this.formatFloat(site.perforated_interval_top, 1)}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Drill Date</b>: {site.date_drilled}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Perforated Intervals Bottom (ft)</b>:{" "}
                        {this.formatFloat(site.perforated_interval_bottom, 1)}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Screened Intervals (ft)</b>:{" "}
                        {site.perforated_intervals_screen
                          ? this.formatFloat(
                              site.perforated_intervals_screen,
                              1
                            )
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classNames(
                        classes.expansionBorder,
                        classes.lastBorder2
                      )}
                      item
                      xs={6}
                      xl={4}
                    ></Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  classes={{ root: classes.expandedMargin }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <PowerIcon style={{ marginTop: 10 }} />
                  <Typography
                    style={{ paddingLeft: 16, marginTop: 8 }}
                    variant="h6"
                  >
                    {" "}
                    Motor/Pump Information
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid
                    container
                    spacing={16}
                    className={classes.borderContainer}
                  >
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Power Type</b>: {site.power_type}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.expansionBorder}
                      item
                      xs={12}
                      md={6}
                      xl={4}
                    >
                      <Typography>
                        <b>Volumetric Pumping Calculation Type</b>:{" "}
                        {site.volumetric_calc_type}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classNames(
                        classes.expansionBorder,
                        classes.lastBorder3
                      )}
                      item
                      xs={4}
                    ></Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h6">
                Measurement Data{" "}
                <Button
                  title="Map"
                  onClick={() =>
                    this.setState({ mapState: !this.state.mapState })
                  }
                >
                  <MapIcon
                    className={
                      this.state.mapState
                        ? classes.iconSelected
                        : classes.iconNotSelected
                    }
                  />
                </Button>
                <Button
                  // fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => this.exportData(filtered_measurements, y_axis)}
                >
                  <Typography
                    style={{
                      textAlign: "left",
                      width: "100%",
                      color: "#fff",
                    }}
                  >
                    Export Tabular Well Data
                  </Typography>
                </Button>
              </Typography>{" "}
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6">Select View</Typography>
            </Grid>
            <Grid item xs={8}>
              {site !== null && site.geometry && this.state.mapState && (
                <ResponsiveContainer
                  width="100%"
                  height={150}
                  ref="mapContainer"
                >
                  <WaterLevelDetailMap
                    geometry={site.geometry.coordinates}
                    height={"150px"}
                    width={"100%"}
                    zoom={8}
                  />
                </ResponsiveContainer>
              )}
            </Grid>
            <Grid item xs={4}>
              <div className={classes.divideContainer}>
                <Button
                  title="Table"
                  onClick={() => this.setState({ buttonState: "table" })}
                  className={classNames(
                    classes.divideButton,
                    buttonState === "table"
                      ? classes.buttonSelected
                      : classes.buttonNotSelected
                  )}
                >
                  <ListIcon
                    className={
                      buttonState === "table"
                        ? classes.iconSelected
                        : classes.iconNotSelected
                    }
                  />
                </Button>
                <Button
                  title="Chart"
                  onClick={() => this.setState({ buttonState: "chart" })}
                  className={classNames(
                    classes.divideButton,
                    buttonState === "chart"
                      ? classes.buttonSelected
                      : classes.buttonNotSelected
                  )}
                >
                  <PollIcon
                    className={
                      buttonState === "chart"
                        ? classes.iconSelected
                        : classes.iconNotSelected
                    }
                  />
                </Button>
              </div>
            </Grid>
            {buttonState === "table" && (
              <Grid item xs={12}>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      columnData={columnData}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                    />
                    <TableBody>
                      {measurements
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>
                              {this.formatDate(item.measurement_date)}
                            </TableCell>
                            <TableCell>
                              {this.formatTime(item.measurement_time)}
                            </TableCell>
                            <TableCell>
                              {item.measurement_depth
                                ? item.measurement_depth.toFixed(1)
                                : ""}
                            </TableCell>
                            <TableCell>
                              {item.surf_elev ? item.surf_elev.toFixed(1) : ""}
                            </TableCell>
                            <TableCell>{item.vert_datum}</TableCell>
                            <TableCell>{item.meas_method}</TableCell>
                            <TableCell>{item.meas_accuracy}</TableCell>
                            <TableCell>{item.no_measurement}</TableCell>
                            <TableCell>{item.quest_meas}</TableCell>
                            <TableCell>{item.measured_by}</TableCell>
                            <TableCell>{item.meas_comments}</TableCell>
                          </TableRow>
                        ))}
                      {measurements.length < 1 && (
                        <TableRow>
                          <TableCell
                            colSpan={7}
                            className={classes.centerAlign}
                          >
                            No Measurements Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    {measurements.length > 10 && (
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            colSpan={7}
                            count={measurements.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActionsWrapped}
                          />
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                </Paper>
              </Grid>
            )}
            {buttonState === "chart" && (
              <>
                {filtered_measurements.length === 0 && (
                  <Grid item xs={12}>
                    <Typography variant="h5" className={classes.centerAlign}>
                      No Measurements Found
                    </Typography>
                  </Grid>
                )}
                {filtered_measurements.length > 0 && (
                  <>
                    <Grid item xs={8}>
                      <ResponsiveContainer
                        width="100%"
                        height={680}
                        ref="chartContainer"
                      >
                        <LineChart
                          data={
                            y_axis === "surf_elev"
                              ? filtered_measurements.filter(
                                  (s) => s.surf_elev != null
                                )
                              : filtered_measurements.filter(
                                  (s) => s.measurement_depth != null
                                )
                          }
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="chart_date"
                            name="Measurement Date"
                            domain={[
                              this.getMinDate(filtered_measurements),
                              this.getMaxDate(filtered_measurements),
                            ]}
                            type="number"
                            tickFormatter={(unixTime) =>
                              moment(unixTime).format("MM/DD/YYYY")
                            }
                          />
                          {y_axis === "surf_elev" && (
                            <YAxis
                              // domain has to be in functions otherwise it doesnt update?
                              domain={[
                                (dataMin) =>
                                  min_value
                                    ? parseFloat(min_value)
                                    : this.getMinMaxVal(
                                        filtered_measurements,
                                        y_axis,
                                        site
                                      ).min,
                                (dataMax) =>
                                  max_value
                                    ? parseFloat(max_value)
                                    : this.getMinMaxVal(
                                        filtered_measurements,
                                        y_axis,
                                        site
                                      ).max,
                              ]} // 'auto' doesnt work instead of math.ceil
                              dataKey={y_axis}
                              type="number"
                            >
                              <Label
                                value="feet"
                                position="middle"
                                angle={-90}
                              />
                            </YAxis>
                          )}
                          {y_axis === "measurement_depth" && (
                            <YAxis
                              //flip the min/max
                              domain={[
                                (dataMax) =>
                                  max_value
                                    ? parseFloat(max_value)
                                    : this.getMinMaxVal(
                                        filtered_measurements,
                                        y_axis,
                                        site
                                      ).max,
                                (dataMin) =>
                                  min_value ? parseFloat(min_value) : 0,
                              ]} // 'auto' doesnt work instead of math.ceil
                              dataKey={y_axis}
                              type="number"
                            >
                              <Label
                                value="feet"
                                position="middle"
                                angle={-90}
                              />
                            </YAxis>
                          )}
                          <Tooltip content={<CustomTooltip />} />
                          <Legend />
                          <Line
                            connectNulls
                            isAnimationActive={false} // Dots are broken on re-render if true https://github.com/recharts/recharts/issues/804
                            name={y_axis_label}
                            type="linear"
                            dataKey={y_axis}
                            stroke="#0080be"
                          />
                          {y_axis === "surf_elev" &&
                            site.gsp_wl_threshold_elev && (
                              <ReferenceLine
                                y={site.gsp_wl_threshold_elev}
                                label={
                                  "Minimum Threshold " +
                                  this.round(site.gsp_wl_threshold_elev, 1)
                                }
                                stroke="red"
                              />
                            )}
                          {y_axis === "surf_elev" &&
                            parseFloat(site.measurable_objective) && (
                              <ReferenceLine
                                y={parseFloat(site.measurable_objective)}
                                label={
                                  "Measureable Objective " +
                                  this.round(
                                    parseFloat(site.measurable_objective),
                                    1
                                  )
                                }
                                stroke="green"
                              />
                            )}
                          {y_axis === "surf_elev" &&
                            site.ground_surface_elev && (
                              <ReferenceLine
                                y={site.ground_surface_elev}
                                label={
                                  "Ground Surface Elevation " +
                                  this.round(site.ground_surface_elev, 1)
                                }
                                stroke="brown"
                              />
                            )}
                          {y_axis === "measurement_depth" &&
                            site.measurable_objective_depth && (
                              <ReferenceLine
                                y={site.measurable_objective_depth}
                                label={
                                  "Measurable Objective " +
                                  this.round(site.measurable_objective_depth, 1)
                                }
                                stroke="red"
                              />
                            )}

                          {y_axis === "measurement_depth" &&
                            site.minimum_threshold_depth && (
                              <ReferenceLine
                                y={site.minimum_threshold_depth}
                                label={
                                  "Minimum Threshold " +
                                  this.round(site.minimum_threshold_depth, 1)
                                }
                                stroke="green"
                              />
                            )}
                        </LineChart>
                      </ResponsiveContainer>
                    </Grid>
                    <Grid item xs={4}>
                      <Form
                        key={this.formKey}
                        dontValidateOnMount={true}
                        validateOnSubmit={true}
                        defaultValues={this.state}
                      >
                        {(formApi) => (
                          <form onSubmit={formApi.submitForm}>
                            <Grid
                              container
                              spacing={8}
                              className={classes.borderContainer2}
                            >
                              <Grid item xs={12} className={classes.chartTitle}>
                                <Typography variant="h6">
                                  Y Axis Values
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Select
                                  field="y_axis"
                                  label=""
                                  options={[
                                    {
                                      label: "Elevation",
                                      value: "surf_elev",
                                    },
                                    {
                                      label: "Depth",
                                      value: "measurement_depth",
                                    },
                                  ]}
                                  fullWidth
                                  eventHandle={(val) => {
                                    var lab;
                                    if (val === "measurement_depth")
                                      lab = "Depth";
                                    if (val === "surf_elev") lab = "Elevation";
                                    this.setState({
                                      y_axis: val,
                                      y_axis_label: lab,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} className={classes.chartTitle}>
                                <Typography variant="h6">
                                  Summary Statistics
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>
                                  <b>Date Range</b>:&nbsp;
                                  {minDate.toLocaleDateString()}&nbsp;-&nbsp;
                                  {maxDate.toLocaleDateString()}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} className={classes.chartTitle}>
                                <Typography variant="h6">
                                  Filter Monitor Data
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Select
                                  field="start_date"
                                  label="Start Date"
                                  eventHandle={(val) =>
                                    this.setState({ start_date: val })
                                  }
                                  options={yearoptions}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Select
                                  field="end_date"
                                  label="End Date"
                                  eventHandle={(val) =>
                                    this.setState({ end_date: val })
                                  }
                                  options={yearoptions}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} className={classes.chartTitle}>
                                <Typography variant="h6">
                                  Chart Settings
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  field="min_value"
                                  type="number"
                                  label="Y Axis Min Value"
                                  eventHandle={(val) =>
                                    this.setState({ min_value: val })
                                  }
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  field="max_value"
                                  type="number"
                                  label="Y Axis Max Value"
                                  eventHandle={(val) =>
                                    this.setState({ max_value: val })
                                  }
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    this.setState({ printOpen: true })
                                  }
                                >
                                  <Typography
                                    style={{
                                      textAlign: "left",
                                      width: "100%",
                                      color: "#fff",
                                    }}
                                  >
                                    Print Chart
                                  </Typography>
                                  <ChevronRight style={{ float: "right" }} />
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      </Form>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </AppContainer>
      );
    }
  }
}

WaterLevelDetail = connect((state, ownProps) => ({}), {})(WaterLevelDetail);

export default withStyles(styles)(WaterLevelDetail);
