import React, { Component } from "react";
import classNames from "classnames";
import { FormField } from "react-form";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = theme => ({
    root: {
        display: "flex"
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit
    },
    group: {
        margin: "0"
    },
    fullWidth: {
        width: "100%"
    },
    horizontal: {
        margin: "0",
        display: "inline"
    },
    error: {
        "& h3": {
            // No way to access actual text - probably because typography tag is being passed as label
            color: "#f44336"
        },
        "& svg": {
            // Keep color on help icon
            color: "rgba(0, 0, 0, 0.87)"
        }
    }
});

class RadioGroupWrapper extends Component {
    handleChange = (event, value) => {
        const { fieldApi, eventHandle } = this.props;
        const { setValue } = fieldApi;
        if (value === "true") value = true;
        if (value === "false") value = false;

        setValue(value);

        if (eventHandle) eventHandle(value);
    };

    render() {
        const { fieldApi, label, options, classes, required, alignment, fullWidth, ...rest } = this.props;

        const { getValue, getError } = fieldApi;
        var value = getValue(),
            error = getError();

        // FIXME: Material UI requires string values
        // Radio values are returned from API as boolean
        if (value === true) value = "true";
        if (value === false) value = "false";

        return (
            <FormControl
                component="fieldset"
                required={required}
                error={error && true}
                className={classNames(classes.formControl, fullWidth && classes.fullWidth)}>
                <FormLabel component="legend" classes={{ error: classes.error }}>
                    {label}
                </FormLabel>
                <RadioGroup
                    className={alignment ? classes.horizontal : classes.group}
                    value={value}
                    onChange={this.handleChange}
                    {...rest}>
                    {options.map(object => (
                        <FormControlLabel
                            disabled={object.disabled}
                            value={object.value}
                            control={<Radio color="primary" />}
                            label={object.label}
                        />
                    ))}
                </RadioGroup>
                {error && <FormHelperText style={{ marginTop: 0 }}>{error}</FormHelperText>}
            </FormControl>
        );
    }
}

const CustomRadioGroup = FormField(RadioGroupWrapper);

export default withStyles(styles)(CustomRadioGroup);
