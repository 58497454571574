import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";

const styles = {
  input: {
    flex: 1,
    border: "2px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
    background: "White",
    paddingLeft: "4px",
  },
  gridItem: {
    paddingLeft: "2px",
  },
  inputBase: {
    paddingTop: "6px",
    paddingBottom: "6px",
    fontFamily: "Arial",
    fontSize: "12px",
    fontWeight: 400,
    color: "black",
  },
  searchBar: {
    paddingTop: "2px",
    paddingBottom: "2px",
    borderRadius: "4px",
    border: "2px solid rgba(0, 0, 0, 0.2)",
  },
};

class CoordinateSearchBar extends React.Component {
  constructor() {
    super();
    this.state = {
      xcoord: "",
      ycoord: "",
    };
  }

  handleChangeX = (event) => {
    const xcoord = event.target.value;
    this.CoordinateChangeX(xcoord);
  };

  handleChangeY = (event) => {
    const ycoord = event.target.value;
    this.CoordinateChangeY(ycoord);
  };

  handleReturnX = (event) => {
    if (event.keyCode == 13) {
      const xcoord = event.target.value;
      this.CoordinateChangeX(xcoord);
    }
  };

  handleReturnY = (event) => {
    if (event.keyCode == 13) {
      const ycoord = event.target.value;
      this.CoordinateChangeX(ycoord);
    }
  };

  CoordinateChangeX = (x) => {
    this.setState({
      xcoord: x,
    });
    this.props.verifyCoordinates([x, this.state.ycoord]);
  };

  CoordinateChangeY = (y) => {
    this.setState({
      ycoord: y,
    });
    this.props.verifyCoordinates([this.state.xcoord, y]);
  };

  handleClick = (d) => {
    this.setState({
      searchWord: d["properties"]["TRS"],
    });
    this.props.zoomToSection(d);
  };

  handleGoClick = () => {
    this.props.zoomToCoordinates([this.state.xcoord, this.state.ycoord]);
  };

  handleClearCoordinates = () => {
    this.setState({
      xcoord: "",
      ycoord: "",
    });
    this.props.verifyCoordinates(["", ""]);
    this.props.clearMarker();
  };

  render() {
    const { classes, coordinateSearchSuccess } = this.props;
    const { xcoord, ycoord } = this.state;

    return (
      <div
        id="CoordinateSearchBar"
        className="leaflet-control-geosearch bar coordinate-search-bar"
        style={{
          paddingTop: "2px",
          paddingBottom: "2px",
          borderRadius: "4px",
          border: "2px solid rgba(0, 0, 0, 0.2)",
          background: "white",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Grid container spacing={0} style={{ paddingRight: "2px" }}>
              <Grid item xs={8} className={classes.gridItem}>
                <InputBase
                  className={classes.input}
                  placeholder="Enter X coordinate..."
                  type="number"
                  fullWidth
                  inputProps={{
                    className: classes.inputBase,
                  }}
                  value={xcoord}
                  onChange={(event) => this.handleChangeX(event)}
                  onKeyUp={(event) => this.handleReturnX(event)}
                />
              </Grid>
              <Grid item xs={4} className={classes.gridItem}>
                <Button
                  disabled={coordinateSearchSuccess ? false : true}
                  color="primary"
                  fullWidth
                  variant="outlined"
                  style={{
                    height: "30px",
                    borderWidth: "2px",
                    background: "white",
                    fontFamily: "Arial",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                  onClick={() => this.handleGoClick()}
                >
                  go
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "4px" }}>
            <Grid container spacing={0} style={{ paddingRight: "2px" }}>
              <Grid item xs={8} className={classes.gridItem}>
                <InputBase
                  className={classes.input}
                  placeholder="Enter Y coordinate..."
                  type="number"
                  fullWidth
                  inputProps={{
                    className: classes.inputBase,
                  }}
                  value={ycoord}
                  onChange={(event) => this.handleChangeY(event)}
                />
              </Grid>
              <Grid item xs={4} className={classes.gridItem}>
                <Button
                  color="secondary"
                  fullWidth
                  variant="outlined"
                  style={{
                    height: "30px",
                    borderWidth: "2px",
                    background: "white",
                    fontFamily: "Arial",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                  onClick={() => this.handleClearCoordinates()}
                >
                  clear
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(CoordinateSearchBar);
