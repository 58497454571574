export const SAVE_FLEXIBLE = "SAVE_FLEXIBLE";

export function saveFlexible(values) {
    return function(dispatch, getState) {
        var { auth } = getState();
            if (auth && auth.user) {
                return fetch(this.props.putURL, {
                    method: "PUT",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Token " + auth.user.auth_token
                    },
                    body: JSON.stringify(values)
                }).then(function(response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(data => {
                            // The state of the table has changed. Need to update the table. 
                        });
                    } else if (response.status === 400) {
                        return response.json().then(data => {
                            console.log('Error Updating');
                            console.log(data);
                        });
                    } else {
                        console.log('error');
                        //dispatch(authUpdateError({ error: "Unknown Server Error" }));
                    }
                });
            } else {
                console.log('not logged in');
                return "You must be logged in";
            }
    }
}