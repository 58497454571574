import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "../common/orm";
import { withStyles } from "@material-ui/core/styles";
import { withRouter, Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import NotReady from "../common/NotReady";
import ReportingStatus from "../../api/reportingStatusHOC";
import BulkSubmitButton from "../../api/BulkSubmitButtonHOC";

import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";

import BreadcrumbNav from "../common/BreadCrumb";
import TableCell from "../common/TableCell";

import Icon from "@mdi/react";
import { mdiEye, mdiLock, mdiLockOpen } from "@mdi/js";
import requestConfig, { trimColumns } from "../../api/fetchConfig";

const styles = (theme) => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee",
  },
  tableCellIcon: {
    minWidth: 34,
    width: 34,
    marginRight: 0,
  },
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    width: "100%",
    "& tbody tr:nth-child(even)": {
      backgroundColor: "rgb(106,225,255,.2)",
    },
  },
  centerAlign: {
    textAlign: "center",
  },
  lgHeader: {
    fontSize: "25px",
  },
});

const columnData = [
  {
    id: "name",
    numeric: false,
    label: "GSP Name",
    allowSort: true,
  },
  {
    id: "gws_submitted",
    numeric: false,
    label: "GW Change in Storage",
    allowSort: true,
  },
  {
    id: "gwe_submitted",
    numeric: false,
    label: "Groundwater Extraction",
    allowSort: true,
  },
  {
    id: "sws_submitted",
    numeric: false,
    label: "Surface Water Supply",
    allowSort: true,
  },
  {
    id: "twu_submitted",
    numeric: false,
    label: "Total Water Use",
    allowSort: true,
  },
  {
    id: "gw_submitted",
    numeric: false,
    label: "Water Level Monitoring",
    allowSort: true,
  },
  {
    id: "surf_submitted",
    numeric: false,
    label: "Interconnected SW Monitoring",
    allowSort: true,
  },
  {
    id: "wq_submitted",
    numeric: false,
    label: "Water Quality Monitoring",
    allowSort: true,
  },
  {
    id: "sub_submitted",
    numeric: false,
    label: "Subsidence Monitoring",
    allowSort: true,
  },
  {
    id: "stream_submitted",
    numeric: false,
    label: "Stream Monitoring Sites",
    allowSort: true,
  },
];

class GSPReportingStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "desc",
      orderBy: "water_year",
      page: 0,
      rowsPerPage: 10,
    };
  }

  componentDidMount() {
    const { authState, history } = this.props;
    if (authState && authState.user && authState.user.role === "Agency") {
      history.push("/dashboard");
    }
  }

  handleRequestSort = (event, property) => {
    const { existingData } = this.state;
    const orderBy = property;
    let order = "desc";

    let data = existingData;

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    data.sort(function (a, b) {
      var numeric = columnData.find((cD) => cD.id === orderBy).numeric;
      if (numeric) {
        if (order === "desc")
          return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
        else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
      } else {
        if (order === "desc")
          return (b[orderBy] || "").toUpperCase() <
            (a[orderBy] || "").toUpperCase()
            ? -1
            : 1;
        else
          return (a[orderBy] || "").toUpperCase() <
            (b[orderBy] || "").toUpperCase()
            ? -1
            : 1;
      }
    });

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };

  handleAPIChange(data) {
    if (data.user_role === "GSP Representative") {
      this.setState({ authorized: true });
    }

    this.setState({ existingData: data.gsp, water_year: data.water_year });
  }

  render() {
    const { classes, user, authState } = this.props;
    const {
      page,
      rowsPerPage,
      order,
      orderBy,
      authorized,
      existingData,
      water_year,
      appConfig,
      isFetching,
    } = this.state;

    let gsp_items = existingData ? existingData : [];

    let token = authState && authState.user ? authState.user.auth_token : "";

    //get the config data
    if (token !== "" && appConfig === undefined && !isFetching) {
      this.setState({ ...this.state, isFetching: true });
      requestConfig(token).then((data) => {
        this.setState({ appConfig: data, isFetching: false });
      });
    }

    if (existingData === undefined || appConfig === undefined) {
      return (
        <AppContainer authenticated>
          <ReportingStatus
            apitarget={"combinedstatus"}
            handler={this.handleAPIChange.bind(this)}
          />
          <NotReady message="Loading..." />
        </AppContainer>
      );
    } else {
      return (
        <AppContainer authenticated>
          <Grid container spacing={24}>
            <Grid item xs={12} className={classes.breadCrumb}>
              <BreadcrumbNav
                level1="GSP Reporting Status"
                level1link="/gspreportingstatus"
              />
            </Grid>
            <Grid item xs={12}>
              <span className={classes.lgHeader}>
                Current Water Year {water_year ? water_year.water_year : ""}:{" "}
                {water_year
                  ? new Date(
                      water_year.water_year_start + " "
                    ).toLocaleDateString("en-US")
                  : ""}{" "}
                -{" "}
                {water_year
                  ? new Date(
                      water_year.water_year_end + " "
                    ).toLocaleDateString("en-US")
                  : ""}
              </span>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <EnhancedTableHead
                    columnData={trimColumns(columnData, appConfig)}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                  />
                  <TableBody>
                    {gsp_items
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>{item.name}</TableCell>
                          {appConfig.GWCS.enabled === true ? (
                            <TableCell>
                              {item.gws_required === true ? (
                                <>
                                  {item.gws_submitted != "None" ? (
                                    <>
                                      <BulkSubmitButton
                                        dataTarget="gws"
                                        gsp={item.id}
                                        status={item.gws_submitted}
                                        iconSize={1}
                                        iconColor="primary"
                                        handler={this.handleAPIChange.bind(
                                          this
                                        )}
                                      ></BulkSubmitButton>
                                      <Tooltip title={"View Data"}>
                                        <Button
                                          className={classes.tableCellIcon}
                                          onClick={() =>
                                            this.props.history.push({
                                              pathname:
                                                "/reportinghistorygsp/groundwaterstorage/" +
                                                item.id,
                                            })
                                          }
                                        >
                                          <Icon
                                            path={mdiEye}
                                            size={1}
                                            color="primary"
                                          ></Icon>{" "}
                                        </Button>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.gws_submitted}
                                </>
                              ) : (
                                <>{item.gws_submitted}</>
                              )}
                            </TableCell>
                          ) : (
                            <></>
                          )}
                          {appConfig.GE.enabled === true ? (
                            <TableCell>
                              {item.gwe_required === true ? (
                                <>
                                  {item.gwe_submitted != "None" ? (
                                    <>
                                      <BulkSubmitButton
                                        dataTarget="gwe"
                                        gsp={item.id}
                                        status={item.gwe_submitted}
                                        iconSize={1}
                                        iconColor="primary"
                                        handler={this.handleAPIChange.bind(
                                          this
                                        )}
                                      ></BulkSubmitButton>
                                      <Tooltip title={"View Data"}>
                                        <Button
                                          className={classes.tableCellIcon}
                                          onClick={() =>
                                            this.props.history.push({
                                              pathname:
                                                "/reportinghistorygsp/groundwaterextraction/" +
                                                item.id,
                                            })
                                          }
                                        >
                                          <Icon
                                            path={mdiEye}
                                            size={1}
                                            color="primary"
                                          ></Icon>{" "}
                                        </Button>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.gwe_submitted}
                                </>
                              ) : (
                                <>{item.gwe_submitted}</>
                              )}
                            </TableCell>
                          ) : (
                            <></>
                          )}
                          {appConfig.SWS.enabled === true ? (
                            <TableCell>
                              {item.sws_required === true ? (
                                <>
                                  {item.sws_submitted != "None" ? (
                                    <>
                                      <BulkSubmitButton
                                        dataTarget="sws"
                                        gsp={item.id}
                                        status={item.sws_submitted}
                                        iconSize={1}
                                        iconColor="primary"
                                        handler={this.handleAPIChange.bind(
                                          this
                                        )}
                                      ></BulkSubmitButton>
                                      <Tooltip title={"View Data"}>
                                        <Button
                                          className={classes.tableCellIcon}
                                          onClick={() =>
                                            this.props.history.push({
                                              pathname:
                                                "/reportinghistorygsp/surfacewatersupply/" +
                                                item.id,
                                            })
                                          }
                                        >
                                          <Icon
                                            path={mdiEye}
                                            size={1}
                                            color="primary"
                                          ></Icon>{" "}
                                        </Button>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.sws_submitted}
                                </>
                              ) : (
                                <>{item.sws_submitted}</>
                              )}
                            </TableCell>
                          ) : (
                            <></>
                          )}
                          {appConfig.TWU.enabled === true ? (
                            <TableCell>
                              {item.twu_required === true ? (
                                <>
                                  {item.twu_submitted != "None" ? (
                                    <>
                                      <BulkSubmitButton
                                        dataTarget="twu"
                                        gsp={item.id}
                                        status={item.twu_submitted}
                                        iconSize={1}
                                        iconColor="primary"
                                        handler={this.handleAPIChange.bind(
                                          this
                                        )}
                                      ></BulkSubmitButton>
                                      <Tooltip title={"View Data"}>
                                        <Button
                                          className={classes.tableCellIcon}
                                          onClick={() =>
                                            this.props.history.push({
                                              pathname:
                                                "/reportinghistorygsp/totalwateruse/" +
                                                item.id,
                                            })
                                          }
                                        >
                                          <Icon
                                            path={mdiEye}
                                            size={1}
                                            color="primary"
                                          ></Icon>{" "}
                                        </Button>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.twu_submitted}
                                </>
                              ) : (
                                <>{item.twu_submitted}</>
                              )}
                            </TableCell>
                          ) : (
                            <></>
                          )}
                          {appConfig.WLM.enabled === true ? (
                            <TableCell>
                              {item.gw_required === true ? (
                                <>
                                  {item.gw_submitted != "None" ? (
                                    <>
                                      <BulkSubmitButton
                                        dataTarget="gw"
                                        gsp={item.id}
                                        status={item.gw_submitted}
                                        iconSize={1}
                                        iconColor="primary"
                                        handler={this.handleAPIChange.bind(
                                          this
                                        )}
                                      ></BulkSubmitButton>
                                      <Tooltip title={"View Data"}>
                                        <Button
                                          className={classes.tableCellIcon}
                                          onClick={() =>
                                            this.props.history.push({
                                              pathname:
                                                "/gspReportingHistory/wellsitemeasurements/" +
                                                item.id,
                                            })
                                          }
                                        >
                                          <Icon
                                            path={mdiEye}
                                            size={1}
                                            color="primary"
                                          ></Icon>{" "}
                                        </Button>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.gw_submitted}
                                </>
                              ) : (
                                <>{item.gw_submitted}</>
                              )}
                            </TableCell>
                          ) : (
                            <></>
                          )}
                          {appConfig.ISM.enabled === true ? (
                            <TableCell>
                              {item.surf_required === true ? (
                                <>
                                  {item.surf_submitted != "None" ? (
                                    <>
                                      <BulkSubmitButton
                                        dataTarget="surf"
                                        gsp={item.id}
                                        status={item.surf_submitted}
                                        iconSize={1}
                                        iconColor="primary"
                                        handler={this.handleAPIChange.bind(
                                          this
                                        )}
                                      ></BulkSubmitButton>
                                      <Tooltip title={"View Data"}>
                                        <Button
                                          className={classes.tableCellIcon}
                                          onClick={() =>
                                            this.props.history.push({
                                              pathname:
                                                "/gspReportingHistory/surfacewatermeasurements/" +
                                                item.id,
                                            })
                                          }
                                        >
                                          <Icon
                                            path={mdiEye}
                                            size={1}
                                            color="primary"
                                          ></Icon>{" "}
                                        </Button>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.surf_submitted}
                                </>
                              ) : (
                                <>{item.surf_submitted}</>
                              )}
                            </TableCell>
                          ) : (
                            <></>
                          )}
                          {appConfig.WQM.enabled === true ? (
                            <TableCell>
                              {item.wq_required === true ? (
                                <>
                                  {item.wq_submitted !== "None" ? (
                                    <>
                                      <BulkSubmitButton
                                        dataTarget="wq"
                                        gsp={item.id}
                                        status={item.wq_submitted}
                                        iconSize={1}
                                        iconColor="primary"
                                        handler={this.handleAPIChange.bind(
                                          this
                                        )}
                                      ></BulkSubmitButton>
                                      <Tooltip title={"View Data"}>
                                        <Button
                                          className={classes.tableCellIcon}
                                          onClick={() =>
                                            this.props.history.push({
                                              pathname:
                                                "/gspReportingHistory/waterqualitymeasurements/" +
                                                item.id,
                                            })
                                          }
                                        >
                                          <Icon
                                            path={mdiEye}
                                            size={1}
                                            color="primary"
                                          ></Icon>{" "}
                                        </Button>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.wq_submitted}
                                </>
                              ) : (
                                <>{item.wq_submitted}</>
                              )}
                            </TableCell>
                          ) : (
                            <></>
                          )}
                          {appConfig.SM.enabled === true ? (
                            <TableCell>
                              {item.sub_required === true ? (
                                <>
                                  {item.sub_submitted !== "None" ? (
                                    <>
                                      <BulkSubmitButton
                                        dataTarget="sub"
                                        gsp={item.id}
                                        status={item.sub_submitted}
                                        iconSize={1}
                                        iconColor="primary"
                                        handler={this.handleAPIChange.bind(
                                          this
                                        )}
                                      ></BulkSubmitButton>
                                      <Tooltip title={"View Data"}>
                                        <Button
                                          className={classes.tableCellIcon}
                                          onClick={() =>
                                            this.props.history.push({
                                              pathname:
                                                "/gspReportingHistory/subsidencemeasurements/" +
                                                item.id,
                                            })
                                          }
                                        >
                                          <Icon
                                            path={mdiEye}
                                            size={1}
                                            color="primary"
                                          ></Icon>{" "}
                                        </Button>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.sub_submitted}
                                </>
                              ) : (
                                <>{item.sub_submitted}</>
                              )}
                            </TableCell>
                          ) : (
                            <></>
                          )}
                          {appConfig.ADMIN.STMS.enabled === true ? (
                            <TableCell>
                              {item.stream_required === true ? (
                                <>
                                  {item.stream_submitted !== "None" ? (
                                    <>
                                      <BulkSubmitButton
                                        dataTarget="stream"
                                        gsp={item.id}
                                        status={item.stream_submitted}
                                        iconSize={1}
                                        iconColor="primary"
                                        handler={this.handleAPIChange.bind(
                                          this
                                        )}
                                      ></BulkSubmitButton>
                                      <Tooltip title={"View Data"}>
                                        <Button
                                          className={classes.tableCellIcon}
                                          onClick={() =>
                                            this.props.history.push({
                                              pathname:
                                                "/gspReportingHistory/streammonitoring/" +
                                                item.id,
                                            })
                                          }
                                        >
                                          <Icon
                                            path={mdiEye}
                                            size={1}
                                            color="primary"
                                          ></Icon>{" "}
                                        </Button>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.stream_submitted}
                                </>
                              ) : (
                                <>{item.stream_submitted}</>
                              )}
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                      ))}
                    {gsp_items.length < 1 && (
                      <TableRow>
                        <TableCell colSpan={4} className={classes.centerAlign}>
                          No GSPs Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  {gsp_items.length > 25 && (
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          colSpan={4}
                          count={gsp_items.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActionsWrapped}
                        />
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </AppContainer>
      );
    }
  }
}
GSPReportingStatus = connect((state, ownProps) => ({
  authState: state.auth,
}))(GSPReportingStatus);

export default withStyles(styles)(GSPReportingStatus);
