import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "../common/orm";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import BreadcrumbNav from "../common/BreadCrumb";
import TableCell from "../common/TableCell";
import NumberFormat from "react-number-format";
import Icon from "@mdi/react";
import { mdiEye } from "@mdi/js";

const historicWaterReporting = createSelector((session, options) => {
  let gsp = session.GSP.all().toRefArray();
  let wy = session.WaterYear.all().toRefArray();
  let gws =
    gsp && wy
      ? session.GroundwaterExtraction.filter((r) => r.submitted == true)
          .toRefArray()
          .map((water) => {
            return {
              ge_id: water.id,
              ...water,
              ...wy.find((w) => w.id === water.water_year_id),
              ...gsp.find((g) => g.id === water.gsp),
            };
          })
      : [];
  if (gws.length) {
    console.log(gws);
  }
  return gws;
});
const styles = (theme) => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee",
  },
  expansionBorder: {
    borderTop: "1px solid " + theme.palette.primary.light,
    borderBottom: "1px solid " + theme.palette.primary.light,
  },
  lastBorder: {
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lastBorder2: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  expandedMargin: {
    marginTop: "12px",
    minHeight: "48px !important",
    maxHeight: 48,
  },
  borderContainer: {
    borderTop: "1px solid " + theme.palette.primary.light,
    borderBottom: "1px solid " + theme.palette.primary.light,
    borderRight: "2px solid " + theme.palette.primary.light,
    borderLeft: "2px solid " + theme.palette.primary.light,
  },
  borderContainer2: {
    border: "1px solid rgb(221, 221, 221)",
  },
  buttonSelected: {
    border: "3px solid #13aff1",
    minWidth: "44px",
  },
  buttonNotSelected: {
    border: "3px solid #666",
    minWidth: "44px",
  },
  iconSelected: {
    color: "#13aff1",
  },
  iconNotSelected: {
    color: "#666",
  },
  divideButton: {
    marginBottom: 16,
    width: "calc(50% - 16px)",
    marginTop: 16,
    minWidth: 44,
  },
  divideContainer: {
    backgroundColor: "#ddd",
    textAlign: "center",
    margin: "0 -4px",
  },
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 500,
    "& tbody tr:nth-child(even)": {
      backgroundColor: "rgb(106,225,255,.2)",
    },
  },
  centerAlign: {
    textAlign: "center",
  },
  marginLeft: {
    marginLeft: -16,
  },
  chartTitle: {
    backgroundColor: "rgb(221, 221, 221)",
    textAlign: "center",
    padding: "8px 4px !important",
  },
});
class GridViewGroundwaterExtraction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "desc",
      orderBy: "water_year",
      page: 0,
      rowsPerPage: 10,
      histdata: [],
    };
  }
  componentDidMount() {}
  columnData() {
    const groundwaterStorageCols = [
      {
        id: "gsp_label",
        numeric: false,
        label: "GSP Organization Name",
        allowSort: true,
      },
      { id: "water_year", numeric: true, label: "Year", allowSort: true },
      { id: "details", numeric: true, label: "Details", allowSort: false },
      // {
      //   id: "gw_dom_ua",
      //   numeric: true,
      //   label: "Urban | Upper Aquifier",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_dom_la",
      //   numeric: true,
      //   label: "Urban | Lower Aquifier",
      //   allowSort: true,
      // },
      {
        id: "gw_dom_total",
        numeric: true,
        label: "Urban",
        allowSort: true,
      },
      // {
      //   id: "gw_dom_calcm",
      //   numeric: true,
      //   label: "Urban | Calculation Method",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_dom_acc",
      //   numeric: false,
      //   label: "Urban | Calculation Accuracy",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_dom_method",
      //   numeric: false,
      //   label: "Urban | Method",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_dom_method_desc",
      //   numeric: false,
      //   label: "Urban | Method Description",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_ind_ua",
      //   numeric: true,
      //   label: "Industrial | Upper Aquifier",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_ind_la",
      //   numeric: true,
      //   label: "Industrial | Lower Aquifier",
      //   allowSort: true,
      // },
      {
        id: "gw_ind_total",
        numeric: true,
        label: "Industrial",
        allowSort: true,
      },
      // {
      //   id: "gw_ind_calcm",
      //   numeric: true,
      //   label: "Industrial | Calculation Method",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_ind_acc",
      //   numeric: false,
      //   label: "Industrial | Calculation Accuracy",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_ind_method",
      //   numeric: false,
      //   label: "Industrial | Method",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_ind_method_desc",
      //   numeric: false,
      //   label: "Industrial | Method Description",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_agg_ua",
      //   numeric: true,
      //   label: "Agricultural | Upper Aquifier",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_agg_la",
      //   numeric: true,
      //   label: "Agricultural | Lower Aquifier",
      //   allowSort: true,
      // },
      {
        id: "gw_agg_total",
        numeric: true,
        label: "Agricultural",
        allowSort: true,
      },
      // {
      //   id: "gw_agg_calcm",
      //   numeric: true,
      //   label: "Agricultural | Calculation Method",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_agg_acc",
      //   numeric: false,
      //   label: "Agricultural | Calculation Accuracy",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_agg_method",
      //   numeric: false,
      //   label: "Agricultural | Method",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_agg_method_desc",
      //   numeric: false,
      //   label: "Agricultural | Method Description",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_wet_ua",
      //   numeric: true,
      //   label: "Managed Wetlands | Upper Aquifier",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_wet_la",
      //   numeric: true,
      //   label: "Managed Wetlands | Lower Aquifier",
      //   allowSort: true,
      // },
      {
        id: "gw_wet_total",
        numeric: true,
        label: "Managed Wetlands",
        allowSort: true,
      },
      // {
      //   id: "gw_wet_calcm",
      //   numeric: true,
      //   label: "Managed Wetlands | Calculation Method",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_wet_acc",
      //   numeric: false,
      //   label: "Managed Wetlands | Calculation Accuracy",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_wet_method",
      //   numeric: false,
      //   label: "Managed Wetlands | Method",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_wet_method_desc",
      //   numeric: false,
      //   label: "Managed Wetlands | Method Description",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_out_ua",
      //   numeric: true,
      //   label: "Outside Subbasin | Upper Aquifier",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_out_la",
      //   numeric: true,
      //   label: "Outside Subbasin | Lower Aquifier",
      //   allowSort: true,
      // },
      {
        id: "gw_out_total",
        numeric: true,
        label: "Outside Subbasin",
        allowSort: true,
      },
      // {
      //   id: "gw_out_calcm",
      //   numeric: true,
      //   label: "Outside Subbasin | Calculation Method",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_out_acc",
      //   numeric: false,
      //   label: "Outside Subbasin | Calculation Accuracy",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_out_method",
      //   numeric: false,
      //   label: "Outside Subbasin | Method",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_out_method_desc",
      //   numeric: false,
      //   label: "Outside Subbasin | Method Description",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_mr_ua",
      //   numeric: true,
      //   label: "Managed Recharge | Upper Aquifier",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_mr_la",
      //   numeric: true,
      //   label: "Managed Recharge | Lower Aquifier",
      //   allowSort: true,
      // },
      {
        id: "gw_mr_total",
        numeric: true,
        label: "Managed Recharge",
        allowSort: true,
      },
      // {
      //   id: "gw_mr_calcm",
      //   numeric: true,
      //   label: "Managed Recharge | Calculation Method",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_mr_acc",
      //   numeric: false,
      //   label: "Managed Recharge | Calculation Accuracy",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_mr_method",
      //   numeric: false,
      //   label: "Managed Recharge | Method",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_mr_method_desc",
      //   numeric: false,
      //   label: "Managed Recharge | Method Description",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_veg_ua",
      //   numeric: true,
      //   label: "Native Vegetation | Upper Aquifier",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_veg_la",
      //   numeric: true,
      //   label: "Native Vegetation | Lower Aquifier",
      //   allowSort: true,
      // },
      {
        id: "gw_veg_total",
        numeric: true,
        label: "Native Vegetation",
        allowSort: true,
      },
      // {
      //   id: "gw_veg_calcm",
      //   numeric: true,
      //   label: "Native Vegetation | Calculation Method",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_veg_acc",
      //   numeric: false,
      //   label: "Native Vegetation | Calculation Accuracy",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_veg_method",
      //   numeric: false,
      //   label: "Native Vegetation | Method",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_veg_method_desc",
      //   numeric: false,
      //   label: "Native Vegetation | Method Description",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_other_desc",
      //   numeric: false,
      //   label: "Other | Description",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_other_ua",
      //   numeric: true,
      //   label: "Other | Upper Aquifier",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_other_la",
      //   numeric: true,
      //   label: "Other | Lower Aquifier",
      //   allowSort: true,
      // },
      {
        id: "gw_other_total",
        numeric: true,
        label: "Other",
        allowSort: true,
      },
      // {
      //   id: "gw_other_calcm",
      //   numeric: true,
      //   label: "Other | Calculation Method",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_other_acc",
      //   numeric: false,
      //   label: "Other | Calculation Accuracy",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_other_method",
      //   numeric: false,
      //   label: "Other | Method",
      //   allowSort: true,
      // },
      // {
      //   id: "gw_other_method_desc",
      //   numeric: false,
      //   label: "Other | Method Description",
      //   allowSort: true,
      // },
    ];
    return groundwaterStorageCols;
  }
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    const colType = this.props.match.params["id"];
    const columnData = this.columnData(colType);
    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.state.histdata.sort(function (a, b) {
      var numeric = columnData.find((cD) => cD.id === orderBy).numeric;
      if (numeric) {
        if (order === "desc")
          return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
        else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
      } else {
        if (order === "desc")
          return (b[orderBy] || "").toUpperCase() <
            (a[orderBy] || "").toUpperCase()
            ? -1
            : 1;
        else
          return (a[orderBy] || "").toUpperCase() <
            (b[orderBy] || "").toUpperCase()
            ? -1
            : 1;
      }
    });
    this.setState({ order, orderBy });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };
  render() {
    const { classes, waterhist } = this.props;
    const { page, rowsPerPage, order, orderBy, histdata } = this.state;
    if (waterhist && waterhist.length) {
      if (!histdata.length) {
        this.setState({ histdata: waterhist });
      }
    }
    let level1 = "Groundwater Extraction";
    let level1link = "/groundwaterextraction";
    return (
      <AppContainer authenticated>
        <Grid container spacing={24}>
          <Grid item xs={12} className={classes.breadCrumb}>
            <BreadcrumbNav level1={level1} level1link={level1link} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              Submitted Groundwater Extraction Data (AF)
            </Typography>
          </Grid>
          {histdata ? (
            <Grid item xs={12}>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <EnhancedTableHead
                    columnData={this.columnData()}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                  />
                  <TableBody>
                    {histdata
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.gsp_label}</TableCell>
                          <TableCell>{item.water_year}</TableCell>
                          <TableCell>
                            <Tooltip title={"View Data"}>
                              <Button
                                className={classes.tableCellIcon}
                                onClick={() =>
                                  this.props.history.push({
                                    pathname:
                                      "/reporting/gwextraction/" +
                                      item.id +
                                      "/" +
                                      item.ge_id,
                                  })
                                }
                              >
                                <Icon
                                  path={mdiEye}
                                  size={1}
                                  color="primary"
                                ></Icon>{" "}
                              </Button>
                            </Tooltip>
                          </TableCell>
                          {/* <TableCell>
                            <NumberFormat
                              value={item.gw_dom_ua}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.gw_dom_la}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          <TableCell>
                            <NumberFormat
                              value={item.gw_dom_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                              decimalScale={0}
                            />
                          </TableCell>
                          {/* <TableCell>{item.gw_dom_calcm}</TableCell>
                          <TableCell>{item.gw_dom_acc}</TableCell>
                          <TableCell>{item.gw_dom_method}</TableCell>
                          <TableCell>{item.gw_dom_method_notes}</TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.gw_ind_ua}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.gw_ind_la}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          <TableCell>
                            <NumberFormat
                              value={item.gw_ind_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          {/* <TableCell>{item.gw_ind_calcm}</TableCell>
                          <TableCell>{item.gw_ind_acc}</TableCell>
                          <TableCell>{item.gw_ind_method}</TableCell>
                          <TableCell>{item.gw_ind_method_notes}</TableCell> */}
                          {/* <TableCell>
                            <NumberFormat
                              value={item.gw_agg_ua}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.gw_agg_la}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          <TableCell>
                            <NumberFormat
                              value={item.gw_agg_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          {/* <TableCell>{item.gw_agg_calcm}</TableCell>
                          <TableCell>{item.gw_agg_acc}</TableCell>
                          <TableCell>{item.gw_agg_method}</TableCell>
                          <TableCell>{item.gw_agg_method_notes}</TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.gw_wet_ua}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.gw_wet_la}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          <TableCell>
                            <NumberFormat
                              value={item.gw_wet_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          {/* <TableCell>{item.gw_wet_calcm}</TableCell>
                          <TableCell>{item.gw_wet_acc}</TableCell>
                          <TableCell>{item.gw_wet_method}</TableCell>
                          <TableCell>{item.gw_wet_method_notes}</TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.gw_out_ua}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.gw_out_la}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          <TableCell>
                            <NumberFormat
                              value={item.gw_out_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          {/* <TableCell>{item.gw_out_calcm}</TableCell>
                          <TableCell>{item.gw_out_acc}</TableCell>
                          <TableCell>{item.gw_out_method}</TableCell>
                          <TableCell>{item.gw_out_method_notes}</TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.gw_mr_ua}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.gw_mr_la}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          <TableCell>
                            <NumberFormat
                              value={item.gw_mr_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          {/* <TableCell>{item.gw_mr_calcm}</TableCell>
                          <TableCell>{item.gw_mr_acc}</TableCell>
                          <TableCell>{item.gw_mr_method}</TableCell>
                          <TableCell>{item.gw_mr_method_notes}</TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.gw_veg_ua}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.gw_veg_la}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          <TableCell>
                            <NumberFormat
                              value={item.gw_veg_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          {/* <TableCell>{item.gw_veg_calcm}</TableCell>
                          <TableCell>{item.gw_veg_acc}</TableCell>
                          <TableCell>{item.gw_veg_method}</TableCell>
                          <TableCell>{item.gw_veg_method_notes}</TableCell>
                          <TableCell>{item.gw_other_desc}</TableCell> */}
                          {/* <TableCell>
                            <NumberFormat
                              value={item.gw_other_ua}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.gw_other_la}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          <TableCell>
                            <NumberFormat
                              value={item.gw_other_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          {/* <TableCell>{item.gw_other_calcm}</TableCell>
                          <TableCell>{item.gw_other_acc}</TableCell>
                          <TableCell>{item.gw_other_method}</TableCell>
                          <TableCell>{item.gw_other_method_notes}</TableCell> */}
                        </TableRow>
                      ))}
                    {histdata.length < 1 && (
                      <TableRow>
                        <TableCell colSpan={7} className={classes.centerAlign}>
                          No Historical Data Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  {histdata.length > rowsPerPage && (
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          colSpan={7}
                          count={histdata.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActionsWrapped}
                        />
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              </Paper>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </AppContainer>
    );
  }
}
GridViewGroundwaterExtraction = connect(
  (state, ownProps) => ({
    waterhist: historicWaterReporting(state, ownProps),
    authState: state.auth,
  }),
  {}
)(GridViewGroundwaterExtraction);
export default withStyles(styles)(GridViewGroundwaterExtraction);
