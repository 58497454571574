import React, { Component } from "react";
import classNames from "classnames";
import { FormField } from "react-form";
import MuiTextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  bootstrapInput: {
    borderRadius: 4,
    border: "1px solid #979797",
    padding: "8px 12px",
    width: "calc(100% - 24px)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: theme.palette.secondary.light,
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    },
    backgroundColor: "#fff",
    fontSize: "16px",
    fontWeight: 300,
    color: "#666666 !important"
  },
  rounded: {
    borderRadius: 14
  },
  labelSelected: {
    //border: '1px solid ' + theme.palette.primary.main,
  },
  bootstrapInputError: {
    "& input": {
      border: "1px solid #f44336"
    }
  },
  bootstrapSelectError: {
    // Select menus are a div not a input
    border: "1px solid #f44336"
  },
  rootLabel: {
    marginLeft: 12,
    marginTop: 3,
    whiteSpace: "nowrap"
  },
  rootLabelShrink: {
    marginLeft: 0,
    marginTop: -8
    //color: theme.palette.primary.dark,
  },
  whiteSpace: {
    whiteSpace: "nowrap"
  },
  disabled: {
    backgroundColor: "#EEEEEE"
  }
});

// See https://react-form.js.org/#/custom-input
class TextFieldWrapper extends Component {
  handleChange = e => {
    let value = e.target.value;
    const { fieldApi, eventHandle, units } = this.props;
    const { setValue } = fieldApi;
    // Make sure value isn't blank otherwise result returns: " acres"
    // Which the server turns into "1 acres"
    if (value !== "" && units) {
      value += " " + units;
    }
    setValue(value);
    if (eventHandle) eventHandle(value, e);
  };

  render() {
    const {
      fieldApi,
      helperText,
      SelectProps,
      units,
      help,
      classes,
      label,
      disabled,
      unitsVisual,
      startAdornment,
      rounded,
      eventHandle,
      ...rest
    } = this.props;
    const { getValue, getError, getWarning, getSuccess, setTouched } = fieldApi;

    const value =
        getValue() ||
        (SelectProps
          ? SelectProps.multiple
            ? []
            : ""
          : getValue() === 0
          ? 0
          : ""),
      error = getError(),
      warning = getWarning(),
      success = getSuccess(),
      hasError = !!error,
      errorText = error || warning || success || helperText;

    let InputProps = {},
      renderValue;

    if (help || units) {
      InputProps["endAdornment"] = (
        <InputAdornment position="end" className={classes.whiteSpace}>
          {(units || unitsVisual) && (
            <Typography color="textSecondary">
              {units}
              {unitsVisual}
            </Typography>
          )}
        </InputAdornment>
      );
    }
    if (startAdornment) {
      InputProps["startAdornment"] = startAdornment;
    }

    if (units) {
      if (value && value.split) {
        renderValue = value.split(" ")[0];
      } else {
        renderValue = value;
      }
    } else {
      renderValue = value;
    }

    InputProps["disableUnderline"] = true;
    InputProps["classes"] = {
      input: classNames(
        classes.bootstrapInput,
        value && classes.labelSelected,
        disabled && classes.disabled,
        rounded && classes.rounded
      ),
      error: classes.bootstrapInputError
    };

    let sProps = {};
    if (SelectProps !== undefined) {
      sProps = SelectProps;
    }
    sProps["classes"] = {
      select: hasError ? classes.bootstrapSelectError : ""
    };

    return (
      <MuiTextField
        value={renderValue.toString()}
        error={hasError}
        label={label}
        disabled={disabled}
        helperText={errorText}
        onChange={this.handleChange}
        onBlur={() => setTouched()}
        SelectProps={sProps}
        InputProps={InputProps}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.rootLabel,
            shrink: classes.rootLabelShrink
          }
        }}
        margin="normal"
        {...rest}
      />
    );
  }
}

const TextField = FormField(TextFieldWrapper);

export default withStyles(styles)(TextField);
