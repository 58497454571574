import React, { Component } from "react";
import { connect } from "react-redux";
import PageHeader from '../common/PageHeader';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import AppContainer from "../common/AppContainer";
import * as authActions from "../auth/actions"; 

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
});

function handleSubmitfunc(event) {
    fetch('/maintenancelog', {
        method: 'POST',
        body: new FormData(event.target)
    });
}

class MaintenanceForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded:false,
            item: []
        };
        this.handleSubmit = handleSubmitfunc.bind(this);
    }
    
    render() {
        const { isEdit, item } = this.state;
        const { authState } = this.props;
        if (!authState) return "...";        
        if (!isEdit) {
            this.setState({id: "new"});
        } 
        return (
            <AppContainer authenticated>
                <PageHeader title={item.well_id + ' Maintenance Log Entry '} />
                <form onSubmit={this.handleSubmit}>
                    <Input type="hidden" value={this.state.id} id="id" />
                    <TextField
                        id="date"
                        label="Date"
                        type="date"
                    />
                    <TextField
                        id="performedby"
                        label="Performed By"
                    />
                    <TextField
                        id="description"
                        label="Description"
                    />
                    <button type="submit">Submit</button>
                </form>
            </AppContainer>
        );
    }
}
MaintenanceForm = connect(
    state => ({ authState: state.auth }),
    {
        ...authActions
    }
)(MaintenanceForm);

export default withStyles(styles)(MaintenanceForm); 
